<template>
    <v-row class="fill-height">
      <v-dialog v-model="loadingMeals" max-width="130px" class="text-center position-relative">
        <v-card class="text-center py-3">
          <v-card-title style="display: unset" class="my-3">
            <div class="lds-dual-ring"></div>
          </v-card-title>
        </v-card>
      </v-dialog>
      <v-col>
          <v-sheet height="64">
              <v-toolbar flat>
                  <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                      {{ $t('today') }}
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="prev">
                      <v-icon small>
                          mdi-chevron-left
                      </v-icon>
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="next">
                      <v-icon small>
                          mdi-chevron-right
                      </v-icon>
                  </v-btn>
                  <v-toolbar-title>
                      <template v-if="$refs.calendar">{{ $refs.calendar.title }}</template>
                      <template v-else>{{ moment().format('MMMM YYYY') }}</template>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                <v-btn class="mr-3" color="primary" v-if="$store.state.user.type == 'restaurant' && $store.state.restaurant && $store.state.restaurant.parent && $store.state.restaurant.parent.parent_menu == 1" @click="selectedDate1 = {name: 'rucak', date: moment().format('YYYY-MM-DD')}; dialog1 = true">
                  {{ $t('meal_from') }} {{ this.$store.state.restaurant.parent.name }}
                </v-btn>
                  <v-btn style="border-radius: 8px;border: 1px solid rgb(251, 109, 59);background: none;color: rgb(251, 109, 59);box-shadow: none;text-transform: capitalize" v-if="$store.state.restaurant && $store.state.restaurant.add_meal == 1"  @click="selectedDate = {name: 'rucak', date: moment().format('YYYY-MM-DD'), meals: []}; dialog = true">
                     <v-icon>mdi-plus</v-icon>  {{ $t('add_meal') }}
                  </v-btn>
              </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
              <v-calendar
                      ref="calendar"
                      v-model="focus"
                      color="primary"
                      :events="events"
                      :event-more="false"
                      :event-color="getEventColor"
                      :type="type"
                      :weekday-format="myDayFormat"
                      :weekdays="weekdays"
                      @click:event="showEvent"
                      @change="updateRange"
                      @click:date="test"
              ></v-calendar>
              <v-menu
                      v-model="selectedOpen"
                      :close-on-content-click="false"
                      :activator="selectedElement"
                      offset-x
              >
                  <v-card
                          color="grey lighten-4"
                          min-width="350px"
                          flat
                  >
                      <v-toolbar :title="selectedEvent.id"
                              :color="selectedEvent.color"
                              dark
                      >



                          <v-btn icon>
                              <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-toolbar-title :title="selectedEvent.id" v-html="selectedEvent.name"></v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn icon>
                              <v-icon>mdi-heart</v-icon>
                          </v-btn>
                          <v-btn icon>
                              <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                      </v-toolbar>
                      <v-card-text>
                          <ul>
                              <template v-for="menu in selectedEvent.menus">
                                  <li>{{menu}}</li>
                              </template>
                          </ul>
                      </v-card-text>
                      <v-card-actions>
                          <v-btn text color="secondary" @click="selectedOpen = false">
                             {{$t('cancel')}}
                          </v-btn>
                      </v-card-actions>
                  </v-card>
              </v-menu>
          </v-sheet>
      </v-col>

      <dialog-menu :dates_for_disable="enteredMealDates" :dialog="dialog" :selectedDate="selectedDate" @close="dialog = false" @delete="deleteItem" @add="addMenu" @select="selectMenu"></dialog-menu>
      <dialog-menu1 v-if="old_event" :new_events="events" :selectedDate="selectedDate" :old_events="old_event" :dialog="dialog1"  @close="dialog1 = false" @select="selectMenu"></dialog-menu1>

    </v-row>
</template>


<script>
    import DialogMenu from '../components/jelovnik/dialog'
    import DialogMenu1 from '../components/jelovnik/dialog1'
    import moment from 'moment'
    import axios from '../plugins/axios'

    export default {
        components: {
            DialogMenu,
            DialogMenu1
        },
        data: () => ({
            loadingMeals: false,
            moment: moment,
            focus: '',
            type: 'month',
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            events: [],
            old_event: [],
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
            weekdays: [1, 2, 3, 4, 5, 6, 0],
            dialog: false,
            dialog1: false,
            selectedDate: {},
            selectedDate1: {},
            enteredMealDates: [],
            loaded: false,
        }),
        computed: {
          // enteredMealDates() {
          //   let dates = []
          //   this.events.filter(meal => {
          //     dates.push(meal.date)
          //   })
          //   return dates
          // }
        },
        created() {
          axios.post('/companies/user-companies', {'user_id': 483}).then(({data}) => {
            console.log(data)
          })
          this.$root.$on('cloneParentMenu12', data => {
            if(data) {
              let startOfMonth = moment(data.date.date).startOf('month').format('YYYY-MM-DD');
              let endOfMonth   = moment(data.date.date).endOf('month').format('YYYY-MM-DD');
              setTimeout(() => {
                this.getWebMonth(startOfMonth, endOfMonth)
              }, 700)
            }
          })
          this.$on('cloneParentMenuEmit', (data) => {
            let prepData = {}
            prepData['date'] = data.selected_date.date
            if(data.parent_menu_id) {
              prepData['id'] = data.parent_menu_id
            }
            prepData['type'] = 'rucak'
            prepData['restaurant_id'] = this.$store.state.restaurant.id
            prepData['clone_id'] = this.$store.state.restaurant.parent.id
            prepData['reserve_id'] = ''
            prepData['one'] = 0
            prepData['meals'] = []
            prepData['meals']['restaurant_id'] = this.$store.state.restaurant.id
            prepData['meals']['menu_id'] = data.restoran_parent_id
            data.selected_date.meals.filter(meal => {
              if (data.selected_meals.includes(meal.id)) {
                if(data.parent_menu_id) {
                  meal.old_menu_id = meal.menu_id
                  meal.clone_meal_id = meal.id
                  meal.menu_id = data.parent_menu_id
                  meal.restaurant_id = this.$store.state.restaurant.id
                  meal.clone_id = this.$store.state.restaurant.parent.id
                  meal.id = ''
                }
                prepData['meals'].push(meal)
              }
            })
            this.addMenu(prepData)
          })
          if (this.$store.state.user && this.$store.state.user.restaurants && this.$store.state.user.restaurants.length && this.$store.state.user.restaurants[0].parent && this.$store.state.user.restaurants[0].parent.parent_menu == 1 &&  this.$store.state.user.type == 'restaurant') {
            axios.post('/menus/get-clone-menu', {'id': this.$store.state.user.restaurants[0].parent_id, 'usr_id': this.$store.state.user.id}).then(res => {
              res.data.forEach(item => {
                const min = new Date(`${item.date}T00:00:01`)
                const max = new Date(`${item.date}T23:59:59`)
                // item.meals.forEach(el=>{
                //   el.type=el.groups.map(i=>i.group);
                // })
                this.old_event.push({
                  id: item.id,
                  name: this.translateName(item.type),
                  date: item.date,
                  reserve_id:item.reserve_id,
                  start: min,
                  end: max,
                  color: this.color(item.type),
                  timed: false,
                  meals: item.meals,
                  one: item.one ? 1 : 0
                })
              })
            })
          }
         this.getWeb()

        },
        methods: {

          getWeb() {
            this.enteredMealDates = []
            this.events = []
            let startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
            let endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
            this.enteredMealDates = []
            this.loadingMeals = true
            axios.get('/menus/web-app-only-menu?from='+ startOfMonth + '&to=' + endOfMonth).then(res => {
              res.data.forEach(item => {
                const min = new Date(`${item.date}T00:00:01`)
                const max = new Date(`${item.date}T23:59:59`)
                // item.meals.forEach(el=>{
                //   el.type=el.groups.map(i=>i.group);
                // })
                this.enteredMealDates.push(item.date)
                this.events.push({
                  id: item.id,
                  name: this.translateName(item.type),
                  date: item.date,
                  reserve_id:item.reserve_id,
                  start: min,
                  end: max,
                  color: this.color(item.type),
                  timed: false,
                  meals: item.meals,
                  one: item.one ? 1 : 0
                })
              })
              this.loadingMeals = false
            })
          },
          getWebMonth(startOfMonth, endOfMonth) {
            this.events = []
            this.enteredMealDates = []
            this.loadingMeals = true
            axios.get('/menus/web-app-only-menu?from='+ startOfMonth + '&to=' + endOfMonth).then(res => {
              res.data.forEach(item => {
                const min = new Date(`${item.date}T00:00:01`)
                const max = new Date(`${item.date}T23:59:59`)
                // item.meals.forEach(el=>{
                //   el.type=el.groups.map(i=>i.group);
                // })

                this.enteredMealDates.push(item.date)
                this.events.push({
                  id: item.id,
                  name: this.translateName(item.type),
                  date: item.date,
                  reserve_id:item.reserve_id,
                  start: min,
                  end: max,
                  color: this.color(item.type),
                  timed: false,
                  meals: item.meals,
                  one: item.one ? 1 : 0
                })
              })
              this.loadingMeals = false
            })
            this.$forceUpdate()
          },
          translateName(name) {
            let ime = '';
            if (name == 'rucak') {
              ime = this.$t('rucak')
            } else {
              ime = name
            }
            return ime
          },
            test(data) {
              let check = this.events.find(item => item.date === data.date)
              if(!check) {
                this.selectedDate = {
                  date: data.date,
                  meals: [],
                  name: 'rucak'
                }
                this.dialog = true
                  this.selectedDate1 = {
                      date: data.date,
                      meals: [],
                      name: 'rucak'
                  }
                  this.dialog = true
              }
            },
            color(type) {
              switch(type) {
                case 'dorucak': return 'green'
                    break
                case 'rucak': return 'blue'
                    break
                case 'vecera': return 'red'
                    break
              }
            },
            selectMenu(data) {
              if(data) {
                this.selectedDate = data
                this.selectedDate.name = data.type
              }
            },
            addMenu(data) {
              if(data.id) {
                let can = false
                axios.put('/menus/' + data.id, data).then(res => {
                  let startOfMonth = moment(data.date).startOf('month').format('YYYY-MM-DD');
                  let endOfMonth   = moment(data.date).endOf('month').format('YYYY-MM-DD');
                  setTimeout(() => {
                    this.getWebMonth(startOfMonth, endOfMonth)
                  }, 700)
                  //this.events.push(data)
                  // this.selectedDate = {}
                  if (res.data.meals) {
                    this.$root.$emit('refreshChildComponent', res.data)
                  }
                  this.events = []
                  this.$root.$emit('menuSaved', res.data)
                  this.getWeb()
                  setTimeout(() => {
                    // this.dialog = false
                    this.dialog1 = false
                    this.$emit('close')
                    this.$forceUpdate()
                  }, 300)
                }).catch((error) => {
                  if (error.response.data.status == "failed") {
                    this.$root.$emit('errorMessage', error.response.data)
                  }
                });
              }
              else {
                axios.post('/menus', data).then(res => {
                  data.id = res.data.id
                  this.events.push(data)
                  this.events.filter(menu => {
                    let can = false
                    if (menu.id == res.data.id) {
                      res.data.meals.filter(meal => {
                        if(meal.clone_meal_id) {
                          can = true
                        } else {
                          can = false
                        }
                        data.meals.filter(m => {
                          if (m.id == meal.clone_meal_id) {
                            // m.id = meal.id
                            m.clone_id = data.clone_id
                            m.original_id = data.clone_meal_id
                            m.clone_meal_id = meal.clone_meal_id
                            m.restaurant_id = data.restaurant_id
                          }
                        })
                      })
                      if (can) {
                        menu.color = 'primary'
                        menu.start = new Date(res.data.date)
                        menu.end = new Date(res.data.date)
                        menu.name = 'rucak'
                        menu.meals = data.meals
                        this.events = []
                        this.getWeb()
                      }
                    }
                  })
                  let startOfMonth = moment(data.date).startOf('month').format('YYYY-MM-DD');
                  let endOfMonth   = moment(data.date).endOf('month').format('YYYY-MM-DD');
                  setTimeout(() => {
                    this.getWebMonth(startOfMonth, endOfMonth)
                  }, 700)
                  this.selectedDate = res.data
                  // this.dialog = false
                  this.dialog1 = false
                  this.$emit('close')
                  this.$emit('menuSaved')
                  this.$forceUpdate()
                })
              }
            },
            deleteItem(id){
              if(id){
                axios.delete('/menus/' + id).then(res => {
                  this.selectedDate = {}
                  this.dialog = false
                  this.events.splice(this.events.findIndex(el=>el.id===id),1)
                }).catch((error) => {
                  if (error.response.data.status == "failed") {
                    this.$root.$emit('errorMessage', error.response.data, 'menu')
                  }
                });
              }
            },
            myDayFormat(day) {
                switch(day.weekday) {
                    case 1: return this.$t('monday')
                        break
                    case 2: return this.$t('tuesday')
                        break
                    case 3: return this.$t('wednesday')
                        break
                    case 4: return this.$t('thursday')
                        break
                    case 5: return this.$t('friday')
                        break
                    case 6: return this.$t('saturday')
                        break
                    case 0: return this.$t('sunday')
                        break
                }
            },
            getEventColor (event) {
                return event.color = 'primary'
            },
            setToday () {
                this.focus = ''
            },
            prev () {
              this.$refs.calendar.prev()

              setTimeout(() => {
                let month = this.$refs.calendar.title.split(' ');
                let year = month[1];

                let startOfMonth = moment(new Date((new Date(year, moment(this.$refs.calendar.value).format('M'), 1)) - 1)).startOf('month').format('YYYY-MM-DD');
                let endOfMonth   = moment(new Date((new Date(year, moment(this.$refs.calendar.value).format('M'), 1)) - 1)).endOf('month').format('YYYY-MM-DD');
                this.getWebMonth(startOfMonth, endOfMonth)
              }, 300)
            },
            next () {
              this.$refs.calendar.next()

              setTimeout(() => {
                let month = this.$refs.calendar.title.split(' ');
                let year = month[1];

                let startOfMonth = moment(new Date((new Date(year, moment(this.$refs.calendar.value).format('M'), 1)) - 1)).startOf('month').format('YYYY-MM-DD');
                let endOfMonth   = moment(new Date((new Date(year, moment(this.$refs.calendar.value).format('M'), 1)) - 1)).endOf('month').format('YYYY-MM-DD');
                this.getWebMonth(startOfMonth, endOfMonth)
              }, 300)
            },
            showEvent ({ nativeEvent, event }) {
              axios.get('/menus/' + event.id + '?meals').then(res => {
                let menu = res.data
                menu.meals.forEach(el => el.type = el.groups.map(i=>i.group))
                this.selectedDate = menu
                this.dialog = true
              })
            },
            updateRange ({ start, end }) {

            },
            rnd (a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            },
            updateItemsPerPage (number) {
                this.itemsPerPage = number
            },
        },
        watch: {
          '$i18n.locale'() {
            if(this.loaded)
              this.getWeb()
            else
              this.loaded = true
          }
        }
    }
</script>
<style>
.v-card__title {
  word-break: break-word;
}
.lds-dual-ring {
    display: inline-block;
    width: 20px;
    height: 20px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    margin: 2px;
    border-radius: 50%;
    border: 2px solid #ec5822;
    border-color: #ec5822 transparent#ec5822 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

</style>
