<template>
  <v-tabs v-model="model">
    <v-tab
      v-show="selectedMenu.shiftOne && Object.keys(selectedMenu.shiftOne).length && company.shift_one"
      :disabled="shiftEnable != 'all' && shiftEnable != 'shiftOne'"
    >
<!--     {{$t('first_shift.one')}}-->
     {{$t('first_shift')}}
<!--     1-->
    </v-tab>
    <v-tab
      v-show="selectedMenu.shiftTwo && Object.keys(selectedMenu.shiftTwo).length && company.shift_two"
      :disabled="shiftEnable != 'all' && shiftEnable != 'shiftTwo'"
    >
<!--      {{$t('shift.two')}}-->
      {{$t('second_shift')}}
<!--     2-->
    </v-tab>
    <v-tab
      v-show="selectedMenu.shiftThree && Object.keys(selectedMenu.shiftThree).length && company.shift_three"
      :disabled="shiftEnable != 'all' && shiftEnable != 'shiftThree'"
    >
<!--      {{$t('shift.three')}}-->
      {{$t('third_shift')}}
<!--     3-->
    </v-tab>
  </v-tabs>
</template>

<script>

export default {
  props: {
    value: {
      type: Number
    },
    selectedMenu: {
      type: Object,
      required: true
    },
    shiftEnable: {
      type: String,
      required: true
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit('input', value)
      },
      deep: true
    },
    company() {
      return this.$store.state.company;
    }
  }
}

</script>
