import { render, staticRenderFns } from "./PostReport.vue?vue&type=template&id=554e25c0&scoped=true"
import script from "./PostReport.vue?vue&type=script&lang=js"
export * from "./PostReport.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "554e25c0",
  null
  
)

export default component.exports