<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-card-title class="pa-0">
          <v-toolbar dark :color="topBarColor">
            <v-btn icon dark @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{
              translate("Клонирање менија")}}
              <span>
              {{'| ' + $store.state.restaurant.parent ? $store.state.restaurant.parent != null ? $store.state.restaurant.parent.name : '' : '' }}
              </span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>

            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-sheet height="64">
            <v-toolbar
                flat
            >
              <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
              >
                {{translate('Данашњи дан')}}
              </v-btn>
              <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="prev"
              >
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="next"
              >
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-toolbar-title>
                <template v-if="$refs.calendar">{{ $refs.calendar.title }}</template>
                <template v-else>{{ moment().format('MMMM YYYY') }}</template>
              </v-toolbar-title>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="old_events"
                :event-more="false"
                :event-color="getEventColor"
                :type="type"
                :weekday-format="myDayFormat"
                :weekdays="weekdays"
                @click:event="showEvent"
                @change="updateRange"
                @click:date="test"
            ></v-calendar>
            <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
            >
              <v-card
                  color="grey lighten-4"
                  min-width="350px"
                  flat
              >
                <v-toolbar
                    :color="selectedEvent.color"
                    dark
                >
                  <v-btn icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <ul>
                    <template v-for="menu in selectedEvent.menus">
                      <li>{{menu}}</li>
                    </template>
                  </ul>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                      text
                      color="secondary"
                      @click="selectedOpen = false"
                  >
                    {{translate(' Откажи')}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>
    <dialog-menu :old_events="old_events" :new_events="new_events" :dialog="dialog1" :selectedDate="selectedDate1" @close="dialog1 = false"></dialog-menu>
  </div>
</template>

<script>
import axios from "../../plugins/axios";
import DialogMenu from './dialog2'
import conf from "../../config";
import moment from "moment/moment";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    selectedDate: { type: Object, default: false },
    new_events: { type: Array },
    old_events: { type: Array },
  },
  components: {
    DialogMenu
  },
  computed: {
    topBarColor() {
      return this.$store.state.restaurant && this.$store.state.restaurant.settings
          ? this.$store.state.restaurant.settings.topbar
              ? this.$store.state.restaurant.settings.topbar.background
              : "primary"
          : this.$store.state.company &&
          this.$store.state.company.restaurant.settings
              ? this.$store.state.company.restaurant.settings.topbar.background
              : "primary";
    },
  },
  data: () => ({
    moment: moment,
    focus: '',
    menu: null,
    type: 'month',
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    dialog1: false,
    selectedDate1: {},
  }),
  created() {
    this.$on('cloneParentMenu', (data) => {
      let prep = {}
      prep['selected_meals'] = data.selected_meals
      prep['parent_menu_id'] = data.parent_menu_id
      prep['restoran_parent_id'] = data.restoran_parent_id
      prep['selected_date'] = this.selectedDate1
      this.$parent.$emit('cloneParentMenuEmit', prep)
    })
  },
  methods: {
    translateName(name) {
      let ime = '';
      if (name == 'rucak') {
        ime = 'ручак'
      } else {
        ime = name
      }
      return ime
    },
    test(data) {
      let check = this.new_events.find(item => item.date === data.date)

      if(!check) {
        this.selectedDate1 = {
          date: data.date,
          meals: [],
          name: 'rucak'
        }
        this.dialog1 = true
      }
    },
    color(type) {
      switch(type) {
        case 'dorucak': return 'green'
          break
        case 'rucak': return 'blue'
          break
        case 'vecera': return 'red'
          break
      }
    },
    myDayFormat(day) {
      switch(day.weekday) {
        case 1: return this.translate('Понедјељак')
          break
        case 2: return this.translate('Уторак')
          break
        case 3: return this.translate('Сриједа')
          break
        case 4: return this.translate('Четвртак')
          break
        case 5: return this.translate('Петак')
          break
        case 6: return this.translate('Субота')
          break
        case 0: return this.translate('Недјеља')
          break
      }
    },
    getEventColor (event) {
      return event.color = 'primary'
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      this.selectedDate1 = event
      this.dialog1 = true
    },
    updateRange ({ start, end }) {

    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    },
  }
};
</script>
