<template>
  <div style="width: 90%; margin: 0 auto;">
    <div style="display: flex;align-items: center">

<!--      <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" dark small>-->
<!--        <v-icon>mdi-plus</v-icon> {{ $t('delivery')}}-->
<!--      </v-btn>-->
    </div>
    <div class="mt-9">
      <v-data-table
          :headers="headers"
          :items="posts || []"
          :search="search"
          sort-by="name"
          class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field style="width: 50%"
              class="pr-4 py-5"
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              dense
            ></v-text-field>
            <v-dialog v-model="dialog_add" max-width="70%" scrollable style="border-radius: 12px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-card class="dialog-restoran" style="border-radius: 12px">
              <v-card-title class="primary" style="display: flex;justify-content: space-between">
                <span class="headline white--text">
                  {{ $t(formTitle) }}
                </span>
                <span @click.prevent="dialog_add = false" style="color: #fff;padding: 1px 12px;border-radius: 50px;border:1px solid;cursor: pointer">x</span>
              </v-card-title>

              <v-card-text class="p-4">
                <v-row>
                  <v-col cols="12" class="mb-0 pb-0">
                    <v-row>
                      <v-col cols="4" class="mb-0 pb-0">
                        <v-text-field
                            v-model="addItem.name"
                            :label="$t('*title')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" class="mb-0 pb-0">
                        <v-select
                            v-model="addItem.country"
                            :items="countries || []"
                            :label="'* ' + $t('country')"
                            item-text="name"
                            item-id="id"
                            return-object
                        ></v-select>
                      </v-col>
                      <v-col cols="4" class="mb-0 pb-0">
                        <v-select
                            v-model="addItem.city_id"
                            :items="addItem.country.city || []"
                            :label="'* ' + $t('city') + '(' + translate('седиште фирме') + ')'"
                            item-text="name"
                            item-id="id"
                            return-object
                        ></v-select>
                      </v-col>
                      <v-col cols="4" class="mb-0 pb-0">
                        <v-text-field
                            v-model="addItem.address"
                            :label="'* ' + $t('address')">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" class="mb-0 pb-0">
                        <v-text-field
                            v-model="addItem.phone"
                            :label="$t('phone')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" class="mb-0 pb-0">
                        <v-text-field
                            v-model="addItem.email"
                            :label="'* ' + $t('email')">
                        </v-text-field>
                      </v-col>
                      <v-col cols="5" class="mb-0 pb-0">
                        <v-checkbox
                          :label="translate('Локал')"
                          type="number"
                          outlined
                          v-bind:false-value="0"
                          v-bind:true-value="1"
                          v-model.number="addItem.local">
                        </v-checkbox>
                        <span v-if="addItem.local">
                        <span class="pt-4 d-block" v-if="! addItem.country">{{ translate('Изаберите државу')}}</span>
                        <span v-else>
                          <v-select
                              v-model="addItem.local_cities"
                              :items="addItem.country.city || []"
                              :label="translate('Изаберите у којим градовима вршите доставу')"
                              item-text="name"
                              item-id="id"
                              item-value="id"
                              multiple
                          ></v-select>
                        </span>
                     </span>
                      </v-col>
                      <v-col cols="5" class="mb-0 pb-0">
                        <v-checkbox class="ml-4"
                          :label="translate('Међуградско')"
                          type="number"
                          outlined
                          v-bind:false-value="0"
                          v-bind:true-value="1"
                          v-model.number="addItem.intercity">
                        </v-checkbox>
                        <span v-if="addItem.intercity">
                          <span class="pt-4 d-block" v-if="! addItem.country">{{ translate('Изаберите државу')}}</span>
                          <span v-else class="pt-4">
                           <v-checkbox class="ml-4"
                             :label="translate('Достава се врши по целој држави')"
                             type="number"
                             outlined
                             v-bind:false-value="0"
                             v-bind:true-value="1"
                             v-model.number="addItem.all_cities">
                           </v-checkbox>
                           <span v-if="! addItem.all_cities">
                             <v-row>
                                <v-col cols="8" class="mb-0 pt-0 pb-0">
                                  <span v-for="(route, index) in addItem.routes" class="d-flex">
                                    <v-select
                                        v-model="route.city"
                                        :items="addItem.country.city || []"
                                        :label="translate('Рута') + (index + 1)"
                                        item-text="name"
                                        item-id="id"
                                        item-value="id"
                                    ></v-select>
                                    <a v-if="addItem.routes.length > 1" href="#" class="pt-5" @click.prevent="removeFromCities(index)">
                                      <v-icon style="color: red;margin-left: 5px;font-size: 19px">mdi-trash-can</v-icon>
                                    </a>
                                  </span>
                                </v-col>
                                <v-col cols="4" class="mb-0 pt-3 pb-0">
                                  <v-btn style="border-radius: 8px;" color="primary darken-1 px-5" dark @click="addMoreRoutes(addItem.country.city)">{{ $t ('route') }}</v-btn>
                                </v-col>
                             </v-row>
                           </span>
                         </span>
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    style="border-radius: 8px;background: none;"
                    text
                    color="primary"
                    class="px-5"
                    @click="close"
                >
                  {{$t ('cancel')}}
                </v-btn>
                <v-btn
                    style="border-radius: 8px;"
                    color="primary darken-1 px-5"
                    dark
                    @click="save()"
                >
                  {{$t ('save')}}
                </v-btn>
              </v-card-actions>
            </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-switch
              @click="switchStatus(item)"
              v-model="item.status"
              :false-value="0"
              :true-value="1"

          ></v-switch>
        </template>
        <template v-slot:item.coverage="{ item }">
          {{ item.coverage == 1 ? 'Local' : 'Medjugradsko' }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editPost(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="openDeletePost(item)">
              mdi-delete
            </v-icon>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="dialog" max-width="90%" scrollable>
      <v-card>
        <v-card-text style="padding: 0;margin: 0">
          <v-card elevation="0">
<!--            <v-card-title style="padding: 5px;margin: 0">-->
<!--              <v-spacer></v-spacer>-->
<!--              <v-btn icon @click="dialog = false">-->
<!--                <v-icon x-large>mdi-close</v-icon>-->
<!--              </v-btn>-->
<!--            </v-card-title>-->
            <v-card-title class="primary" style="display: flex;justify-content: space-between">
                <span class="headline white--text">
                 {{ $t('edit')}}
                </span>
              <span @click.prevent="dialog = false" style="color: #fff;padding: 1px 12px;border-radius: 50px;border:1px solid;cursor: pointer">x</span>
            </v-card-title>
            <v-card-text>
              <v-card elevation="0" class="pa-0">
                <v-card-title>
                </v-card-title>
                <v-card-text class="mb-4" style="width: 100%">
                  <v-row>
                    <v-col cols="7" class="mb-0 pb-0">
                      <v-row>
                        <v-col cols="4" class="mb-0 pb-0">
                          <v-text-field
                              v-model="editedItem.name"
                              :label="$t('*title')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="mb-0 pb-0">
                          <v-select
                              v-model="editedItem.country"
                              :items="countries || []"
                              :label="'* ' + $t('country')"
                              item-text="name"
                              item-id="id"
                              return-object
                          ></v-select>
                        </v-col>
                        <v-col cols="4" class="mb-0 pb-0">
                          <v-select v-if="editedItem.country && editedItem.country.city"
                              v-model="editedItem.city"
                              :items="editedItem.country.city ? editedItem.country.city : []"
                              :label="'* ' + $t('city') + '(' + translate('седиште фирме') + ')'"
                              :item-text="getItemText"
                              item-id="id"
                              return-object
                          ></v-select>
                        </v-col>
                        <v-col cols="4" class="mb-0 pb-0">
                          <v-text-field
                              v-model="editedItem.address"
                              :label="'* ' + $t('address')">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" class="mb-0 pb-0">
                          <v-text-field
                              v-model="editedItem.phone"
                              :label="$t('phone')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="mb-0 pb-0">
                          <v-text-field
                              v-model="editedItem.email"
                              :label="'* ' + $t('email')">
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="5" class="mb-0 pb-0">
                       <!-- POKRIVENOST-->
<!--                    <v-col cols="12" class="mb-0 pt-0 pb-0">-->
<!--                      <v-select style="width: 100%"-->
<!--                        v-model="editedItem.coverage"-->
<!--                        :items="[-->
<!--                          {val: 1, name: 'Локал', id: 1},-->
<!--                          {val: 2, name: 'Међуградско', id: 2},-->
<!--                        ]"-->
<!--                        item-value="val"-->
<!--                        :item-text="getItemText"-->
<!--                        :label="$t('coverage')"-->
<!--                        item-id="id"-->
<!--                        return-object-->
<!--                      >-->
<!--                      </v-select>-->
<!--                    </v-col>-->
                    <v-col cols="12" class="mb-0 pt-0 pb-0">
                      <span v-if="editedItem.coverage.val == 1">
                        <span v-if="! editedItem.country">{{ translate('Изаберите државу')}}</span>
                        <span v-else>
                          <v-select
                              v-model="editedItem.city_post"
                              :items="editedItem.country.city || []"
                              :label="translate('Изаберите у којим градовима вршите доставу')"
                              :item-text="getItemText"
                              item-id="id"
                              item-value="id"
                              multiple
                              return-object
                          ></v-select>
                        </span>
                     </span>
                      <span v-if="editedItem.coverage.val == 2">
                       <span v-if="! editedItem.country">{{ translate('Изаберите државу')}}</span>
                       <span v-else>
                         <v-checkbox
                           :label="translate('Достава се врши по целој држави')"
                           type="number"
                           outlined
                           v-bind:false-value="0"
                           v-bind:true-value="1"
                           v-model.number="editedItem.all_cities">
                         </v-checkbox>
                         <span v-if="editedItem.all_cities != 1">
                           <v-row>
                              <v-col cols="8" class="mb-0 pt-0 pb-0">
                                <span v-for="(route1, index) in editedItem.city_post" class="d-flex">
                                  <v-select
                                      v-model="route1.city"
                                      :items="editedItem.country.city || []"
                                      :label="translate('Рута') + (index + 1)"
                                      :item-text="getItemText"
                                      item-id="id"
                                      item-value="id"
                                      return-object
                                  ></v-select>
                                  <a v-if="editedItem.city_post.length > 1" href="#" class="pt-5" @click.prevent="removeFromCities(index)">
                                    <v-icon style="color: red;margin-left: 5px;font-size: 19px">mdi-trash-can</v-icon>
                                  </a>
                                </span>
                              </v-col>
                              <v-col cols="4" class="mb-0 pt-3 pb-0">
                                <v-btn style="border-radius: 8px;" color="primary darken-1 px-5" dark @click="addMoreRoutes(editedItem.country.city)">{{ $t ('route') }}</v-btn>
                              </v-col>
                           </v-row>
                         </span>
                       </span>
                     </span>
                    </v-col>
                  </v-col>
                  </v-row>
                  <div class="text-right mt-3">
                    <v-btn outlined style="border-radius: 9px" color="orange darken-4" small class="mr-2" @click="editMessage">
                      {{ $t("edit") }}
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline text-center">{{translate ('Потврдите брисање')}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDelete">{{$t('cancel')}}</v-btn>
          <v-btn style="background-color: #fb6d3b !important;color: #fff" color="darken-1" text @click="deletePost">{{$t ('confirm')}}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "../plugins/axios";
import conf from "../config";

export default {
  data() {
    return {
      conf: conf,
      dialog: false,
      dialog_add: false,
      dialogDelete: false,
      poruka: '',
      search: '',
      posts: '',
      restorani: [],
      restoran: '',
      kompanije: [],
      kompanija: '',
      korisnici: [],
      korisnik: [],
      edit_post: '',
      editedItem: {
        name: '',
        coverage: '',
        address: '',
        city_id: '',
        cities: [],
        local: 0,
        intercity: 0,
        local_cities: [],
        country: '',
        phone: '',
        routes: [{
          city: ''
        }],
        email: '',
        all_cities: 1,
      },
      addItem: {
        name: '',
        coverage: '',
        address: '',
        city_id: '',
        cities: [],
        local: 0,
        intercity: 0,
        local_cities: [],
        country: '',
        phone: '',
        routes: [{
          city: ''
        }],
        email: '',
        all_cities: 1,
      },
      defaultItem: {
        name: '',
        coverage: '',
        address: '',
        city_id: '',
        cities: [],
        country: '',
        phone: '',
        email: '',
        all_cities: 1,
      },
      cities: '',
      countries: '',
      editedIndex: -1,
      headers: [
        {text: 'Id', align: 'start', value: 'id', sortable: true},
        {text: 'title', align: 'start', value: 'name', sortable: true},
        {text: 'coverage', align: 'start', value: 'coverage', sortable: true},
        {text: 'address', align: 'start', value: 'address', sortable: true},
        {text: 'phone', align: 'start', value: 'phone', sortable: true},
        {text: 'email', align: 'start', value: 'email', sortable: true},
        {text: 'status', align: 'start', value: 'status', sortable: true},
        { text: 'actions', align: 'center', value: 'actions', sortable: false },
      ],
      deleteItem: ''
    };
  },

  created() {
    this.load()
    this.getCities()
    this.getCountries()
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? this.$t('add') : this.$t('send_by_mail') + ': ' + this.editedItem.id
    },
  },

  methods: {
    removeFromCities(index) {
      this.editedItem.city_post.splice(index, 1)
    },
    addMoreRoutes(cities) {
      this.addItem.city_post.push({
        city: '',
        cities: cities
      })
    },
    getItemText(item) {
      return this.translate(`${item.name}`);
    },
    getCity() {

    },
    switchStatus(item) {
      axios.post('/post/edit-status', item).then(({data}) => {
        if (data) {
          console.log('OK')
        }
      })
    },
    openDeletePost(post) {
      this.deleteItem = post
      this.dialogDelete = true
    },
    closeDelete() {
      this.dialogDelete = false
    },
    getCountries() {
      axios.get('/post/get-country').then(({data}) => {
        this.countries = data.sort((a, b) => a.name - b.name);
      })
    },
    getCities() {
      axios.get('/post/all-cities').then(({data}) => {
        this.cities = data.sort((a, b) => a.name - b.name);
      })
    },
    close() {
      this.dialog_add = false
    },
    save() {
      axios.post('/post/store-post', this.addItem).then(({data}) => {
        if (data) {
          this.posts = data
          this.dialog_add = false
          this.addItem.name = ''
          this.addItem.coverage = '';
          this.addItem.address = '';
          this.addItem.city_id=  '';
          this.addItem.country=  '';
          this.addItem.phone =  '';
          this.addItem.email = '';
        }
        this.$forceUpdate()
      })
    },
    load() {
      axios.get('/post/all').then(({data}) => {
        data.filter(item => {
          item.coverage = {}
          let selected_citise = []
          if (item.city_post[0].local == 1) {
            item.coverage.val = 1
            item.coverage.name = 'Локал'
            item.coverage.id = 1
          }
          if (item.city_post[0].intercity == 1) {
            item.coverage.val = 2
            item.coverage.name = 'Међуградско'
            item.coverage.id = 2
          }
          if (item.city_post.length > 0) {
            item.city_post.filter(city => {
              city.name = city.city.name
              city.city.id = parseInt(city.city.id)
              selected_citise.push(city.city)
              city.id = city.city.id
            })
          }
          item.city_id = selected_citise
        })
        this.posts = data
      })
    },
    editPost(poruka) {
      this.dialog = true
      this.editedItem = poruka
      this.$forceUpdate()
    },
    saveMessage() {
      if (this.poruka != '') {
        axios.post('/poruke/sacuvaj-poruku', {
          'message': this.poruka
        }).then(({data}) => {
          if (data) {
            this.posts.push(data.data)
            this.poruka = ''
          }
        })
      }
    },
    editMessage() {
      axios.post('/post/edit-post', this.editedItem).then(({data}) => {
        if(data) {
          this.posts.filter(item => {
            if (item.id == data.id) {
              item = data
            }
          })
          this.dialog = false
        }
      })
    },
    deletePost(poruka) {
      axios.post('/post/delete-post', this.deleteItem).then(({data}) => {
        if(data) {
          this.posts.filter((item,index) => {
            if (item.id == this.deleteItem.id) {
              this.posts.splice(index, 1)
            }
          })
          this.$forceUpdate()
          this.dialogDelete = false
        }
      })
    }
  },
};
</script>

<style scoped>
label {
  display: inline-block;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}

@media (max-width: 767px) {
  .col-sm-4 {
    max-width: 33.333%;
  }
}
</style>