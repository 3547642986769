<template>
<div style="width: 95%;margin: 0 auto">
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("branches") }}</v-toolbar-title>
        
         <v-spacer></v-spacer>
         <v-btn :style="'border-radius: 8px;border: 1px solid rgb(251, 109, 59);background: none;color: rgb(251, 109, 59);box-shadow: none;text-transform: capitalize'"
              dark
              class="mb-2 mr-2"
              v-bind="attrs"
              v-on="on"
              small
            @click="print('print')" >
          <v-icon class="mr-2">mdi-printer</v-icon>{{ $t("print") }}
         </v-btn>
        <v-dialog v-model="dialog" max-width="600px" scrollable>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :style="'border-radius: 8px;border: 1px solid rgb(251, 109, 59);background: none;color: rgb(251, 109, 59);box-shadow: none;text-transform: capitalize'"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon>mdi-plus</v-icon>
              {{$t("add_firm")}}
            </v-btn>
           
          </template>
          
          <v-card>
            <v-card-title class="primary">
              <span class="headline white--text"
                >{{ translate(formTitle) }} | ID: {{ editedItem.id }}</span
              >
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-switch
                      v-model="editedItem.state"
                      inset
                      :label="$t('active')"
                      :false-value="0"
                      :true-value="1"
                      dense
                    ></v-switch>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.name"
                      :rules="[translate('Обавезно поље')]"
                      :label="translate('*Назив')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                            v-model="editedItem.type"
                            :items="[{text:translate('мали'),val:'small'}, {text:translate('велики'),val:'large'}]"
                           item-text="text"
                          item-value="val"
                            :label="translate('*Тип јеловника доступан овој фирми:')"
                            :rules="[translate('Обавезно поље')]"
                    ></v-select>
                  </v-col>

                  
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.city"
                      :label="translate('Град')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.address"
                      :label="$t('address')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.phone"
                      :label="$t('phone_no')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.email"
                      :label="$t('email')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.lib"
                      :label="translate('ЈИБ')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.pib"
                      :label="translate('ПИБ')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="editedItem.description"
                      :label="$t('description')"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <b>{{ $t("mark_shift_comp_recieves_meals") }}</b> <br><br>
                    <b>{{ $t("1st_shift_restaurant_menu") }}</b>
                    <v-radio-group v-model="editedItem.shift_one" row>
                      <v-radio
                        :label="$t('shit_one')"
                        :value="1"
                      ></v-radio>
                      <v-radio
                        :label="$t('shit_two')"
                        :value="2"
                      ></v-radio>
                      <v-radio
                        :label="$t('shit_three')"
                        :value="3"
                      ></v-radio>
                      <v-radio
                        :label="$t('closed')"
                        :value="0"
                      ></v-radio>
                    </v-radio-group>
                    <b>{{ translate("Мени ресторана за другу смјену") }}</b>
                    <v-radio-group v-model="editedItem.shift_two" row>
                      <v-radio
                        :label="$t('shit_one')"
                        :value="1"
                      ></v-radio>
                      <v-radio
                        :label="$t('shit_two')"
                        :value="2"
                      ></v-radio>
                      <v-radio
                        :label="$t('shit_three')"
                        :value="3"
                      ></v-radio>
                      <v-radio
                        :label="$t('closed')"
                        :value="0"
                      ></v-radio>
                    </v-radio-group>
                    <b>{{ $t("3rd_shift_restaurant_menu") }}</b>
                    <v-radio-group v-model="editedItem.shift_three" row>
                      <v-radio
                        :label="$t('shit_one')"
                        :value="1"
                      ></v-radio>
                      <v-radio
                        :label="$t('shit_two')"
                        :value="2"
                      ></v-radio>
                      <v-radio
                        :label="$t('shit_three')"
                        :value="3"
                      ></v-radio>
                      <v-radio
                        :label="$t('closed')"
                        :value="0"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn color="primary" dark @click="save">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">{{
              $t("delete_confirmation")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Откажи</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Потврди</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
  <v-data-table
    :headers="headers"
    :items="desserts"
    :search="search"
    sort-by="calories"
    class="elevation-1"
    id="print"
    dense
    show-expand
  >
    <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }"  >
                {{translate(header.text)}}
    </template>
    <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item }">
                {{translate(item[values.value])}}
    </template>
    <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
      <template v-if="item.children && item.children.length">
        <v-btn :style="'border-radius: 8px;border: 1px solid rgb(251, 109, 59);background: none;color: rgb(251, 109, 59);box-shadow: none;text-transform: capitalize'"
          dark
          small
          rounded
          @click="expand(true)"
          v-if="!isExpanded"
        >
        <v-icon>mdi-arrow-down</v-icon>
          {{ translate("Пословнице") }}
        </v-btn>
        <v-btn :style="'border-radius: 8px;border: 1px solid rgb(251, 109, 59);background: none;color: rgb(251, 109, 59);box-shadow: none;text-transform: capitalize'" dark small rounded @click="expand(false)" v-else>
          <v-icon>mdi-arrow-up</v-icon>
          {{ translate("Сакриј") }}
        </v-btn>
      </template>
    </template>
    
    <template v-slot:expanded-item="{ item, isExpanded, expand }">
      <template v-if="item.children">
        <td :colspan="headers.length" class="pa-0">
          <v-data-table
            dense
            :headers="headers"
            class="elevation-1 grey lighten-4"
            :items="item.children || []"
            disable-pagination
            hide-default-footer
            hide-default-header
          >
            <v-spacer></v-spacer>
            <template v-slot:item.actions="{ item, index }">
              <v-btn
                small
                color="rgb(251, 109, 59)"
                text
                class="mx-2"
                :to="{ name: 'firma-id-korisnici', params: { id: item.id, name:item.name, parent_id:item.parent_id } }"
              >
                {{ translate("Корисници") }} {{ item.users_count }}
              </v-btn>
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </td>
      </template>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
        small
        color="rgb(251, 109, 59)"
        text
        class="mx-2"
        :to="{ name: 'firma-id-korisnici', params: { id: item.id,name:item.name, parent_id:item.parent_id } }"
        v-if="
          (item.children && item.children.length == 0) ||
          !item.hasOwnProperty('children')
        "
      >
        {{ translate("Корисници") }} {{ item.users_count }}
      </v-btn>
      <v-btn
        small
        color="rgb(251, 109, 59)"
        text
        class="mx-2"
        :to="{ name: 'firma-id-korisnici', params: { id: item.id,name:item.name } }"
        v-else
      >
        {{ translate("Корисници") }}
        {{
          (item.children || []).reduce((sum, item) => {
            return item.users_count?sum + item.users_count:sum;
          }, 0)
        }}
      </v-btn>
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="rgb(251, 109, 59)" style="color: #fff" @click="initialize">
        {{ translate("Ресетуј") }}
      </v-btn>
    </template>
  </v-data-table>
</div>
</template>

<script>
import axios from "../plugins/axios";

export default {
  data: () => ({
    dialog: false,
    search: "",
    dialogDelete: false,
    headers: [
      {
        text: "Id",
        align: "end",
        value: "id",
        //width: '25px'
      },
      {
        text: "Назив",
        align: "start",
        //width: '15%',
        value: "name",
      },
      {
        text: "Адреса",
        align: "start",
        value: "address",
        // width: '15%',
        sortable: false,
      },
      {
        text: "Email",
        align: "start",
        //width: '15%',
        value: "email",
        sortable: false,
      },

      {
        text: "Акција",
        align: "center",
        value: "actions",
        //width: '15%',
        sortable: false,
      },
      {
        text: "",
        //width: '15%',
        value: "data-table-expand",
      },
    ],
    desserts: [],
    editedIndex: -1,
    editedParentIndex: -1,
    editedItem: {
      name: "",
      city: "",
      address: "",
      phone: "",
      email: "",
      lib: "",
      pib: "",
      description: "",
      state: 1,
      shift_one: 1,
      shift_two: 2,
      shift_three: 3,
      type:  'small'
    },
    defaultItem: {
      name: "",
      city: "",
      address: "",
      phone: "",
      email: "",
      lib: "",
      pib: "",
      description: "",
      state: 1,
      shift_one: 1,
      shift_two: 2,
      shift_three: 3,
      type:  'small'
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Нова пословница" : "Измјена пословнице";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      axios.get("companies/parent/"+this.$store.state.company.id).then((res) => {
        this.desserts = res.data;
      });
    },

    editItem(item) {
      if(item.id!=this.$store.state.company.id)item.parent_id=this.$store.state.company.id;
      if (item.parent_id) {
        this.editedIndex = this.desserts.findIndex(
          (i) => i.id === item.parent_id
        );
        this.editedParentIndex = this.desserts[
          this.editedIndex
        ].children.findIndex((i) => (i.id = item.id));
      } else {
        this.editedIndex = this.desserts.indexOf(item);
      }

      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      if (item.parent_id) {
        this.editedIndex = this.desserts.findIndex(
          (i) => i.id === item.parent_id
        );
        this.editedParentIndex = this.desserts[
          this.editedIndex
        ].children.findIndex((i) => (i.id = item.id));
      } else {
        this.editedIndex = this.desserts.indexOf(item);
      }
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      axios.delete("/companies/" + this.editedItem.id).then((res) => {
        if (this.editedParentIndex > -1) {
          this.desserts[this.editedIndex].children.splice(
            this.editedParentIndex,
            1
          );
        } else {
          this.desserts.splice(this.editedIndex, 1);
        }
        this.closeDelete();
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedParentIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedParentIndex = -1;
      });
    },

    save() {
      this.editedItem.parent_id = this.editedItem.id!=this.$store.state.company.id
        ? this.$store.state.company.id
        : 0;
      if (this.editedIndex > -1 || this.editedParentIndex > -1) {
        let _editedIndex = this.editedIndex;
        let _editedParentIndex = this.editedParentIndex;
        let _editedItem = this.editedItem;

        axios
          .put("/companies/" + this.editedItem.id, this.editedItem)
          .then((res) => {
            if (_editedParentIndex > -1) {
              Object.assign(
                this.desserts[_editedIndex].children[_editedParentIndex],
                _editedItem
              );
            } else {
              Object.assign(this.desserts[_editedIndex], _editedItem);
            }
          });
      } else {
        let _editedItem = this.editedItem;
        axios.post("/companies", this.editedItem).then((res) => {
          _editedItem.id = res.data.id;
          if (_editedItem.parent_id) {
            let i = this.desserts.findIndex(
              (i) => i.id === _editedItem.parent_id
            );
            this.desserts[i].children.push(_editedItem);
          } else {
            this.desserts.push(_editedItem);
          }
        });
      }
      this.close();
    },
    print(id, type = false) {
      const prtHtml = document.getElementById(id).innerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      console.log(stylesHtml);
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
                                    <html>
                                      <head>
                                        ${stylesHtml}
                                        
                                      </head>
                                      <body>
                                        ${prtHtml}
                                      </body>
                                    </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
      
    },
  },
};
</script>