<script>
import conf from "@/config";
import moment from "moment/moment";
import axios from "@/plugins/axios";

export default {
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
      dateRangeText: '',
      send_type: 1,
      conf: conf,
      name: "Намирнице",
      search: "",
      pretraga: "",
      moment: moment,
      date: [new Date().toISOString().substr(0, 10)],
      date1: new Date().toISOString().substr(0, 7),
      dates: null,
      menu: false,
      dialogDetail: false,
      report: null,
      reportOrginal: null,
      modal: false,
      printTable: "table3",
      users: [],
      dialog: false,
      headers: [
        { text: this.$t('r_b'), value: "num",},
        { text: this.$t('delivery_post'), value: "post" },
        { text: this.$t('sender'), value: "sender" },
        { text: this.$t('receiver'), value: "receiver" },
        { text: this.$t('type'), value: "send_type" },
        { text: this.$t('date'), value: "send_date" },
        { text: this.$t('details'), value: "action" },
      ],
      tab: null,
      items: [
        {
          tab: this.$t('groceri'),
          content: "Tab 3 Content",
          print: "table3",
          icon: "mdi-corn",
        },
        {
          tab: this.$t('meals_main'),
          content: "Tab 2 Content",
          print: "table2",
          icon: "mdi-food-drumstick",
        },
        {
          tab: this.$t('meals'),
          content: "Tab 1 Content",
          print: "table1",
          icon: "mdi-bowl-mix",
        },
        {
          tab: 'НАРУЧЕНО',
          content: "Tab 4 Content",
          print: "table34",
          icon: "mdi-grid",
        },
      ],
      isRange: true,
      item_detail: '',
      time_delivery: 0,
    };
  },
  watch: {
    '$i18n.locale'() {
      if(this.$i18n.locale == 'en') {
        this.$forceUpdate()
      }
    },
  },
  methods: {
    openDialogDetail(item) {
      this.dialogDetail = true
      this.item_detail = item
    },
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    changeView() {
      if (this.select_view == 1) {
        this.report = this.reportOrginal
      }
      if (this.select_view == 2) {
        this.reportForFood = this.report_food
      }
      if (this.select_view == 3) {
        let a = {}
        if (this.reportOrginal instanceof Object) {
          for (let shift in this.reportOrginal) {
            if (this.report_food[shift]) {
              a[shift] = this.reportOrginal[shift].concat(this.report_food[shift]);
            } else {
              a[shift] = this.reportOrginal[shift]
            }
          }
        } else {
          for (let shift in this.report_food) {
            for (let shift1 in this.report_food[shift]) {
              a[shift] = this.report_food[shift][shift1]
            }
          }
        }
        this.report = a
      }
      this.$forceUpdate()
    },
    totalUserQtu(shift) {
      let total = 0;
      shift.filter(item => {
        total += item.quantity
      })

      return total
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getItemText(item) {
      return this.translate(`${item.company}`);
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY.')
    },
    formatTime(time) {
      if(time) {
        let formatedTime = time.split(':')
        return formatedTime[0] + ':' + formatedTime[1]
      }
    },
    showMeal(id) {
      axios.get("/meals/" + id).then((res) => {
        this.selectedMeal = res.data;
        this.dialog = true;
        // //console.log(this.selectedMeal);
      });
    },
    showUsers(user) {
      this.selectedUser = user;
      ////console.log(this.selectedUser);
      this.dialog2 = true;
    },
    toggle() {
      if (this.tab == 3) {
        if (this.search2.length > 0) {
          this.search2 = []
          this.findFoods()
        } else
        if (this.search2.length == 0) {
          this.search2 = []
          this.report_food_original.companies.filter(com => {
            this.search2.push(com.id)
          })
          this.findFoods()
        }
      } else {
        if (this.search2.length === this.desserts4.filter((i) => i.company).length) {
          this.search2 = [];
        } else {
          this.search2 = []
          this.search2 = this.desserts4
              .filter((i) => i.company)
              .map((i) => i.id);
        }
        this.findMeals();
      }
    },
    print(id, type = false) {
      if (id === "table6") {
        const WinPrint = window.open(
            "",
            "",
            "left=100,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );

        WinPrint.document.write(`<head>`);

        for (let index in this.meals) {
          const prtHtml = document.getElementById(`table6_${index}`).innerHTML;
          let stylesHtml = "";
          for (const node of [
            ...document.querySelectorAll('link[rel="stylesheet"], style'),
          ]) {
            stylesHtml += node.outerHTML;
          }

          WinPrint.document.write(` ${stylesHtml}`);
        }

        WinPrint.document.write(`<style>
          table {
          font-family: arial, sans-serif;
          border-collapse: collapse;
          width: 100%;
          }
          td, th {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 8px;
          }
          tr:nth-child(even) {
          background-color: #f1f1f1;
          }
          .test_klasa td {
            vertical-align: unset !important;
            padding-top: 7px !important;
            padding-bottom: 7px !important;
          }
          </style>
          </head>`);

        for (let index in this.meals) {
          const prtHtml = document.getElementById(`table6_${index}`).innerHTML;
          let stylesHtml = "";
          for (const node of [
            ...document.querySelectorAll('link[rel="stylesheet"], style'),
          ]) {
            stylesHtml += node.outerHTML;
          }

          WinPrint.document.write(`
            <body>
              ${
              this.$store.state.restaurant.name
          }: ${index}   ${moment(
              this.date[0]
          ).format("DD.MM.YYYY.") + '-' + moment(this.date[1]).format("DD.MM.YYYY.")}
            <strong>${prtHtml}</strong>
          </body>`);

          WinPrint.document.write('<p style="page-break-after:always;"></p>');
        }

        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
      }
      else if (id === "table11") {
        const prtHtml = document.getElementById(id).innerHTML;
        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }
        const WinPrint = window.open("", "", "left=100,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");

        WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
              ${stylesHtml}
              <style>
                table {
                  font-family: arial, sans-serif;
                  border-collapse: collapse;
                  width: 100%;
                }

                td, th {
                  border: 1px solid #dddddd;
                  text-align: left;
                  padding: 8px;
                }

                tr:nth-child(even) {
                  background-color: #f1f1f1;
                }
                 .test_klasa td {
                  vertical-align: unset !important;
                  padding-top: 7px !important;
                  padding-bottom: 7px !important;
                }
                </style>
            </head>
            <body>
              ${this.userReportItem.name}: ${this.userReportItem.lastname} (ID: ${this.userReportItem.id}) - ${this.monthName} ${this.year}.
              <strong>${prtHtml}</strong>
            </body>
          </html>`);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
      } else {
        const prtHtml = document.getElementById(id).innerHTML;
        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }
        // //console.log(stylesHtml);
        // Open the print window
        const WinPrint = window.open(
            "",
            "",
            "left=100,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );

        WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
              ${stylesHtml}
              <style>
             table {
              font-family: arial, sans-serif;
              border-collapse: collapse;
              width: 100%;
            }

            td, th {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 8px;
            }

            tr:nth-child(even) {
              background-color: #f1f1f1;
            }
             .test_klasa td {
              vertical-align: unset !important;
              padding-top: 7px !important;
              padding-bottom: 7px !important;
            }
            </style>

            </head>
            <body>
              ${this.$store.state.restaurant.name}: ${this.name}   ${moment(this.date[0]).format("DD.MM.YYYY.") + '-' + moment(this.date[1]).format("DD.MM.YYYY.")}
              <strong>${prtHtml}</strong>
            </body>
          </html>`);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
      }
    },
    load() {
      this.search = "";
      this.menu_oborci = []
      if (this.date && this.date.length == 1) {
        this.date.push(this.date[0]);
      }
      if (this.date[0] == this.date[1]) {
        this.canSeeAddMeal = true
      }
      if (this.date && this.date[0] > this.date[1])
        [this.date[0], this.date[1]] = [this.date[1], this.date[0]];
      if (this.$store.state.restaurant) {
        axios.get(
          "post/reports?date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD") + '&sendType=' + this.send_type
        )
          .then(({data}) => {
            this.report = data;
            this.reportOrginal = data;
          });
      }
      this.$forceUpdate()
    },
    groupBy(array, key){
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]){
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    }
  },
  mounted() {
    this.onResize()
  },
  created() {
    this.load()
  },
  computed: {
    reportSearch() {
      let data = []
      if (this.searchR != '') {
        this.dessertsReport.filter(rep => {
          if (rep.shiftOne && rep.shiftOne.length > 0) {
            rep.shiftOne.filter(item => {
              if (item.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
          if (rep.shiftTwo && rep.shiftTwo.length > 0) {
            rep.shiftTwo.filter(item2 => {
              if (item2.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
          if (rep.shiftThree && rep.shiftThree.length > 0) {
            rep.shiftThree.filter(item3 => {
              if (item3.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
        })
      }
      if (this.select_view == 2) {
        return this.dessertsReportF
      }

      return data.length ? data : this.dessertsReport
    },
    daysInMonth() {
      return moment(this.month).daysInMonth()
    },
    monthName() {
      return moment(this.month, "M").format('MMMM').toUpperCase()
    },
    month() {
      return parseInt(this.date1.split('-')[1])
    },
    year() {
      return parseInt(this.date1.split('-')[0])
    },
    user() {
      return this.$store.state.user
    },
    desserts() {
      let arr = [];
      if (this.report) {
        for (let shift in this.report) {
          for (let shift2 in this.report[shift]) {
            let index = arr.findIndex((i) => i.menu === shift2);

            if (index > -1) {
              arr[index].shift_one +=
                  shift === "1-smena" ? this.report[shift][shift2].quantity : 0;
              arr[index].shift_two +=
                  shift === "2-smena" ? this.report[shift][shift2].quantity : 0;
              arr[index].shift_three +=
                  shift === "3-smena" ? this.report[shift][shift2].quantity : 0;
              arr[index].total += this.report[shift][shift2].quantity;
            } else {
              arr.push({
                menu: shift2,
                id: this.report[shift][shift2].id,
                shift_one:
                    shift === "1-smena" ? this.report[shift][shift2].quantity : 0,
                shift_two:
                    shift === "2-smena" ? this.report[shift][shift2].quantity : 0,
                shift_three:
                    shift === "3-smena" ? this.report[shift][shift2].quantity : 0,
                total: this.report[shift][shift2].quantity,
              });
            }
          }
        }

        arr.push({
          menu: "УКУПНО",
          shift_one: arr.reduce((sum, item) => {
            return sum + item.shift_one;
          }, 0),
          shift_two: arr.reduce((sum, item) => {
            return sum + item.shift_two;
          }, 0),
          shift_three: arr.reduce((sum, item) => {
            return sum + item.shift_three;
          }, 0),
          total:
              arr.reduce((sum, item) => {
                return sum + item.shift_one;
              }, 0) +
              arr.reduce((sum, item) => {
                return sum + item.shift_two;
              }, 0) +
              arr.reduce((sum, item) => {
                return sum + item.shift_three;
              }, 0),
        });
      }
      //console.log(arr);
      return arr;
    },
  },
};
</script>

<template>
  <section>
    <div style="width: 95%;margin: 10px auto">
      <div :class="windowSize.x < 600 ? 'block' : 'row'">
        <v-col sm="6" md="6" lg="3" style="margin-top: 2px;">
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateRangeText"
                  :label="$t('date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
              ></v-text-field>
            </template>
            <v-card>
              <strong>{{$t("select_span_meals_listing") }}</strong>
              <br />
              {{ $t("click_on_two_dates") }}<br />
              <v-date-picker v-model="date" range no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="
                    $refs.menu.save(date);
                    load();
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-card>
          </v-menu>
        </v-col>
        <v-col sm="6" md="6" lg="3">
          <v-autocomplete class="mt-6"
            v-model="send_type"
            :items="[
              { name: translate('Локал'), id: 1 },
              { name: translate('Међуградски'), id: 2 },
            ]"
            dense
            @input="load()"
            :label="$t('view')"
            item-text="name"
            item-value="id"
            clear
            chips
            small-chips
          >
          </v-autocomplete>
        </v-col>
        <v-col sm="6" md="6" lg="4">
          <v-text-field
              class="pt-5"
              v-model="pretraga"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              dense
          ></v-text-field>
        </v-col>
        <v-col sm="6" md="6" lg="2" class="my-6 px-3">
          <div class="row">
            <v-btn style="width: 50%" :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" @click="print(printTable)" small class="mt-2">
              <v-icon :style="'color:' + $store.state.restaurant.settings.topbar.background ? $store.state.restaurant.settings.topbar.background : 'rgb(251, 109, 59)'" class="mr-2">mdi-printer</v-icon>
              <span id="stampaj">
                {{ $t("print") }}
              </span>
            </v-btn>
          </div>
        </v-col>
      </div>
      <v-data-table
        :headers="headers"
        :items="report || []"
        sort-by="status"
        id="table7"
        class="elevation-1"
        dense
        hide-default-footer
        disable-pagination
        :search="pretraga"
      >
        <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
          <span class="py-3 d-inline-block">{{ translate(header.text) }}</span>
        </template>
        <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item }">
          <span :title="values.value == 'order_count' ? translate('Поручено оброка') : ''">{{ translate(item[values.value]) }}</span>
        </template>
        <template v-slot:item.id="{ item }">
          <span v-if="item.id == 'УКУПНО:'">{{ $t("totalU") }}</span>
          <span v-else @click.prevent="showUsers(item)">{{ item.id }} </span>
        </template>
        <template v-for="values in headers" v-slot:item.num="{ item, index }">
          #{{ item.id == 'УКУПНО' ? '' : item.id }}
        </template>
        <template v-slot:item.send_type="{ item }">
          <span>{{ item.send_type == 1 ? translate('Локал') : translate('Међуградски') }}</span>
        </template>
        <template v-slot:item.post="{ item }">
          <span>{{ item.post.name }} </span>
        </template>
        <template v-slot:item.sender="{ item }">
          <span>{{ item.sender.name }} {{ item.sender.lastname }}</span>
        </template>
        <template v-slot:item.receiver="{ item }">
          <span>{{ item.receiver.name }} {{ item.receiver.lastname }}</span>
        </template>
        <template v-slot:item.send_date="{ item }">
          <span :title="translate('Датум, време наручивања')" v-if="date[0] == date[1]">{{ item.id != 'УКУПНО:' ? moment(item.date).format('MM.DD.  HH:mm') : '' }}</span>
        </template>
        <template v-slot:item.action="{ item }">
          <a :title="$t('months_report')"  v-if="item.id != 'УКУПНО:'" href="" @click.prevent="openDialogDetail(item)">
            <v-icon style="font-size: 17px" color="primary">mdi-format-list-bulleted</v-icon>
          </a>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="dialogDetail" max-width="80%" scrollable>
      <v-card v-if="item_detail">
        <v-card-text>
          <v-card elevation="0">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialogDetail = false">
                <v-icon x-large>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mb-0">
              <v-row>
                <v-card-text align="center" class="title" style="margin-top: -40px">
                  <h3>{{ translate('Пошиљка бр') }}: #{{ item_detail.id }}</h3>
                </v-card-text>
                <v-card elevation="0" class="pa-5" style="width: 100%">
                  <v-row style="width: 100%">
                    <v-col class="mb-0 pb-0" cols="12">
                      <p class="font-weight-black">{{ translate('Поштарину плаћа прималац') }} | {{item_detail.send_date }}</p>
                    </v-col>
                    <v-col cols="4">
                      <h4 class="text-uppercase mb-1">{{ translate('Адреса пошиљаоца') }}</h4>
                      <p style="margin-bottom: 0;padding-bottom: 0">
                        <span style="color: #757575;font-size: 10px;display: block">{{ translate('Име и презиме') }}</span>
                        <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.sender.name }} {{ item_detail.sender.lastname }}</span>
                      </p>
                      <p style="margin-bottom: 0;padding-bottom: 0">
                        <span style="color: #757575;font-size: 10px;display: block">{{ translate('Адреса') }}</span>
                        <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.sender_location.address }}</span>
                      </p>
                      <p style="margin-bottom: 0;padding-bottom: 0">
                        <span style="color: #757575;font-size: 10px;display: block">{{ translate('Поштански бр') }}</span>
                        <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.sender_location.zip }} {{ item_detail.sender_location.city.name }}</span>
                      </p>
                      <h4 class="text-uppercase mt-5 mb-1">{{ translate('Адреса примаоца') }}</h4>
                      <p style="margin-bottom: 0;padding-bottom: 0">
                        <span style="color: #757575;font-size: 10px;display: block">{{ translate('Име и презиме') }}</span>
                        <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.receiver.name }}, {{ item_detail.receiver.lastname }}</span>
                      </p>
                      <p style="margin-bottom: 0;padding-bottom: 0">
                        <span style="color: #757575;font-size: 10px;display: block">{{ translate('Адреса') }}</span>
                        <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.receiver_location.address }}</span>
                      </p>
                      <p style="margin-bottom: 0;padding-bottom: 0">
                        <span style="color: #757575;font-size: 10px;display: block">{{ translate('Поштански бр') }}</span>
                        <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.receiver_location.zip }}, {{ item_detail.receiver_location.city.name }}</span>
                      </p>
                    </v-col>
                    <v-col cols="4">
                      <h4 class="text-uppercase">{{ translate('Врста услуге') }}</h4>
                      <h5>{{ translate('Детаљи') }}</h5>
                      <p>
                        <span style="color: #757575;font-size: 10px;display: block">{{ translate('Опис') }}</span>
                        <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.description }}</span>
                      </p>
                      <p>
                        <span style="color: #757575;font-size: 10px;display: block">{{ translate('Доставна служба') }}</span>
                        <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.post.name }}</span>
                      </p>
                      <p style="display: flex;justify-content: space-between">
                        <span style="width: 31%">
                          <span style="color: #757575;font-size: 10px;display: block">{{ translate('Маса') }} </span>
                          <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.mass }} kg</span>
                        </span>
                        <span style="width: 31%">
                          <span style="color: #757575;font-size: 10px;display: block">{{ translate('Отварање пошиљке') }} </span>
                          <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.open == 1 ? translate('Да') : translate('Не') }}</span>
                        </span>
                        <span style="width: 31%">
                          <span style="color: #757575;font-size: 10px;display: block">{{ translate('Осигурање') }} </span>
                          <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.insurance == 1 ? translate('Да') : translate('Не') }}</span>
                        </span>
                      </p>
                      <p style="display: flex;justify-content: space-between">
                        <span style="width: 31%">
                          <span style="color: #757575;font-size: 10px;display: block">{{ translate('Ширина') }} </span>
                          <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.width }} cm</span>
                        </span>
                        <span style="width: 31%">
                          <span style="color: #757575;font-size: 10px;display: block">{{ translate('Дужина') }} </span>
                          <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.height }} cm</span>
                        </span>
                        <span style="width: 31%">
                          <span style="color: #757575;font-size: 10px;display: block">{{ translate('Висина') }} </span>
                          <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.length }} cm</span>
                        </span>
                      </p>
                      <h5 class="mb-2">{{ translate('Посебне услуге') }}</h5>
                      <p style="margin-bottom: 1px;padding-bottom: 1px">
                        <label for="naplata">
                          <input id="naplata" type="checkbox"> {{ translate('Наплата поузећем') }}
                        </label>
                      </p>
                      <p style="margin-bottom: 1px;padding-bottom: 1px">
                        <label for="vremenska">
                          <input id="vremenska" v-model="time_delivery" type="checkbox"> {{ translate('Временски одређена достава') }}
                        </label>
                      </p>
                      <p v-if="time_delivery" style="display: flex;justify-content: space-between">
                        <span>{{ translate('Од') }}: 12:00</span>
                        <span>{{ translate('До') }}: 13:00</span>
                      </p>
                      <p style="margin-bottom: 1px;padding-bottom: 1px">
                        <label for="ubrzano">
                         <input id="ubrzano" type="checkbox"> {{ translate('Убрзана достава (до 12)') }}
                        </label>
                      </p>
                      <hr class="my-2">
                      <p style="display: flex;justify-content: space-between">
                        <span>{{ translate('Вриједност') }}</span>
                        <span style="padding: 1px 55px;border: 1px solid #cfcfcf" class="font-weight-black">{{ parseFloat(item_detail.price) }}</span>
                      </p>
                      <p style="display: flex;justify-content: space-between">
                        <span>{{ translate('Износ откупнине') }}</span>
                        <span style="padding: 1px 55px;border: 1px solid #cfcfcf" class="font-weight-black">{{ parseFloat(item_detail.price) }}</span>
                      </p>
                    </v-col>
                    <v-col cols="4">
                      <h4 class="text-uppercase">{{ translate('Подаци о преузимању') }}</h4>
                      <p>
                        <span style="width: 49%">
                          <span style="color: #757575;font-size: 10px;display: block">{{ translate('Тип пошиљке') }} </span>
                          <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.send_type == 1 ? translate('Локално') : translate('Међуградски') }}</span>
                        </span>
                      </p>
                      <p>
                        <span style="width: 49%">
                          <span style="color: #757575;font-size: 10px;display: block">{{ translate('Врста пошиљке') }} </span>
                          <span style="border-bottom: 1px solid #cfcfcf;display: block">{{ item_detail.order_type == 1 ? translate('Пакет') : translate('Докуменат') }}</span>
                        </span>
                      </p>
                      <h5>{{ translate('Поштарина') }}</h5>
                      <div style="display: flex;justify-content: space-between">
                        <div>
                          <h5 class="mb-2">{{ translate('Поштарину плаћа') }}</h5>
                          <p style="margin-bottom: 1px;padding-bottom: 1px">
                            <label for="pos">
                              <input id="pos" type="checkbox"> {{ translate('Пошиљалац') }}
                            </label>
                          </p>
                          <p style="margin-bottom: 1px;padding-bottom: 1px">
                            <label for="prim">
                              <input id="prim" type="checkbox"> {{ translate('Прималац') }}
                            </label>
                          </p>
                          <p style="margin-bottom: 1px;padding-bottom: 1px">
                            <label for="tre">
                              <input id="tre" type="checkbox"> {{ translate('Треће лице') }}
                            </label>
                          </p>
                        </div>
                        <div>
                          <h5 class="mb-2">{{ translate('Начин плаћања') }}</h5>
                          <p style="margin-bottom: 1px;padding-bottom: 1px">
                            <label for="got">
                              <input id="got" type="checkbox"> {{ translate('Готовински') }}
                            </label>
                          </p>
                          <p style="margin-bottom: 1px;padding-bottom: 1px">
                            <label for="fak">
                              <input id="fak" type="checkbox"> {{ translate('По фактури') }}
                            </label>
                          </p>
                          <p style="margin-bottom: 1px;padding-bottom: 1px">
                            <label for="bex">
                              <input id="bex" type="checkbox"> {{ translate('Без готовински') }}
                            </label>
                          </p>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<style scoped>

</style>