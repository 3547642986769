<template>
<v-container>
  <v-data-table
    :headers="headers"
    :items="desserts"
    :search="search"
    sort-by="calories"
    class="elevation-1"
    dense
    show-expand
  >
    <template
      v-for="header in headers"
      v-slot:[`header.${header.value}`]="{ header }"
    >
      {{ $t(header.text) }}
    </template>
    <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
      <template v-if="item.children && item.children.length">
        <v-btn
          color="primary"
          dark
          small
          rounded
          @click="expand(true)"
          v-if="!isExpanded"
        >
          {{ $t("show") }}
        </v-btn>
        <v-btn color="primary" dark small rounded @click="expand(false)" v-else>
          {{ $t("hide") }}
        </v-btn>
      </template>
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("menu_list") }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
         <v-text-field
          class="pr-4 pb-2"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
          dense
        ></v-text-field>
        <v-dialog v-model="dialog" max-width="500px" scrollable>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon>mdi-plus</v-icon>
              {{$t("add_menu_type")}}
            </v-btn>
            
          </template>
          <v-card>
            <v-card-title class="primary">
              <span class="headline white--text"
                >{{translate(formTitle) }} </span
              >
              <span class="headline white--text" v-if="editedItem.id">
                | ID: {{ editedItem.id }}
              </span>
            </v-card-title>

            <v-card-text>
              <v-container style="padding-bottom: 5px">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.name"
                      :rules="[$t('mandatory_field')]"
                      :label="'* ' + $t('title')"
                    ></v-text-field>
                  </v-col>
<!--                  <v-col cols="12">-->
<!--                    <v-text-field-->
<!--                      v-model="editedItem.code"-->
<!--                      :label="$t('code')"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->
                  <v-col cols="12">
                    <v-text-field
                      type="number"
                      v-model="editedItem.poredak"
                      :label="$t('order_m')"
                    ></v-text-field>
                      <p style="margin-top: -20px;font-size: 10px;">{{ $t('hi_num')}}</p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn style="border-radius: 12px" color="primary px-5" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn style="border-radius: 12px" color="primary px-5" dark @click="save">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">{{
              $t("delete_confirmation")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">{{
                  $t("cancel")
              }}</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
                   $t("save")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:expanded-item="{ item, isExpanded, expand }">
      <template v-if="item.children">
        <td :colspan="headers.length" class="px-0">
          <v-data-table
            dense
            :headers="headers"
            class="elevation-1 grey lighten-4"
            :items="item.children || []"
            hide-default-footer
            hide-default-header
          >
            <v-spacer></v-spacer>
            <template v-slot:item.actions="{ item, index }">
              <v-icon v-if="item.restaurant_id == $store.state.restaurant.id" small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon v-if="item.restaurant_id == $store.state.restaurant.id" small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </td>
      </template>
    </template>
    <template v-slot:item.image="{ item }">
       <span style="position: relative" class="row">
          <input style="cursor: pointer;position: relative;z-index: 999" type="file" id="upload" @change="upload(item)" />
          <span style="position: absolute;left: 5px;z-index: 0;top: -9px" v-if="! item.image"><v-icon style="font-size: 51px" small>mdi-image</v-icon></span>
          <label style="position: absolute;top: -16px" v-else class="ma-3" for="upload">
            <v-img height="40" width="40" :src="conf.apiUrlBase +'/gallery/' + $store.state.restaurant.id +'/medium/' + item.image.name"></v-img>
            <a style="position: absolute;top: -8px;right: -7px;background: #c50b0b;color: #fff;font-size: 10px;padding: 1px 5px;border-radius: 15px;text-decoration: none;" @click.prevent="removeImage(item)" href="" title="Ukloni sliku">X</a>
         </label>
       </span>
    </template>
    <template v-slot:item.name="{ item }">
      {{ $t(item.name) }} ({{ item.name }})
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon v-if="item.restaurant_id == $store.state.restaurant.id" small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small v-if="item.restaurant_id == $store.state.restaurant.id" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" @click="initialize">
        {{ $t("resets") }}
      </v-btn>
    </template>
  </v-data-table>
  </v-container>
</template>

<script>
import axios from "../plugins/axios";
import conf from "../config";
import moment from "moment/moment";

export default {
  data: () => ({
    conf: conf,
    dialog: false,
    search: "",
    dialogDelete: false,
    headers: [
      { text: "id", align: "start", value: "id", },
      { text: "title", align: "start", value: "name", },
      { text: "actions",  value: "actions", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    desserts: [],
    editedIndex: -1,
    editedParentIndex: -1,
    editedItem: {
      name: "",
    },
    defaultItem: {
      name: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('new_menu_type') : this.$t('edit_menu_type');
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    // axios.post('/reports/user-active-orders', {'user_id': + 6725}).then(({data}) => {
    //   console.log(data)
    // })
    // axios.post('/reports/all-user-active-orders', {'user_id': + 6725}).then(({data}) => {
    //   console.log(data)
    // })
    // axios.post('/user/' + 11879 + '/change-receiving-orders', {'receiving_orders': false}).then(({data}) => {
    //   console.log(data)
    // })
    // axios.get('/orders/guest-orders/' + 1685 + '/show').then(({data}) => {
    //   console.log(data)
    // })
    // axios.post('/companies/user-companies', {'user_id' : 13006}).then(({data}) => {
    //   console.log(data)
    // })
    // axios.get('/categories-guest').then(({data}) => {
    //   console.log(data)
    // })
    // axios.get('/menus/mob-app?from=2023-12-07&to=2023-12-07&type=rucak').then(({data}) => {
    //  console.log(data)
    // })
    // let podaci = {}
    // podaci["location_sender_id"] = {
    //   "coordinates": "40.111, 41.122",
    //   "address": "Test",
    //   "post_user_id": 1,
    //   "city_id": 1,
    //   "delivery_distance": "30",
    //   "apartment": 1,
    //   "floor": 1,
    //   "firm": "Test firma",
    //   "zip": "123"
    // };
    // podaci["location_receiver_id"] = {
    //   "coordinates": "40.111, 41.122",
    //   "address": "Test",
    //   "post_user_id": 1,
    //   "city_id": 1,
    //   "delivery_distance": "30",
    //   "apartment": 1,
    //   "floor": 1,
    //   "firm": "Test firma",
    //   "zip": "123"
    // };
    // podaci["post_id"] = 1;
    // podaci["sender"] =  {
    //   "name": "Test",
    //   "lastname": "Test",
    //   "email": "test@test.com",
    //   "phone": "111 111-111",
    // };
    // podaci["receiver"] = {
    //   "name": "Test",
    //   "lastname": "Test",
    //   "email": "tes1t@test1.com",
    //   "phone": "111 111-111",
    // };
    // podaci["send_type"] = 1;
    // podaci["order_type"] = 1;
    // podaci["send_date"] = '2024-05-29';
    // podaci["payment"] = 1;
    // podaci["description"] = "test";
    // podaci["width"] = 10;
    // podaci["height"] = 20;
    // podaci["length"] = 30;
    // podaci["mass"] = 10;
    // podaci["price"] = 10;
    // podaci["insurance"] = 1;
    // podaci["open"] = '';
    // podaci["send_type_id"] = 1;
    // // podaci["additional_service"] = null
    // podaci["additional_service"] = {
    //   "charge_on_delivery": false,
    //   "charge_on_delivery_price": 100,
    //   "dispatch_note": false,
    //   "time_specified_delivery": false,
    //   "time_specified_delivery_from": "10:00",
    //   "time_specified_delivery_to": "13:00",
    //   "urgent_delivery": false
    // }
    //
    // axios.post('/post/store-order-post', podaci).then(({data}) => {
    //  console.log(data)
    // })

    // axios.get('/v2/reports/daily/company/104/no-contract?date=2023-12-01&date2=2023-12-01&status=on-hold').then(({data}) => {
    //  console.log(data)
    // })
    // let user = {}
    // user['user'] = {}
    // user['user']['id'] = 6725
    // user['res_id'] = 60
    // axios.post('/orders/guest-old-orders', user).then(({data}) => {
    //   console.log(data)
    // })
    axios.get('/menus/mob-app').then(({data}) => {
      console.log(data)
    })
    axios.get('/categories-guest').then(({data}) => {
      console.log(data)
    })
  },

  methods: {
    removeImage(item) {
      // axios.delete("/categories/category-image-delete/" + item.id).then((res) => {
      //   if (res.data) {
      //     this.desserts.filter(category => {
      //       if (category.id == item.id) {
      //         category.image_id = null
      //         category.image = null
      //       }
      //     })
      //   }
      // });
    },
    upload(item) {
      let data = new FormData();
      data.append("image", event.target.files[0]);
      data.append("category_id", item.id);
      axios.post("/gallery", data).then((res) => {
        if (res.data) {
          this.desserts.filter(category => {
            if (category.id == item.id) {
              category.image_id = res.data.id
              category.image = res.data
            }
          })
        }
      });
    },
    setPrilog(prilog, index) {
      axios.post('/categories/set-prilog', prilog).then(({data}) => {
        console.log(data)
      })
    },
    initialize() {
      axios.get("menus/menu-types").then((res) => {
        this.desserts = res.data;
      });
    },

    editItem(item) {
      if (item.parent_id) {
        this.editedIndex = this.desserts.findIndex(
          (i) => i.id === item.parent_id
        );
        this.editedParentIndex = this.desserts[
          this.editedIndex
        ].children.findIndex((i) => (i.id = item.id));
      } else {
        this.editedIndex = this.desserts.indexOf(item);
      }
      if(item.name) {
        item.name = this.translate(item.name)
      }
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      if (item.parent_id) {
        this.editedIndex = this.desserts.findIndex(
          (i) => i.id === item.parent_id
        );
        this.editedParentIndex = this.desserts[
          this.editedIndex
        ].children.findIndex((i) => (i.id = item.id));
      } else {
        this.editedIndex = this.desserts.indexOf(item);
      }
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // axios.delete("/categories/" + this.editedItem.id).then((res) => {
      //   if (this.editedParentIndex > -1) {
      //     this.desserts[this.editedIndex].children.splice(
      //       this.editedParentIndex,
      //       1
      //     );
      //   } else {
      //     this.desserts.splice(this.editedIndex, 1);
      //   }
      //   this.closeDelete();
      // });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedParentIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedParentIndex = -1;
      });
    },

    save() {
      this.editedItem.parent_id = this.editedItem.parent
        ? this.editedItem.parent.id
        : 0;
      if (this.editedIndex > -1 || this.editedParentIndex > -1) {
        let _editedIndex = this.editedIndex;
        let _editedParentIndex = this.editedParentIndex;
        let _editedItem = this.editedItem;

        axios
          .put("/menus/menu-types/" + this.editedItem.id, this.editedItem)
          .then((res) => {
            if (_editedParentIndex > -1) {
              Object.assign(
                this.desserts[_editedIndex].children[_editedParentIndex],
                  _editedItem
              );
            } else {
              _editedItem.name = this.translate(_editedItem.name)
              Object.assign(this.desserts[_editedIndex], _editedItem);
            }
          });
      } else {
        let _editedItem = this.editedItem;
        axios.post("/menus/menu-types", this.editedItem).then((res) => {
          _editedItem.id = res.data.id;
          if (_editedItem.parent_id) {
            let i = this.desserts.findIndex(
              (i) => i.id === _editedItem.parent_id
            );
            this.desserts[i].children.push(res.data);
          } else {
            this.desserts.push(res.data);
          }
          this.$forceUpdate()
        });
      }
      this.close();
    },
  },
};
</script>
<style scoped>

input[type='file'] {
  display: inline-block;
  width: 40px;
  padding: 30px 0 0 0;
  height: 30px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

}
</style>