export default {
    computed: {
        rules() {
            return {
                company: value => !value || value.length >= 2 || 'At least 2 characters',
                email: value => {
                    const pattern = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
                    return !value || pattern.test(value) || 'Invalid email.'
                },
                firstName: (value) => {
                    const pattern = /[A-Z]{1}[a-z]+/;
                    return !value || pattern.test(value) || 'Invalid first name.'
                },
                fullName: (value) => {
                    const pattern = /[a-z]+/;
                    return !value || pattern.test(value) || 'Invalid full name.'
                },
                /*fullName: (value) => {
                    const pattern = /[A-Z]{1}[a-z]+\s[A-Za-z]+(\s?)([A-Za-z]?)/;
                    return !value || pattern.test(value) || 'Invalid full name.'
                },*/
                hyperlink: value => {
                    const pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
                    const mailto = value.startsWith('mailto:')
                    return !value || mailto || pattern.test(value) || 'Invalid hyperlink.'
                },
                onlyString: value => !value || /^[a-zA-Z\s+]*$/.test(value) || 'Only characters allowed',
                order: value => value > 0 || 'Ordering must be higher then 0',
                password: value => value.length >= 6 || 'Sifra mora da sadrzi najmanje 6 karaktera',
                /*password: value => {
                    let pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/;
                    return !value || pattern.test(value) || 'Password must be at least 6 characters with one uppercase, lowercase and one digit';
                },*/
                phone: value => {
                    let pattern = /^[-+]?\d+$/;
                    return !value || pattern.test(value) || 'Invalid phone number';
                },
               /* phone: value => {
                    let pattern = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
                    return !value || pattern.test(value) || 'Invalid phone number';
                },*/
                number: value => {
                    let pattern = /^\d+$/;
                    return !value || pattern.test(value) || 'Invalid number';
                },
                float: value => {
                    let pattern = /^[+-]?\d+(\.\d+)?$/;
                    return !value || pattern.test(value) || 'Invalid values';
                },
                repeatPassword: value => {
                    return !value || value === this.password || 'Sifre nisu iste'
                },
                repeatPassword2: value => {
                    return !value || value === this.password || 'Passwords do not match'
                },
                required: value => {
                    return !!value || value === 0 || 'Field is required'
                },
                /*required: value => {
                    return !value || value !== 0 || 'Passwords do not match'
                },*/
                twitter: value => value.match(/(^|[^'"])(https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+))/) || 'Invalid twitter url.',
                vimeo: value => value.indexOf('https://vimeo.com/') > -1 || 'Invalid vimeo link.',
                youtube: value => value.indexOf('youtube.com') > -1 && value.indexOf('v=') > -1 || 'Invalid youtube link.'
            }
        }
    }
}
