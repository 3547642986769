<template>
  <v-card
    style="overflow-wrap: unset; !important;box-shadow: none;border:1px solid #e1e1e1;border-radius: 12px !important; position:relative;"
    :title="!(params.item.disabled && params.item.test != params.meal.id + `_${params.shift.num}-smena`) ? '' : $t('change_order_deadline_expired')"
    class="my-2 food-order-card"
  >
    <div
      v-if="$store.state.user.type !== 'company' && disabledWorker"
      class="food-order-card-disabled">
    </div>
    <div
      v-else-if="params.item.disabled || (params.dayTo > params.item.date && !params.checkTime)"
      class="food-order-card-disabled">
    </div>
    <v-card-text :class="isMobile ? 'pa-0' : 'pa-0'">
      <v-row no-gutters>
        <!-- IMAGE -->
        <v-col cols="auto" class="pa-2" align="center" v-if="params.meal.name !== 'Укупно'">
          <div v-if="$store.state.user.type === 'company' || $store.state.user.companies[0].is_credit == 1" @click="preview" class="fill-height">
            <v-img alt="obrok" cover :width="isMobile ? '100%' : '90px'" :height="isMobile ? '90px' : '100%'" :style="isMobile ? 'border-radius: 12px 12px 0px 0px' : 'border-radius: 6px'" v-if="params.meal.image"
                   :src=" conf.apiUrlBase + '/gallery/' + (params.meal.clone_id ? params.meal.image.restaurant_id : params.restaurant.id) +'/small/' + params.meal.image.name"/>
            <v-img alt="obrok" cover :width="isMobile ? '100%' : '90px'" :height="isMobile ? '90px' : '100%'" :style="isMobile ? 'border-radius: 12px 12px 0px 0px' : 'border-radius: 6px'" v-else
                   src="https://cdn.vuetifyjs.com/images/cards/cooking.png"/>
          </div>
          <div v-else @click="preview" class="fill-height">
            <v-img alt="obrok" cover :width="isMobile ? '100%' : '90px'" :height="isMobile ? '90px' : '100%'" v-if="params.meal.image"
                   :src=" conf.apiUrlBase + '/gallery/' + (params.meal.clone_id ? params.meal.image.restaurant_id : params.restaurant.id) +'/small/' + params.meal.image.name"
                   :style="disabledWorker && 'opacity: 0.3', isMobile ? 'border-radius: 12px 12px 0px 0px' : 'border-radius: 6px'"/>
            <v-img alt="obrok" cover :width="isMobile ? '100%' : '90px'" :height="isMobile ? '90px' : '100%'" v-else
                   src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
                   :style="disabledWorker && 'opacity: 0.3', isMobile ? 'border-radius: 12px 12px 0px 0px' : 'border-radius: 6px'"/>
          </div>
        </v-col>
        <!-- END IMAGE -->
        <v-col class="fill-height">
          <!-- WORKER-->
          <v-row :class="!isMobile && 'pl-2'" no-gutters v-if="$store.state.user.companies[0].is_credit != 1 && $store.state.user.type === 'customer'">
            <v-col cols="12"
             @click="params.dialog = true;params.selectedItem = params.item;params.selectedMeal = params.meal;params.selShift2=`_${params.shift.num}-smena`;params.selShift=`${params.shift.num}-smena`;params.selectedShift=$t(`shift_${params.shift.text}`);params.selectedDateImage = params.item.name.split(' ')[1] + '.'"
             v-model="params.item.test"
             @change="$parent.reserve($event, params.item, false, params.meal.id + `_${params.shift.num}-smena`)"
             hide-details
             :disabled="params.item.disabled || !!params.item.test"
            >
              <v-row
                no-gutters
                class="text-sm-body-2 text-md-body-1"
                :style="disabledWorker && 'color: #0000003b', isMobile ? 'height: 120px; text-align: center' : 'height: 38px; text-align: left'"
                :class="isMobile ? 'px-1 pt-2' : 'pt-2'"
              >
                <v-col :style="!isMobile && 'overflow: hidden;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;'">{{ params.meal.name }}</v-col>
                <v-col cols="auto" v-if="showPrice && params.meal.price" class="text-body-2 font-weight-medium pr-1">
                  <span style="color: #fb6d3b">{{params.meal.price}}</span> KM
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              :style="isMobile ? 'height: 40px; text-align: center' : 'height: 35px; text-align: right'"
            >
              <v-btn
                v-if="!(params.item.disabled && params.item.test != params.meal.id + `_${params.shift.num}-smena`)"
                @click="
                  params.item.test === params.meal.id + `_${params.shift.num}-smena` &&
                  $store.state.user.type === 'customer'
                  ? $parent.revert(params.item, params.meal.id)
                  : $parent.reserve($event, params.item, true, params.meal.id + `_${params.shift.num}-smena`)
                "
                :class="!isMobile && 'mr-2 mb-2'"
                style="text-transform: capitalize; border:1px solid #FB6D3B!important;box-shadow: none !important;border-radius: 12px;"
                :style="params.item.test === params.meal.id + `_${params.shift.num}-smena` && $store.state.user.type === 'customer' ? 'background: #FB6D3B !important;color: #fff' : 'color: #FB6D3B'"
                :height="isMobile ? 35 : 30"
                color="white"
              >
                {{
                  $t(
                    !params.restaurant.settings.rok_dana && params.meal.orders.find((order) => order.shift === `${params.shift.num}-smena`).quantity_payed > 0 && params.meal.orders.find((order) => order.shift === `${params.shift.num}-smena`).date == params.item.date && params.meal.id == params.meal.orders.find((order) => order.shift === `${params.shift.num}-smena`).meal_id ? $t('ordered_meal') :
                      params.meal.orders.find((order) => order.shift === `${params.shift.num}-smena`).date == params.item.date && (params.dayTo > params.item.date && !params.checkTime) ? $t('ordered_meal') :
                        params.item.test === params.meal.id + `_${params.shift.num}-smena` &&
                        $store.state.user.type === "customer"
                          ? "cancel"
                          : "order"
                  )
                }}
              </v-btn>
            </v-col>
          </v-row>
          <!-- COMPANY-->
          <div v-else>
            <v-row no-gutters>
              <v-col
                :cols="params.meal.name == 'Укупно' ? '9' : '12'"
                :align="$store.state.user.type === 'customer' ? 'center' : 'left'"
                :class="!isMobile && 'pl-2'"
              >
                <span
                  :style="params.meal.name == 'Укупно' ? 'margin-left:10px' : '', disabledWorker && 'color: #0000003b', isMobile ? 'height: 120px; text-align: center' : 'height: 50px; text-align: left; overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;'"
                  style="color:#2C2C2C;line-height: 20px;display: block;font-size: 16px;font-weight: 700;text-align: left;font-family: 'Poppins', Helvetica, sans-serif !important;"
                  :class="isMobile ? 'px-1 pt-2' : 'pt-2'"
                >
                  {{params.meal.name }}
                </span>
              </v-col>
              <v-col
                :cols="params.meal.name == 'Укупно' ? '3' : '12'"
                style="align-items: center;display: flex;justify-content: end"
                :style="isMobile ? 'height: 80px; text-align: center' : 'height: 46px; text-align: right'"
                :class="!isMobile && 'pl-2'"
              >
                <v-row no-gutters align="center" justify="center" class="fill-height">
                  <v-col cols="12" md="5" class="order-1 order-sm-0">
                    <v-text-field
                      readonly
                      style="border: none; max-width: 160px" class="ukinuti_liniju"
                      :disabled="params.item.disabled || (params.dayTo > params.item.date && !params.checkTime)"
                      v-model="params.meal.orders.find((i) => i.shift === `${params.shift.num}-smena`).quantity"
                      type="number"
                      v-if="params.meal.name !== 'Укупно'"
                      prepend-icon="mdi-minus"
                      min="0"
                      hide-details
                      dense
                      append-icon="mdi-plus"
                      @change="$store.state.user.companies[0].is_credit == 1
                      ? $parent.reserveMoreCredit($event, params.item, params.meal, `${params.shift.num}-smena`, params.delivery_from[params.index], params.delivery_to[params.index])
                      : $parent.reserveMore($event, params.item, params.meal, `${params.shift.num}-smena`, params.delivery_from[params.index], params.delivery_to[params.index])"

                      @click:prepend="$parent.decrement(params.meal, `${params.shift.num}-smena`, params)"
                      @click:append="$parent.increment(params.meal, `${params.shift.num}-smena`, params)"
                    ></v-text-field>
                    <span v-else>
                    {{
                        params.item['shift_' + params.shift.text].reduce((sum, one) => {
                          if (one.orders && one.orders.length) {
                            return (sum + one.orders.reduce((total, order) => {
                              if (order.shift === `${params.shift.num}-smena`) {
                                return (total + parseInt(order.quantity));
                              } else {
                                return total;
                              }
                            }, 0));
                          } else {
                            return sum;
                          }
                        }, 0)
                      }}
                </span>
                  </v-col>
                  <v-col cols="12" md="7" align="end" class="order-0 order-sm-1" style="height: 36px">
                    <div v-if="params.meal.name !== 'Укупно'">
                      <v-btn
                        v-show="params.meal.orders.find((i) => i.shift === `${params.shift.num}-smena`).quantity > 0"
                        v-if="!(params.item.disabled && params.item.test != params.meal.id + `_${params.shift.num}-smena`)"
                        :disabled="!params.restaurant.settings.rok_dana && params.meal.orders.find((order) => order.shift === `${params.shift.num}-smena`).quantity_payed > 0 || (params.dayTo > params.item.date && !params.checkTime)"
                        class="primary white--text"
                        :style="params.meal.orders.find((i) => i.shift === `${params.shift.num}-smena`).quantity > 0 && params.meal.id == params.meal.orders.find((i) => i.shift === `${params.shift.num}-smena`).meal_id && ($store.state.user.type === 'company' || $store.state.user.companies[0].is_credit == 1) ? 'background: #FB6D3B !important;color: #fff' : ''"
                        style="margin-right: 5px;text-transform:capitalize;color: #FB6D3B !important; background: none !important;border:2px solid #FB6D3B !important;box-shadow: none !important;font-weight:500;font-family: 'Poppins', Helvetica, sans-serif !important;border-radius: 12px;height: 100%;font-size: 13px; height: 36px;"
                        @click="
                        $store.state.user.companies[0].is_credit == 1
                          ? params.item.test === params.meal.id + `_${params.shift.num}-smena`
                            ? $parent.reserveMoreCredit(0, params.item, params.meal, `${params.shift.num}-smena`, params.delivery_from[params.index], params.delivery_to[params.index])
                            : $parent.reserveMoreCredit(params.meal.orders.find((i) => i.shift === `${params.shift.num}-smena`).quantity, params.item, params.meal, `${params.shift.num}-smena`, params.delivery_from[params.index], params.delivery_to[params.index])
                          : params.meal.isOrder
                            ? $parent.reserveMore(0, params.item, params.meal, `${params.shift.num}-smena`, params.delivery_from[params.index], params.delivery_to[params.index])
                            : $parent.reserveMore(params.meal.orders.find((i) => i.shift === `${params.shift.num}-smena`).quantity, params.item, params.meal, `${params.shift.num}-smena`, params.delivery_from[params.index], params.delivery_to[params.index])"
                        :class="params.item.disabled && params.item.test != params.meal.id + `_${params.shift.num}-smena` ? ' d-none' : params.item.test === params.meal.id + `_${params.shift.num}-smena` ? 'otkazi' : 'naruci'"
                      >
                        {{params.meal.orders.find((i) => i.shift === `${params.shift.num}-smena`).quantity > 0 && params.meal.id == params.meal.orders.find((i) => i.shift === `${params.shift.num}-smena`).meal_id ? $t('cancel') : $t('order') }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import conf from "../../config";

export default {
  props: {
    params: {
      type: Object
    }
  },
  data() {
    return {
      conf: conf,
      isMobile: false
    }
  },
  methods: {
    preview() {
      this.params.dialog = true;
      this.params.selectedItem = this.params.item;
      this.params.selectedMeal = this.params.meal;
      this.params.selShift2=`_${this.params.shift.num}-smena`;
      this.params.selShift=`${this.params.shift.num}-smena`;
      this.params.selectedShift=this.$t(`shift_${this.params.shift.text}`);
      this.params.selectedDateImage = this.params.item.name.split(' ')[1] + '.'
      this.$emit('update', this.params)
    }
  },
  computed: {
    disabledWorker() {
      return this.params.item.test !== this.params.meal.id + `_${this.params.shift.num}-smena`
        && !!this.params.item.test || !this.params.restaurant.settings.rok_dana
        && this.params.meal.orders.find((order) => order.shift === `${this.params.shift.num}-smena`).quantity_payed > 0
        || (this.params.dayTo > this.params.item.date && !this.params.checkTime)
    },
    company() {
      return this.$store.state.company;
    },
    showPrice() {
      return JSON.parse(this.company.settings) ? JSON.parse(this.company.settings).show_price : 0
    },
  },
  mounted() {
    this.isMobile = window.innerWidth < 960
  }
}
</script>

<style>

.food-order-card-disabled {
  position:absolute;
  background: #ffffff;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  z-index: 2;
  border-radius: 12px;
  opacity: 0.5;
}

.food-order-card .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: white!important;
}

.food-order-card .theme--light.v-btn.v-btn--disabled {
  color: #FB6D3B!important;
}

</style>
