<template>
  <div style="margin: 0; padding: 0;">
    <div class="hero" :style="`background: url('https://app.restorani.bitlab.host/gallery/${restaurant.id}/medium/${restaurant.settings.header}'); background-size: cover;width:100vw background-position: center;`">
      <img
      class="hero-logo"
      :src="
        conf.apiUrlBase +
        '/gallery/' +
        restaurant.id +
        '/' +
        restaurant.settings.logo
      "
    />
    </div>
  <v-container style="max-width: 1170px;" >
    <v-row>
      <h2 class="mb-5 mt-4">{{translate( $store.state.user.name) }} {{ translate($store.state.user.lastname) }}: {{ translate("Промјена локације") }}</h2>
    </v-row>
    <v-row justify="center  ">
      <v-col lg="4" md="4" sm="12" xs="12" cols="12">
        <span class="izaberi-datum">{{ $t("choose_date") }}</span>
        <v-date-picker v-model="date" :min="new Date().toISOString().substr(0, 10)" no-title scrollable @input="reqOrder()">
        </v-date-picker>
      </v-col>

      <v-col lg="4" md="4" sm="12" xs="12" cols="12">
        <div class="lista-narucenih-stvari" v-if="this.orders.length">
          <span>{{ $t("food_ordered") }}</span>
          
          <ul>
            <li v-for="order in orders" @click="openDialog(order)">
              <v-icon class="ikonica">mdi-food-fork-drink</v-icon> {{ translate(order.meals.name) }} ({{ order.quantity }})
            </li>
          </ul>
        </div>

        <div v-else>
          <v-btn text small @click="$router.push({ name :'home'})"> {{
            translate(
              "Нисте обавили наруџбе за изабрани датум"
            )
          }}</v-btn>
          <v-btn class="mt-2" color="#C20606" dark small @click="$router.push({ name :'home'})">
            {{$t('do_order')}}
          </v-btn>
        </div>
      </v-col>

      <v-col lg="4" md="4" sm="12" xs="12" cols="12" v-if="this.orders.length">
              <span class="promjena-lokacije">
                {{ $t("locations_change") }}
              </span>
            

            <v-card-text>
              <v-select
                v-model="location"
                :items="companies"
                :label="$t('office_for_delivery')"
                @change="selectCompany"
                item-text="1"
                item-value="0"
                return-object
              >
                <template v-slot:prepend>
                  <v-icon color="#757575">mdi-store</v-icon>
                </template>
              </v-select>
            </v-card-text>

            <v-card-text>
              <v-select
                v-model="shift"
                :items="shifts"
                :label="translate('Смјена у којој радите')"
                item-text="text"
                item-id="id"
                return-object
                
              > 
                <template v-slot:prepend>
                  <v-icon color="#757575">mdi-clock-time-four-outline</v-icon>
                </template>
              </v-select>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="change()">
                {{ translate("Промијени") }}
              </v-btn>
            </v-card-actions>
          <!-- </v-card> -->
        </v-col>

        <v-col v-else lg="4" md="4" sm="12" xs="12" cols="12">

        </v-col>
        
        <v-snackbar v-model="snackbar.status">
          {{ translate(snackbar.text) }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="snackbar.status = false"
            >
              Ok
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>


    
    </v-row>
    <v-dialog v-model="dialog" max-width="60%" scrollable>
      <v-card>
        <v-card-text>
          <v-card elevation="0">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon x-large>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mb-4">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-img
                    :src="
                      selectedMeal.image
                        ? conf.apiUrlBase +
                          '/gallery/' +
                          restaurant +
                          '/medium/' +
                          selectedMeal.image.name
                        : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" sm="6" class="ml-sm-n16 my-sm-2 mt-sm-16">
                  <v-row align="end" class="fill-height">
                    <v-card elevation="0" class="pa-5">
                      <v-card-title
                        ><h3>
                          {{ translate(selectedMeal.meals.name) }}
                          
                        </h3></v-card-title
                      >

                      <v-card-text class="mb-4">
                        <v-row no-gutters>
                          <template v-for="(eat, i) in selectedMeal.meals.foods">
                            <v-col cols="12" class="font-weight-bold pt-2">
                              <v-row no-gutters justify="space-between">
                                <span
                                  ><h3>{{ translate(eat.name) }}</h3></span
                                >
                              </v-row>
                            </v-col>
                            <v-col cols="12">{{
                              translate(eat.description)
                            }}</v-col>
                          </template>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
  </div>
</template>

<script>
import axios from "../plugins/axios";
import conf from "../config";


export default {
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    conf: conf,
    dialog:false,
    selectedMeal: {meals:{name:null,foods:{}}},
    shift: null,
    shifts:[],
    snackbar: {
      status: false,
      text: "",
    },
    cId: 0,
    orders: [],
    companies: [],
    location: null,
  }),

  computed: {
        restaurant() {
      return this.$store.state.restaurant
        ? this.$store.state.restaurant
        : this.$store.state.company.restaurant;
    },
  },

  watch: {},

  created() {
    this.initialize();
    this.reqOrder();
  },

  methods: {
    selectCompany(){
      this.shifts=[];
      axios.get("/companies/"+this.location[0]).then((res) => {
        if(res.data.shift_one){
          this.shifts.push({id:1,text:'Прва'})
        }
        if(res.data.shift_two){
          this.shifts.push({id:2,text:'Друга'})
        }
        if(res.data.shift_three){
          this.shifts.push({id:3,text:'Трећа'})
        }
        this.shift=this.shifts[0];
      });
    },
    initialize() {
      this.cId = this.$store.state.company.parent_id;
      if (!this.cId) this.cId = this.$store.state.company.id;
      axios.get("/companies/list/" + this.cId).then((res) => {
        this.companies = res.data;
      });
    },
    openDialog(item){
          this.dialog = true;
          this.selectedMeal = item
    },
    reqOrder() {
      this.orders = [];
      if (this.date) {
        axios
          .get("/orders/" + this.$store.state.user.id + "?date=" + this.date)
          .then((res) => {
            this.orders = res.data;
            if(this.orders.length){
              this.location=this.companies.find(o=>o[0]==this.orders[0].company_id);
              axios.get("/companies/"+this.location[0]).then((res) => {
                if(res.data.shift_one){
                  this.shifts.push({id:1,text:'Прва'})
                  if(this.orders[0].shift=='1-smena')this.shift={id:1,text:'Прва'};
                }
                if(res.data.shift_two){
                  this.shifts.push({id:2,text:'Друга'})
                  if(this.orders[0].shift=='2-smena')this.shift={id:2,text:'Друга'};
                }
                if(res.data.shift_three){
                  this.shifts.push({id:3,text:'Трећа'})
                  if(this.orders[0].shift=='3-smena')this.shift={id:3,text:'Трећа'};
                }
              });
            }
          });
      }
      
    },
    success(){
      this.snackbar.status = true;
            this.snackbar.text = "Успјешно сте промјенили локацију";
    },
    fail() {
      this.snackbar.status = true;
      this.snackbar.text = "Дошло је до грешке";
    },
    change() {
      if (
        this.location[0] &&
        this.shift.id
      ) {
        axios
          .post("/orders/location", {
            userId: this.$store.state.user.id,
            date: this.date,
            location: this.location[0],
            shift: this.shift.id,
            _method: "patch",
          })
          .then(() => {
            this.location=[];
            this.shift=[];
            this.success();
          })
          .catch(() => {
            this.fail();
          });
      } else {
        fail();
      }
    },
  },
};
</script>

<style scoped>
.lista-narucenih-stvari span, .izaberi-datum, .promjena-lokacije {
  font-size: 20px;
  line-height: 2rem;
  font-family: "Roboto", sans-serif !important;
  padding: 8px 15px;
  background: #e0e0e0;
  display: block;
  margin-bottom: 15px;
}

.lista-narucenih-stvari ul {
  margin-top: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;

}

.lista-narucenih-stvari ul li {
  font-size: 19px;
  color: #333;
  padding: 15px 10px;
  cursor: pointer;
}

.lista-narucenih-stvari ul li:hover {
      background: #ededed;
}

.lista-narucenih-stvari ul li:nth-child(even) {
      background: #f3f4f4;
}

.ikonica {
  margin-top: -5px;
}

</style>