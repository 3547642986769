<template>
  <v-row class="py-6 home-page">
    <template>
      <div style="padding:0 15px;position: absolute;top: -50px;background: rgb(255 255 255);width: 30%;height: 34px;z-index: 999;left: calc(50% - 25%);border-radius: 12px;">
        <input style="width: 100%;height: 100%;outline: none;" type="text" v-model="search" :placeholder="$t('search_restaurants')">
      </div>
      <div class="container">
        <v-row class="mb-5">
        <v-card style="width: 50% !important;box-shadow: none" class="px-2">
          <v-card-title class="my-0">
            <v-select class="ml-0"
              @change="getCitiesByCountry"
              :items="countries"
              item-value="id"
              item-text="name"
              v-model="country"
              :label="$t('country')">
            </v-select>
          </v-card-title>
        </v-card>
        <v-card style="width: 50% !important;box-shadow: none" class="px-2">
        <v-card-title>
            <v-select class="ml-4" style="width: 50% !important;"
              :items="filteredCitiesByCountry ? filteredCitiesByCountry : cities"
              item-value="id"
              item-text="name"
              v-model="city"
              :label="$t('city')">
            </v-select>
          </v-card-title>
        </v-card>
        </v-row>
        <h1 style="font-size: 20px">{{ $t('restaurants') }}</h1>
        <v-col cols="12">
          <v-row class="align-content-center justify-content-center">
            <div class="card text-center" style="width: 33%;padding: 10px 5px;" v-for="res in filteredItems">
              <div style="box-shadow: 0 1px 14px 2px #cfcfcf;border-radius: 12px;height: 230px;position: relative;">
                <img onerror="javascript:this.src='https://app.restorani.bitlab.host/gallery/no-image-restaurant.jpg'" style="cursor: pointer;border-top-left-radius:12px;border-top-right-radius:12px;" @click="selectRes(res)" class="card-img-top " width="100%" height="75%" :src="res.settings ?  conf.apiUrlBase + '/gallery/' + res.id + '/' + res.settings.logo : ''" alt="Logo">
                <div class="card-body">
                  <h5 class="card-title">{{ translate(res.name) }}</h5>
                  <p style="font-size: 11px;" class="card-text mb-0">
                    <span>{{ translate(res.address) }}</span>
                  </p>
                  <p style="font-size: 11px;" class="card-text mb-0 font-weight-bold">
                    <span>{{ translate(res.city ? res.city.name : '') }}</span>
                  </p>
                </div>
              </div>
            </div>
          </v-row>
        </v-col>
      </div>
    </template>
  </v-row>
</template>

<script>
import axios from "@/plugins/axios";
import conf from "../config";

export default {
  name: "RestoraniGuest",
 data() {
    return {
      conf: conf,
      search: '',
      allRestorants: '',
      prefixLang: '',
      country_code: '',
      countries: [],
      country: '',
      city: '',
      cities: [{name : 'Изаберите државу'}],
      filteredByCountry: '',
      filteredCitiesByCountry: ''
    }
 },
  created() {
    this.countryCity();
    this.getAllRestorans()
    this.$root.$on('prefix_lang',data => {
      console.log(data)
    })
  },
  computed: {
    filteredItems() {
      let res = ''
      if (this.filteredByCountry) {
        res = this.filteredByCountry
      } else {
        res = this.allRestorants
      }
      if (res) {
        res.filter(item1 => {
          item1.name = this.translate(item1.name)
        })
        return res.filter(item => {
          return item.name.toLowerCase().includes(this.translate(this.search.toLowerCase()))
        })
      }
    }
  },
  methods: {
    countryCity() {
      axios.get('/restaurants/countries-all').then(({data}) => {
        this.countries = data
        // if (this.$store.state.user.city_id) {
        //   this.cities = []
        //   data.filter(country => {
        //     country.city.filter(citi => {
        //       if (citi.id == this.$store.state.user.city_id) {
        //         this.country = country.id
        //         this.cities = country.city
        //       }
        //     })
        //   })
        // }
      })
    },
    getCitiesByCountry() {
      let restorans = []
      let gradovi = []
      this.cities = [{name : 'Изаберите државу'}]
      this.countries.filter(country => {
        if (country.id == this.country) {
          this.cities = country.city
        }
      })
      this.cities.filter(city => {
        this.allRestorants.filter(restoran => {
          if (city.id == restoran.city_id) {
            restorans.push(restoran)
            gradovi.push(city)
          }
        })
      })
     this.filteredCitiesByCountry = gradovi
     this.filteredByCountry = restorans
    },
    getAllRestorans() {
      axios.get('/all-restaurants').then(({data}) => {
        this.allRestorants = data.data
      })
    },
    selectRes(res = null) {
      localStorage.removeItem('newRes')
      if (res == null && this.restaurant) {
        let data = {};
        data['user_id'] = this.$store.state.user.id
        data['restoran_id'] = this.restaurant.id
        axios.post('/choose-restaurant', data).then(({data}) => {
          localStorage.setItem('guest', data);
          localStorage.setItem('newRes', this.restaurant.id);
          localStorage.setItem('newCompany', JSON.stringify(data.company));
          this.$root.$emit('setRestoranId', data.data)
          this.deliveryRestoran = data.data
          this.$router.push({ name: "guest", params: { 'data': data.data} });
        })
      } else {
        if (res) {
          let data = {};
          data['user_id'] = this.$store.state.user ? this.$store.state.user.id : ''
          data['restoran_id'] = res.id
          axios.post('/choose-restaurant', data).then(({data}) => {
            localStorage.setItem('guest', JSON.stringify(data));
            localStorage.setItem('newRes', JSON.stringify(data.data));
            this.$root.$emit('test', JSON.stringify(data.data))
            localStorage.setItem('newCompany', JSON.stringify(data.company));
            this.$root.$emit('setRestoranId', data.data)
            this.dialogRestoran = false
            this.$router.push({ name: "guest", params: { 'data': data.data} });
          })
        }
      }

    },
  }
}
</script>

<style scoped>

</style>