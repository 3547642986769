<template>
<div>
  <v-container style="max-width: 1170px;">
    <v-row>
      <h2 class="mb-5">{{ translate( $store.state.user.name) }} {{ translate($store.state.user.lastname) }} / {{ $t("profile_change") }}</h2>
    </v-row>
  </v-container>
  <v-container style="max-width: 800px">
    <v-card-text>
      <v-form>
        <v-row>
          <v-text-field
              v-model="name"
              :label="$t('name')"
              name="name"
              type="text"
          ></v-text-field>
          <v-text-field class="ml-3"
              v-model="lastname"
              :label="$t('last_name')"
              name="name"
              type="text"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-text-field
            v-model="email"
            :label="$t('email')"
            name="name"
            type="text"
          ></v-text-field>
          <v-text-field   class="ml-3"
          v-model="phone"
          :label="$t('phone_no')"
          name="phone"
          type="text"
        ></v-text-field>
        </v-row>
        <v-row>
          <v-select
            @change="getCitiesByCountry"
            :items="countries"
            item-value="id"
            item-text="name"
            v-model="country"
            :label="$t('country')">
          </v-select>
          <v-select class="ml-3"
          :items="cities"
          item-value="id"
          item-text="name"
          v-model="$store.state.user.city_id"
          :label="$t('city')">
        </v-select>
        </v-row>
        <v-row>
          <v-text-field
              v-model="firm"
              :label="$t('firm')"
              name="address"
              type="text"
          ></v-text-field>
          <v-text-field class="ml-3"
          v-model="address"
          :label="$t('address')"
          name="address"
          type="text"
        ></v-text-field>
        </v-row>
        <v-row>
        <v-text-field
            v-model="apartment"
            :label="$t('apartment')"
            name="address"
            type="text"
        ></v-text-field>
        <v-text-field class="ml-3"
            v-model="floor"
            :label="$t('floor')"
            name="address"
            type="text"
        ></v-text-field>
        </v-row>
        <v-select
         v-model="notification"
          :items="items"
          :label="$t('select_notification')"
        ></v-select>
        <v-text-field
          v-model="password"
          id="password"
          :label ="$t('confirm_pass')"
          name="password"
          prepend-icon="mdi-lock"
          type="password"
        ></v-text-field>
       
        <v-alert dense outlined type="error" v-if="failed">
          Погрешна лозинка
        </v-alert>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="px-16" :style="'border-radius: 8px;background: rgb(251, 109, 59);;color:#fff;box-shadow: none;text-transform: capitalize'" @click="update">
        {{ $t('verify_change') }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-container>
</div>
</template>
<script>
import axios from '../plugins/axios'
export default {
    
  data() {
    return {
      name: this.$store.state.user.name,
      lastname: this.$store.state.user.lastname,
      email: this.$store.state.user.email,
      phone: this.$store.state.user.phone,
      address: this.$store.state.user.address,
      apartment: this.$store.state.user.apartment,
      floor: this.$store.state.user.floor,
      firm: this.$store.state.user.firm,
      password:null,
      notification:null,
      items:['email','telefon'],
      failed:false,
      countries: [],
      country: '',
      city: '',
      cities: [{name : 'Изаберите државу'}],
    };
  },
  created() {
    this.countryCity()
  },
  methods: {
    countryCity() {
      axios.get('/restaurants/countries-all').then(({data}) => {
        this.countries = data
        if (this.$store.state.user.city_id) {
          this.cities = []
          data.filter(country => {
            country.city.filter(citi => {
              if (citi.id == this.$store.state.user.city_id) {
                this.country = country.id
                this.cities = country.city
              }
            })
          })
        }
      })
    },
    getCitiesByCountry() {
      this.cities = [{name : 'Изаберите државу'}]
      this.countries.filter(country => {
        if (country.id == this.country) {
          this.cities = country.city
        }
      })
    },
    update() {
      this.failed=false;
      axios.post("/user/edit-profile/" + this.$store.state.user.id, {
        name: this.name,
        lastname: this.lastname,
        email: this.email, 
        phone: this.phone,
        address: this.address,
        password: this.password,
        notification:this.notification,
        _method: "patch",
      }).then(res => {
                this.$store.state.user.name=this.name;
                this.$store.state.user.lastname=this.lastname;
                this.$store.state.user.phone=this.phone;
                this.$store.state.user.address=this.address;
                this.$store.state.user.email=this.email;
                this.$router.push({name: 'profil'})
            })
            .catch(()=>{
              this.failed=true;
            })
        }
    }
}
    

</script>
<style scoped>
label,p, div {
  font-family: 'Poppins', Helvetica, sans-serif !important;
}
</style>