<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{
              $t("menu_for")}} {{ formatDate(selectedDate.date) + ' ' }}
              <span v-if="selectedDate.id">{{'| ID '+ selectedDate.id}}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
<!--              <v-col class="mt-3">-->
                <v-btn dark text
                    @click="deleteDialog=true"
                    :id="this.selectedDate.id"
                ><v-icon> mdi-trash-can </v-icon
                >{{ $t("delete_menu") }}</v-btn
                >
                <!-- <v-switch
                  v-model="selectedDate.one"
                  inset
                  label="Само једно јело"
                  hide-details
                ></v-switch> -->
<!--              </v-col>-->
              <v-btn dark text @click="openDialogCopy">
                <v-icon>mdi-content-copy</v-icon>
                {{ $t("copy_menu") }}
              </v-btn>
              <v-btn dark text @click="addMenu">
                <v-icon>mdi-content-save</v-icon>
                {{ $t("save") }}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
<!--          <v-container>-->
          <v-row>
              <v-col cols="12" sm="4" v-show="false">
                <v-select
                  prepend-icon="mdi-silverware-fork-knife "
                  label="Врста оброка"
                  v-model="selectedDate.name"
                  :items="['dorucak', 'rucak', 'vecera']"
                  @change="menuType"
                ></v-select>
              </v-col>
              <v-col sm="12" class="pa-0"></v-col>
              <v-col>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="selectedDate.date"
                  transition="scale-transition"
                  offset-y
                  format="DD.MM.YYYY"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormatted"
                      :label="$t('date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectedDate.date"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.menu.save(selectedDate.date);
                        menuType();
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
<!--                <v-select-->
<!--                  v-model="filterType"-->
<!--                  :items="[-->
<!--                    { text: $t('all'), val: 0 },-->
<!--                    { text: $t('small'), val: 'small' },-->
<!--                    { text: $t('large'), val: 'large' },-->
<!--                  ]"-->
<!--                  :item-text="item => translate(item.text)"-->
<!--                  item-value="val"-->
<!--                  single-line-->
<!--                ></v-select>-->
                <v-select
                    v-model="filterType"
                    :items="menu_type_for_filter"
                    :item-text="item => $t(item.name)"
                    item-value="name"
                    single-line
                ></v-select>
              </v-col>
            <v-col>
              <v-select
                  v-model="ordering"
                  :items="[
                    { text: $t('id_pr'), val: 1 },
                    { text: $t('align'), val: 2 },
                  ]"
                  :item-text="item => translate(item.text)"
                  item-value="val"
                  single-line
              ></v-select>
            </v-col>
<!--              <v-col v-if="$store.state.restaurant.settings.can_reserve">-->
<!--                <v-select-->
<!--                 v-if="$store.state.restaurant.settings.can_reserve"-->
<!--                  v-model="selectedDate.reserve_id"-->
<!--                  :items="selectedDate.meals"-->
<!--                  item-text="name"  -->
<!--                  item-value="id"-->
<!--                  :label="translate('Резервно јело ')"-->
<!--                  single-line-->
<!--                ></v-select>-->
<!--              </v-col>-->
              <v-spacer></v-spacer>
              <v-col class="mt-3 ml-7">
                <v-btn style="border-radius: 8px;border: 1px solid rgb(251, 109, 59);background: none;color: rgb(251, 109, 59);box-shadow: none;text-transform: capitalize" @click="dialog2 = true">
                  <v-icon> mdi-plus </v-icon>
                  {{ $t("add_meals1") }}
                </v-btn>
              </v-col>
<!--              @click="deleteItem()"-->
<!--              <v-col class="mt-3">-->
<!--                <v-btn-->
<!--                  color="primary"-->

<!--                  @click="deleteDialog=true"-->
<!--                 :id="this.selectedDate.id"-->
<!--                  ><v-icon> mdi-trash-can </v-icon-->
<!--                  >{{ translate("Обриши мени") }}</v-btn-->
<!--                >-->
<!--                &lt;!&ndash; <v-switch-->
<!--                  v-model="selectedDate.one"-->
<!--                  inset-->
<!--                  label="Само једно јело"-->
<!--                  hide-details-->
<!--                ></v-switch> &ndash;&gt;-->
<!--              </v-col>-->
              <v-col cols="12">
                <v-row>
                  <template v-for="(item, i) in filteredMeals" class="position-relative">
                    <v-col cols="12" sm="4" md="3">
                      ID: {{ item.id}}
                     <v-col cols="12" md="12">
                      <v-card :style="$store.state.restaurant.settings && $store.state.restaurant.settings.can_reserve && can_reserve == item.id ? 'border: 1px solid #fd2b2b' : ''">
                        <v-img v-if="item.clone_id != null"
                          height="160"
                          :src="
                            item.image
                              ? conf.apiUrlBase +
                                '/gallery/' +
                                item.clone_id +
                                '/medium/' +
                                item.image.name
                              : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
                          "
                        ></v-img>
                        <v-img v-else
                          height="160"
                          :src="
                          item.image
                            ? conf.apiUrlBase +
                              '/gallery/' +
                              $store.state.restaurant.id +
                              '/medium/' +
                              item.image.name
                            : item.image_id ? conf.apiUrlBase +
                              '/gallery/' +
                              $store.state.restaurant.id +
                              '/medium/' +
                              images.find(id => id.id == item.image_id).name : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
                        "
                        ></v-img>
                        <v-card-title>
                          <div style="width: 100%">{{ item.name }}</div>
                          <v-spacer></v-spacer>
                          <span>
                            <v-btn icon @click="(selectedMenu = item), (gallery = true)">
                              <v-icon small>mdi-image</v-icon>
                            </v-btn>
<!--                            <v-btn v-if="!item.clone_id" icon @click="edit(item)">-->
                            <v-btn icon @click="edit(item)">
                              <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
  <!--                          <v-btn icon @click="selectedDate.meals.splice(i, 1), addMenu()">-->
                            <v-btn icon @click="deleteObrokk(i, item)">
                              <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                          </span>
                        </v-card-title>
                        <v-card-subtitle style="display: flex;justify-content: space-between">
<!--                          <div style="width: 99px">-->
<!--                            {{item.type ? item.type.includes("small") ? $t("small") : "" : '' }}-->
<!--                            {{item.type ? item.type.includes("large") ? $t("large") : "" : '' }}-->
<!--                            {{item.type ? item.type.includes("guest_meal") ? $t("for_guest") : "" : '' }}-->
<!--                          </div>-->
                          <div style="width: 180px">
                            <span v-for="i in item.type">
                                {{ menu_types_filter.includes(i) ? $t(i) : '' }}
                            </span>
                          </div>
                          <div class="text-right">
                            <v-text-field style="width: 40%;padding-top: 0;margin-top: -15px;margin-left: auto"
                                v-model="item.poredak"
                                @input="handleInput(item)"
                                placeholder="20"
                                label="Poredak"
                                type="number"
                            ></v-text-field>
                          </div>
                        </v-card-subtitle>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-list dense>
                            <v-list-item-group color="primary">
                              <v-list-item v-for="(eat, ind) in item.foods" :key="ind">
                                <v-list-item-content>
                                  <v-list-item-title :title="'ID: ' + eat.id" v-if="eat.name" style="font-weight: 500">{{ translate(eat.name) }}</v-list-item-title>
                                </v-list-item-content>
                                <!-- <v-list-item-icon>
                                  {{ eat.price }}.00din
                                </v-list-item-icon> -->
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                          <v-radio-group
                            v-if="$store.state.restaurant.settings && $store.state.restaurant.settings.can_reserve"
                            v-model="can_reserve"
                            @change="changeCanReserve(filteredMeals,item)"
                            column
                          >
                            <v-col v-if="item.price" cols="12" style="margin-top: -30px">
                              <span style="font-size: 13px;font-weight: 700;padding-left: 5px">{{ $t('price') }}: {{ item.price }} {{ $store.state.restaurant.settings.price }}</span>
                            </v-col>
                            <v-radio v-if="! item.clone_id"
                              :label="$t('reserve_meal')"
                              color="#fd2b2b"
                              :value="item.id"
                            ></v-radio>
                          </v-radio-group>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-row>
<!--          </v-container>-->
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCopy" max-width="350px">
    <v-card>
      <v-card-title>
        {{$t('copy_menu_to')}}
      </v-card-title>
      <v-card-text>
          <v-date-picker :picker-date.sync="pickerDate" v-model="date">
        <v-spacer></v-spacer>
        <v-btn @click="dialogCopy=false">
          {{$t("cancel")}}
        </v-btn>
         <v-btn @click="addMenu">
          {{$t("copy")}}
        </v-btn>
        </v-date-picker>
      </v-card-text>
    </v-card>
 </v-dialog>
    <v-dialog v-model="dialog2" max-width="900px" persistent>
      <v-card>
        <div class="d-flex" style="justify-content: space-between;align-items: center">
          <v-card-title>{{ $t("meal") }} {{ selected.id }}</v-card-title>
          <v-btn color="primary" text @click="close">
            {{ $t("close") }}
          </v-btn>
        </div>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="selected.name"
                :label="$t('name_menu')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-0 pt-0">
              <v-text-field
                  class="mt-0 pt-0"
                v-model="selected.description"
                :label="$t('description')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row>
                <template v-for="(food, index) in foods">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="selected.eating"
                      :items="food"
                      ref="autocomplete"
                      item-value="id"
                      :item-text="getItemText"
                      return-object
                      class="mr-16"
                      outlined
                      dense
                      chips
                      small-chips
                      hide-details
                      :label="translate(index)"
                      multiple
                    >
                      <template #selection="{ item }">
                        <v-chip>
                          {{ translate(item.name) }}
                          <v-btn icon @click.prevent="preventBackspace(item)">
                            <v-icon small>mdi-close</v-icon>
                          </v-btn>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </template>
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-row>
                <v-col cols="auto">
                  <v-checkbox
                    v-model="shift"
                    :label="$t('1st_shift')"
                    :value="1"
                  ></v-checkbox>
                </v-col>
                <v-col cols="auto">
                  <v-checkbox
                    v-model="shift"
                    :label="$t('2nd_shift')"
                    :value="2"
                  ></v-checkbox>
                </v-col>
                <v-col cols="auto">
                  <v-checkbox
                    v-model="shift"
                    :label="$t('3rd_shift')"
                    :value="3"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row v-if="status">
                <v-col cols="12">
                  <v-alert dense outlined type="error">
                    {{ $t("choose_shift") }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="3">
                  <v-text-field
                      class="mt-0 pt-0 mr-1"
                      v-model="selected.price"
                      :label="$t('price')"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <span style="font-size: 13px;font-weight: 700;padding-top: 10px;display: block">{{ $store.state.restaurant.settings.price }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
<!--              <v-select-->
<!--                small-chips-->
<!--                hide-details-->
<!--                dense-->
<!--                class="mr-16"-->
<!--                v-model="selected.type"-->
<!--                multiple-->
<!--                chips-->
<!--                :items="menu_type "-->
<!--                item-text="text"-->
<!--                item-value="val"-->
<!--                :label="translate('Тип менија *')"-->
<!--              ></v-select>-->
              <label for="">{{ $t('menu_type') }}</label>
              <v-row>
                <v-col :key="index" cols="auto" v-for="(check, index) in menu_type">
                  <v-checkbox multiple
                      v-model="selected.type"
                      :label=" $t(check.name)"
                      :value="check.name"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
<!--          <v-btn color="primary" text @click="close">-->
<!--            {{ translate("Затвори") }}-->
<!--          </v-btn>-->
          <v-btn color="primary" @click="addMenu2" style="width: 92%;border-radius: 12px" class="mr-16">
            {{ selected.id ? $t("edit_meal") : $t("add_meals") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="gallery" max-width="80%">
      <v-card>
        <v-card-title class="headline">
          {{ $t("select_image") }}
          <v-btn color="#FB6D3B" text style="padding: 0 12px;min-width: 30px;border-radius: 25px;position: absolute;top: 5px;color: #333 !important;right: 5px;border: 1px solid #cfcfcf" @click="gallery = false">
            {{ translate("X") }}
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="searchImage"
                @input="filterImage"
                :label="$t('image_search')"
                prepend-inner-icon="mdi-magnify"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="text-right">
              <input type="file" id="upload" hidden @change="upload" />
              <v-btn class="mt-4" :style="'margin-top: -8px;border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;box-shadow: none;text-transform: capitalize'">
                <label :style="'color: ' + $store.state.restaurant.settings.topbar.background" class="ma-3" for="upload">
                  <v-icon>mdi-plus</v-icon>
                  {{ $t("add_image") }}
                </label>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="image in imagesFilter"
              :key="image.id"
              class="d-flex child-flex"
              cols="2"
            >
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-img
                    :src="
                      conf.apiUrlBase +
                      '/gallery/' +
                      $store.state.restaurant.id +
                      '/medium/' +
                      image.name
                    "
                    @click="selectImage(image)"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" class="pt-0 text-center">
                  <div>{{ image.name | imageName }}</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="gallery = false">
            {{ $t("close") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="350px" class="text-center">
      <v-card class="text-center py-3">
        <v-card-title style="display: unset" class="my-3">
          {{$t('are_you_sure')}}
        </v-card-title>
        <v-card-text class="mt-6">
            <v-spacer></v-spacer>

            <v-btn @click="deleteDialog=false" class="mr-4">
              {{$t("cancel")}}
            </v-btn>

              <v-btn
                  color="primary"
                  @click="deleteItem()"
                  :id="selectedDate.id"
              ><v-icon> mdi-trash-can </v-icon
              >{{ $t("delete_menu") }}</v-btn
              >

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteMessage" max-width="650px" class="text-center">
      <v-card class="text-center py-3">
        <v-card-title style="display: unset" class="my-3">
          {{ deletedMessage }}
        </v-card-title>
        <v-card-text class="mt-6">
          <v-spacer></v-spacer>
          <v-btn @click="closeMessage()" class="mr-4">{{ $t("cancel") }}</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="saveMenu" max-width="550px" class="text-center">
      <v-card class="text-center py-3">
        <v-card-title style="display: unset" class="my-3">
          {{$t('success')}}
          <v-icon style="color: rgba(0,255,13,0.56);font-size: 40px">mdi-success</v-icon>
        </v-card-title>
<!--        <v-card-text class="mt-6">-->
<!--          <v-spacer></v-spacer>-->
<!--          <v-btn @click="saveMenu=false" class="mr-4">{{ translate("Откажи") }}</v-btn>-->
<!--        </v-card-text>-->
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteObrok" max-width="350px" class="text-center">
      <v-card class="text-center py-3">
        <v-card-title style="display: unset" class="my-3">
          {{$t('are_you_sure')}}
        </v-card-title>
        <v-card-text class="mt-6">
          <v-spacer></v-spacer>

          <v-btn @click="deleteObrok = false" class="mr-4">
            {{$t("cancel")}}
          </v-btn>

          <v-btn v-if="! clone.clone_id"
            color="primary"
            @click="selectedDate.meals.splice(deleteNumber, 1), addMenu()">
            <v-icon> mdi-trash-can </v-icon>
            {{ $t("delete_meal") }}
          </v-btn>
          <v-btn v-else
                 color="primary"
                 @click="selectedDate.meals.splice(deleteNumber, 1), deleteCloneMeal(deleteNumber, clone)">
            <v-icon> mdi-trash-can </v-icon>
            {{ $t("delete_meal") }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteObrokError" max-width="350px" class="text-center">
      <v-card class="text-center py-3">
        <v-card-title style="display: unset" class="my-3">
          {{$t('cant_delete')}}
        </v-card-title>
        <v-card-text class="mt-6">
          <v-spacer></v-spacer>
          <p class="py-6">{{ errorObrokMessage }}</p>
          <v-btn @click="deleteObrokError = false" class="mr-4">
            {{$t("cancel")}}
          </v-btn>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "../../plugins/axios";
import conf from "../../config";
import moment from 'moment';
import _debounce from 'lodash/debounce'

export default {
  props: {
    dialog: { type: Boolean, default: false },
    selectedDate: { type: Object, default: false },
    dates_for_disable: { type: Array, default: false },
  },
  data() {
    return {
      // menu_type: [
      //   {text:this.$t('small'),val:'small'},
      //   {text:this.$t('large'),val:'large'},
      //   {text:this.$t('group'),val:'group'}
      // ],
      menu_type: [],
      types: '',
      arrayEvents: null,
      dialogCopy:false,
      deleteDialog:false,
      saveMenu:false,
      deleteObrok:false,
      deleteMessage:false,
      deleteObrokError:false,
      deletedMessage:'',
      filterType: 'all',
      search: null,
      conf: conf,
      selected: {
        id: '',
        name: null,
        eating: null,
        description: null,
        type: null,
        price: null,
      },
      dialog2: false,
      menu: null,
      editItem: null,
      foods: {},
      gallery: false,
      images: [],
      selectedMenu: null,
      shift: [],
      menu_type_for_filter: [],
      status: false,
      searchImage: null,
      imagesFilter: null,
      date:null,
      can_reserve: '',
      deleteNumber: '',
      clone: '',
      errorObrokMessage: '',
      pickerDate: null,
      copiedMeal: '',
      ordering: 1
    };
  },
  watch: {
    selectedDate() {
      if (this.dialog) {
        this.selectedDate.meals.filter(meal => {
          if (meal.can_reserve != null) {
            this.can_reserve = meal.id
          }
        })
      } else {
        this.can_reserve = ''
      }
    },
    pickerDate (newval,oldval) {
     console.log(newval,oldval)
    },
    dates_for_disable() {
      let allDates = document.querySelectorAll(".v-date-picker-table .v-btn .v-btn__content");
      let dates = this.dates_for_disable.map(x => parseInt(x.split('-')[2]));
      dates.forEach((x, i) => {
        if (dates.includes(parseInt(x.innerHTML))) {
          allDates[i].classList.add('date-color');
        }
      })
    },
    dialogCopy(prev, next) {
      setTimeout(() => {
        this.setColor();
      }, 400)
    }
  },
  mounted () {
    // this.setColor();
  },
  created() {
    this.getStatuses()
    // this.setColor();
    this.getFoods();
    this.getImage();
    this.date=null;
    this.$on('close', () => {
      this.can_reserve = ''
    })
    this.$root.$on('menuSaved', () => {
      this.saveMenu = true
      setTimeout(() => {
        this.saveMenu = false
      }, 1500)
    })
    this.$root.$on('errorMessage', (data, menu) => {
      this.deleteMessage = true
      if (menu) {
        this.deletedMessage = this.$t('menu_has_orders')
      } else {
        this.deletedMessage = this.$t('meal_has_orders')
        this.selectedDate.meals.push(this.clone)
      }
      setTimeout(() => {
        this.deleteMessage = false
      }, 3000)
    })
    if(this.$store.state.restaurant.settings.lice_bez_ugovora) {
      this.menu_type.push({
        name: this.$t('for_guest'), val: 'guest_meal'
      })
    } else {
      this.menu_type.push(this.types)
    }
  },
  methods: {
    upload(item) {
      let data = new FormData();
      data.append("image", event.target.files[0]);

      axios.post("/gallery", data).then((res) => {
        this.images.push(res.data);
      });
    },
    getItemText(item) {
      return this.translate(`${item.name}`);
    },
    getStatuses() {
      axios.get('/menus/menu-types').then(({data}) => {
        this.types = data
        this.menu_type = data
        this.menu_type_for_filter.push({name: 'all', val: 'all'})
        data.filter(item => {
          this.menu_type_for_filter.push(item)
        })
      })
    },
    handleInput: _debounce(function (value) {
      axios.post('/meals/set-order-meal', value).then(({data}) => {
        console.log(data)
      })
    }, 600),
    preventBackspace(event) {
      let ind = ''
      this.selected.eating.filter((item,index) => {
        if (item.id == event.id) {
          ind = index
        }
      })
      this.selected.eating.splice(ind, 1)
      this.$forceUpdate()
    },
    formatDate1(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    closeMessage() {
      return this.deleteMessage = false
    },
    openDialogCopy() {
      this.dialogCopy = true
      this.date = this.selectedDate.date
      this.setColor()

    },
    setColor() {
      let allDates = document.querySelectorAll(".v-date-picker-table .v-btn .v-btn__content");
      let dates = this.dates_for_disable.map(x => parseInt(x.split('-')[2]));
      allDates.forEach((x, i) => {
        if (dates.includes(parseInt(x.innerHTML))) {
          allDates[i].classList.add('date-color');
        }
      })
    },
    formatDate(value) {
      return moment(value).format('DD.MM.YYYY.')
    },
    deleteCloneMeal(deleteNumber, clone) {
      axios.post('/meals/delete-clone', clone).then(({data}) => {
        if(data == 'OK') {
          this.selectedDate.meals.filter(item => {

          })
          // this.dialog = false
          this.deleteObrok = false;
        }
      }).catch((error) => {
        if (error.response.data.status == "failed") {
          this.deleteObrokError = true
          this.errorObrokMessage = error.response.data.message
        }
      });
    },
    deleteObrokk(i, item) {
      this.deleteObrok = true;
      this.deleteNumber = i;
      this.clone = item
    },
    changeCanReserve(allmeals, item) {
      let mealsID = []
      allmeals.filter(meal => {
        mealsID.push(meal.id)
        if (meal.can_reserve != null) {
          meal.can_reserve = null
        }
      })
      allmeals.filter(meal => {
        if(meal.id == item.id) {
          meal.can_reserve = item.id
        }
      })
      this.can_reserve = item.id
      let data = {}
      data['allmeals'] = mealsID
      data['item_id'] = item
      axios.post('/meals/change-reserved-meal', data).then(({data}) => {
        this.can_reserve = data.data.can_reserve
      })
    },
    filterImage() {
      this.imagesFilter = this.images.filter((i) =>
        i.name.includes(this.searchImage)
      );
    },
    handleClick() {},
    deleteItem() {
      this.$emit("delete", this.selectedDate.id || null);
      this.deleteDialog = false
    },

    selectImage(image) {
      this.gallery = false;
      this.selectedMenu.image_id = image.id
      this.selectedMenu.image = image
    },
    menuType() {
      if (this.selectedDate.date && this.selectedDate.name) {
        console.log(this.selectedDate.name)
        axios
          .get(
            "/menus?from=" +
              this.selectedDate.date +
              "&to=" +
              this.selectedDate.date +
              "&type=" +
              this.selectedDate.name
          )
          .then((res) => {
            if (res.data.length) {
              this.$emit("select", res.data[0]);
            } else {
              this.$emit("select", {
                date: this.selectedDate.date,
                type: this.selectedDate.name,
                one: this.selectedDate.one ? 1 : 0,
                eating: [],
                meals: [],
              });
            }
          });
      }
    },
    getImage() {
      axios.get("/gallery").then((res) => {
        this.images = res.data;
        this.imagesFilter = res.data;
      });
    },
    sortObject: function(o) {
      return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
    },
    sortedArrays(a) {
      return a.sort((a, b) => {
        return a.name.localeCompare(b.name)
      });
    },

    getFoods() {
      axios.get("/foods").then((res) => {
        let sortedArray = {}
        let prepArray = {}
        res.data.forEach((food) => {
          if (food && food.category && food.category.name) {
            if (!prepArray.hasOwnProperty(food.category.name)) {
              prepArray[food.category.name] = [];
            }
            prepArray[food.category.name].push(food);
          }
        });
        sortedArray = this.sortObject(prepArray);

        for (let i in sortedArray) {
          sortedArray[i] = sortedArray[i].sort(function (a, b) {
            return a.name.localeCompare(b.name);
          })
        }
        this.foods = sortedArray
        // console.log(this.foods)
      });
    },
    edit(item) {
      this.selected.id=item.id;
      this.selected.name = item.name;
      this.selected.one = item.one ? 1 : 0;
      this.selected.eating = item.foods;
      this.selected.description = item.description;
      this.selected.type = [...new Set(item.type)];
      this.selected.price = item.price;
      this.editItem = item;
      this.shift = [
        item.shift_one && 1,
        item.shift_two && 2,
        item.shift_three && 3,
      ];

      this.dialog2 = true;
    },
    close() {
      this.selected.id='';
      this.selected.name = null;
      this.selected.description = null;
      this.selected.type = null;
      this.selected.price = null;
      this.selected.eating = [];
      this.editItem = null;
      this.dialog2 = false;
      this.deleteObrok = false
    },
    addMenu2() {
      if (this.shift.length) {
        if (this.editItem) {
          this.editItem.name = this.selected.name;
          this.editItem.price = this.selected.price;
          this.editItem.foods = this.selected.eating;
          (this.editItem.description = this.selected.description),
            (this.editItem.type = this.selected.type),
            (this.editItem.one = this.selected.one ? 1 : 0);
          (this.editItem.shift_one = this.shift.includes(1) ? 1 : 0),
            (this.editItem.shift_two = this.shift.includes(2) ? 1 : 0),
            (this.editItem.shift_three = this.shift.includes(3) ? 1 : 0);
        } else {
          if (!this.selectedDate.hasOwnProperty("meals"))
            this.selectedDate.meals = [];
          this.selectedDate.meals.push({
            name: this.selected.name,
            foods: this.selected.eating,
            description: this.selected.description,
            type: this.selected.type,
            price: this.selected.price,
            one: this.selected.one ? 1 : 0,
            shift_one: this.shift.includes(1) ? 1 : 0,
            shift_two: this.shift.includes(2) ? 1 : 0,
            shift_three: this.shift.includes(3) ? 1 : 0,
          });
        }
        let tempId=this.selectedDate.id;
        if(this.dialogCopy&&this.date){
          tempId=null;
          this.dialogCopy=false;
        }else{
          this.date=this.selectedDate.date;
        }
        const min = new Date(`${this.date}T00:00:01`);
        const max = new Date(`${this.date}T23:59:59`);
        this.$emit("add", {
          id: tempId || null,
          name: this.selectedDate.name,
          price: this.selectedDate.price,
          date: this.date,
          reserve_id:this.selectedDate.reserve_id,
          start: min,
          end: max,
          color: this.color,
          timed: false,
          meals: this.selectedDate.meals,
          type: 'rucak',
          one: this.selectedDate.one ? 1 : 0,
        });
        this.selected.id='';
        this.selected.name = null;
        this.selected.description = null;
        this.selected.type = null;
        this.selected.price = null;
        this.selected.eating = [];
        this.editItem = null;
        this.dialog2 = false;
        this.deleteObrok = false
      } else {
        this.status = true;
      }
    },
    addMenu() {
      let tempId=this.selectedDate.id;
      if(this.dialogCopy&&this.date){
        tempId=null;
        this.dialogCopy=false;
      }else{
        this.date=this.selectedDate.date;
      }
      const min = new Date(`${this.date}T00:00:01`);
      const max = new Date(`${this.date}T23:59:59`);
      this.$emit("add", {
        id: tempId || null,
        name: this.selectedDate.name,
        date: this.date,
        reserve_id:this.selectedDate.reserve_id,
        start: min,
        end: max,
        color: this.color,
        timed: false,
        meals: this.selectedDate.meals,
        type: 'rucak',
        one: this.selectedDate.one ? 1 : 0,
      });
      this.date=null;
      this.deleteObrok = false
      this.deleteDialog = false
    },
  },
  computed: {
    menu_types_filter() {
      let tipovi = []
      this.menu_type.filter(item => {
        tipovi.push(item.name)
      })
      return tipovi
    },
    computedDateFormatted () {
      return this.formatDate(this.selectedDate.date)
    },
    color() {
      switch (this.selectedDate.name) {
        case "dorucak":
          return "green";
          break;
        case "rucak":
          return "blue";
          break;
        case "vecera":
          return "red";
          break;
      }
    },
    filteredMeals() {
      if (this.filterType) {
        if (this.filterType == 'all') {
          return this.selectedDate.meals;
        } else {
          return this.selectedDate.meals.filter((item) =>
              item.type.includes(this.filterType)
          );
        }

      } else {
        if (this.ordering == 2) {
          return this.selectedDate.meals.slice().sort((a, b) => b.poredak - a.poredak)
        } else {
          return this.selectedDate.meals;
        }

      }
    },
  },
  filters: {
    imageName(name) {
      if (typeof name == 'string') {
        let imageName = name.split(".");
        let finalImage = imageName[0].split("_")
        // return imageName[0].replace("_", " ").replace("-", " ");
        return finalImage[1];
      }
    },
  },
};
</script>
<style>
.v-chip.v-size--default {
    border-radius: 12px;
    font-size: 11px;
    height: 20px;
}
.date-color {
  color: #20e14c;
  border: 1px solid #20e14c;
  padding: 5px;
  border-radius: 100%;
}
@media(max-width: 767px) {
  .v-card__title {
    display: block;
  }
}
</style>
