<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-card-title class="pa-0">
          <v-toolbar dark :color="topBarColor">
            <v-btn icon dark @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $store.state.restaurant.parent ? $store.state.restaurant.parent != null ? $store.state.restaurant.parent.name : '' : '' }} - {{translate("Клонирање менија ")}}
              <span v-if="selectedDate.id">
              за дан {{selectedDate.date | formatDate}}.
              </span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn style="height: 39px !important;margin-top:12px;box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);border-radius: 8px" class="primary" :disabled="selectedMeals.length == 0" dark text @click.prevent="klonirajMenu(selectedMeals)">
                {{ translate("Копирај селектовано")  }}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4" v-show="false">
              <v-select
                prepend-icon="mdi-silverware-fork-knife "
                label="Врста оброка"
                v-model="selectedDate.name"
                :items="['dorucak', 'rucak', 'vecera']"
                @change="menuType"
              ></v-select>
            </v-col>
            <v-col sm="12" class="pa-0"></v-col>
            <v-col>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12">
              <p class="pl-3 font-weight-bold">Кликните на оброк који желите да клонирате</p>
              <v-row>
                <template v-for="(item, i) in filteredMeals" class="position-relative">
                  <v-col  @click.prevent="markMeal(item,i)" cols="12" sm="4" md="3">
                    <v-col cols="12" sm="4" md="12">
                      <v-card :style="selectedMeals.includes(item.id) ? 'border: 3px solid #fd2b2b' : ''">
                        <v-img
                          height="160"
                          :src="
                            item.image
                              ? conf.apiUrlBase +
                                '/gallery/' +
                                $store.state.user.restaurants[0].parent_id +
                                '/medium/' +
                                item.image.name
                              : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
                          "
                        ></v-img>
                        <v-card-title style="font-size: 13px">
                          ID: {{ item.id }} | {{ translate(item.name) }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-list dense>
                            <v-list-item-group color="primary">
                              <v-list-item
                                v-for="(eat, i) in item.foods"
                                :key="i"
                              >
                                <v-list-item-content>
                                  <v-list-item-title v-text="eat.name"></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                          <v-col v-if="item.price" cols="12" style="margin-top: -30px">
                            <span style="font-size: 13px;font-weight: 700;padding-left: 5px">Цена: {{ item.price }} {{ $store.state.restaurant.settings && $store.state.restaurant.settings.price ? $store.state.restaurant.settings.price : '' }}</span>
                          </v-col>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog v-model="confirmClone" max-width="500px" class="p-2">
        <v-card>
          <v-card-title :color="topBarColor" class="error text-center position-relative">
              <span style="font-size: 16px" class="white--text">
                {{ translate("Да ли сте сигурни да желите да клонирате мени?") }}
              </span>
            <v-btn color="primary" text style="position: absolute;top: 5px;color: white !important;right: 5px" @click="confirmClone = false">
              {{ translate("X") }}
            </v-btn>
          </v-card-title>
          <v-col cols="12">
            <p class="text-center">За дан {{ selectedDate.date | formatDate }}.</p>
          </v-col>
          <div class="pb-7 text-center">
            <v-btn color="gray" class="mr-2 success" @click.prevent="confirmCloning(selectedMeals, selectedDate)">
              {{ translate("Клонирај") }}
            </v-btn>
            <v-btn color="gray" dark @click="confirmClone = false">
              {{ translate("Затвори") }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
import axios from "../../plugins/axios";
import conf from "../../config";
import moment from 'moment'

export default {
  props: {
    dialog: { type: Boolean, default: false },
    selectedDate: { type: Object, default: false },
    old_events: { type: Array, default: false },
    new_events: { type: Array, default: false },
  },

  data() {
    return {
      dialogCopy:false,
      confirmClone:false,
      deleteDialog:false,
      deleteObrok:false,
      filterType: 0,
      search: null,
      conf: conf,
      selected: {
        id: '',
        name: null,
        eating: null,
        description: null,
        type: null,
        price: null,
      },
      dialog2: false,
      menu: null,
      editItem: null,
      foods: {},
      gallery: false,
      images: [],
      selectedMenu: null,
      shift: [],
      status: false,
      searchImage: null,
      imagesFilter: null,
      date:null,
      can_reserve: '',
      deleteNumber: '',
      selectedMeals: []
    };
  },
  watch: {
    new_events() {
      if (this.new_events.length) {
        this.new_events.filter(it => {
          if(it.meals && it.meals.length) {
            it.meals.filter(meal => {
              if (! this.selectedMeals.includes(meal.clone_meal_id ? meal.clone_meal_id : meal.id)) {
                this.selectedMeals.push(meal.clone_meal_id ? meal.clone_meal_id : meal.id)
              }
            })
          }
        })
        // if (this.selectedMeals.length) {
          this.selectedMeals = [...new Set(this.selectedMeals)]
        // }

        this.$forceUpdate()
      }
    },
    selectedDate() {
      if (this.dialog) {
        this.selectedDate.meals.filter(meal => {
          if (meal.can_reserve != null) {
            this.can_reserve = meal.id
          }
        })
      } else {
        this.can_reserve = ''
      }
    }
  },
  created() {
    this.getFoods();
    this.getImage();
    this.date = null;
    this.$on('close', () => {
      this.can_reserve = ''
    })
    this.$root.$on('refreshChildComponent', (data) => {
      if (data.meals.length) {
        this.new_events.filter(it => {
          if(it.meals && it.meals.length) {
            it.meals.filter(meal => {
              it.menu_id = meal.old_menu_id
              meal.id = meal.clone_meal_id
            })
          }
        })
        data.meals.filter(it => {
          if (this.selectedDate.meals) {
            this.selectedDate.meals.filter((i,ind) => {
              if (i.clone_meal_id == it.clone_meal_id) {
                i.menu_id = this.selectedDate.id
                this.selectedMeals.push(i.clone_meal_id)
                i.id = i.clone_meal_id
              }
            })
          }
        })
        this.$forceUpdate()
      }
    })
    this.$root.$on('oldData', (data) => {
      console.log(data)
    })
  },
  methods: {
    confirmCloning(selectedMeals, date) {
      let data = {}
      data['selected_meals'] = selectedMeals
      this.new_events.filter(ev => {
        if (ev.date == this.selectedDate.date) {
          data['parent_menu_id'] = ev.id
        }
      })
      data['restoran_parent_id'] = this.$store.state.restaurant.parent.id
      data['date'] = date
      this.$parent.$emit('cloneParentMenu', data)
      this.$root.$emit('cloneParentMenu12', data)
      this.confirmClone = false
      this.selectedMeals = []
      this.$emit('close')
    },
    klonirajMenu(selectedMeals) {
      this.confirmClone = true
    },
    markMeal(item,index) {
      if (this.selectedMeals.includes(item.id)) {
        this.selectedMeals.splice(this.selectedMeals.indexOf(item.id), 1);
      } else {
        this.selectedMeals.push(item.id)
      }
      this.$forceUpdate()
    },
    deleteObrokk(i) {
      this.deleteObrok = true;
      this.deleteNumber = i;
    },
    changeCanReserve(allmeals, item) {
      let mealsID = []
      allmeals.filter(meal => {
        mealsID.push(meal.id)
        if (meal.can_reserve != null) {
          meal.can_reserve = null
        }
      })
      allmeals.filter(meal => {
        if(meal.id == item.id) {
          meal.can_reserve = item.id
        }
      })
      this.can_reserve = item.id
      let data = {}
      data['allmeals'] = mealsID
      data['item_id'] = item
      axios.post('/meals/change-reserved-meal', data).then(({data}) => {
        this.can_reserve = data.data.can_reserve
      })
    },
    filterImage() {
      this.imagesFilter = this.images.filter((i) =>
        i.name.includes(this.searchImage)
      );
    },
    handleClick() {},
    deleteItem() {
      this.$emit("delete", this.selectedDate.id || null);
      this.deleteDialog = false
    },
    
    selectImage(image) {
      this.gallery = false;
      this.selectedMenu.image_id = image.id
      this.selectedMenu.image = image
    },
    menuType() {
      if (this.selectedDate.date && this.selectedDate.name) {
        axios
          .get(
            "/menus?from=" +
              this.selectedDate.date +
              "&to=" +
              this.selectedDate.date +
              "&type=" +
              this.selectedDate.name
          )
          .then((res) => {
            if (res.data.length) {
              this.$emit("select", res.data[0]);
            } else {
              this.$emit("select", {
                date: this.selectedDate.date,
                type: this.selectedDate.name,
                one: this.selectedDate.one ? 1 : 0,
                eating: [],
                meals: [],
              });
            }
          });
      }
    },
    getImage() {
      axios.get("/gallery").then((res) => {
        this.images = res.data;
        this.imagesFilter = res.data;
      });
    },
    getFoods() {
      axios.get("/foods").then((res) => {
        res.data.forEach((food) => {
          if (food && food.category && food.category.name) {
            if (!this.foods.hasOwnProperty(food.category.name)) {
              this.foods[food.category.name] = [];
            }
            this.foods[food.category.name].push(food);
          }
        });

        // console.log(this.foods)
      });
    },
    edit(item) {
      //console.log(item);
      this.selected.id=item.id;
      this.selected.name = item.name;
      this.selected.one = item.one ? 1 : 0;
      this.selected.eating = item.foods;
      this.selected.description = item.description;
      this.selected.type = item.type;
      this.selected.price = item.price;
      this.editItem = item;
      this.shift = [
        item.shift_one && 1,
        item.shift_two && 2,
        item.shift_three && 3,
      ];

      this.dialog2 = true;
    },
    close() {
      this.selected.id='';
      this.selected.name = null;
      this.selected.description = null;
      this.selected.type = null;
      this.selected.price = null;
      this.selected.eating = [];
      this.editItem = null;
      this.dialog2 = false;
      this.deleteObrok = false
    },
    addMenu2() {
      if (this.shift.length) {
        if (this.editItem) {
          this.editItem.name = this.selected.name;
          this.editItem.price = this.selected.price;
          this.editItem.foods = this.selected.eating;
          (this.editItem.description = this.selected.description),
            (this.editItem.type = this.selected.type),
            (this.editItem.one = this.selected.one ? 1 : 0);
          (this.editItem.shift_one = this.shift.includes(1) ? 1 : 0),
            (this.editItem.shift_two = this.shift.includes(2) ? 1 : 0),
            (this.editItem.shift_three = this.shift.includes(3) ? 1 : 0);
        } else {
          if (!this.selectedDate.hasOwnProperty("meals"))
            this.selectedDate.meals = [];
          this.selectedDate.meals.push({
            name: this.selected.name,
            foods: this.selected.eating,
            description: this.selected.description,
            type: this.selected.type,
            price: this.selected.price,
            one: this.selected.one ? 1 : 0,
            shift_one: this.shift.includes(1) ? 1 : 0,
            shift_two: this.shift.includes(2) ? 1 : 0,
            shift_three: this.shift.includes(3) ? 1 : 0,
          });
        }
        let tempId=this.selectedDate.id;
        if(this.dialogCopy&&this.date){
          tempId=null;
          this.dialogCopy=false;
        }else{
          this.date=this.selectedDate.date;
        }
        const min = new Date(`${this.date}T00:00:01`);
        const max = new Date(`${this.date}T23:59:59`);
        this.$emit("add", {
          id: tempId || null,
          name: this.selectedDate.name,
          price: this.selectedDate.price,
          date: this.date,
          reserve_id:this.selectedDate.reserve_id,
          start: min,
          end: max,
          color: this.color,
          timed: false,
          meals: this.selectedDate.meals,
          type: 'rucak',
          one: this.selectedDate.one ? 1 : 0,
        });
        this.selected.id='';
        this.selected.name = null;
        this.selected.description = null;
        this.selected.type = null;
        this.selected.price = null;
        this.selected.eating = [];
        this.editItem = null;
        this.dialog2 = false;
        this.deleteObrok = false
      } else {
        this.status = true;
      }
    },
    addMenu() {
      let tempId=this.selectedDate.id;
      if(this.dialogCopy&&this.date){
        tempId=null;
        this.dialogCopy=false;
      }else{
        this.date=this.selectedDate.date;
      }
      const min = new Date(`${this.date}T00:00:01`);
      const max = new Date(`${this.date}T23:59:59`);
      this.$emit("add", {
        id: tempId || null,
        name: this.selectedDate.name,
        date: this.date,
        reserve_id:this.selectedDate.reserve_id,
        start: min,
        end: max,
        color: this.color,
        timed: false,
        meals: this.selectedDate.meals,
        type: 'rucak',
        one: this.selectedDate.one ? 1 : 0,
      });
      this.date=null;
      this.deleteObrok = false
      this.deleteDialog = false
    },
  },
  computed: {
    topBarColor() {
      return this.$store.state.restaurant && this.$store.state.restaurant.settings
          ? this.$store.state.restaurant.settings.topbar
              ? this.$store.state.restaurant.settings.topbar.background
              : "primary"
          : this.$store.state.company &&
          this.$store.state.company.restaurant.settings
              ? this.$store.state.company.restaurant.settings.topbar.background
              : "primary";
    },
    color() {
      switch (this.selectedDate.name) {
        case "dorucak":
          return "green";
          break;
        case "rucak":
          return "blue";
          break;
        case "vecera":
          return "red";
          break;
      }
    },
    filteredMeals() {
      if (this.filterType) {
        return this.selectedDate.meals.filter((item) =>
          item.type.includes(this.filterType)
        );
      } else {
        return this.selectedDate.meals;
      }
    },
  },
  filters: {
    imageName(name) {
      let imageName = name.split(".");
      return imageName[0].replace("_", " ").replace("-", " ");
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    }
  },
};
</script>
