<template>
<div>
    <div v-if="restaurant.settings.header" class="hero" :style="`background: url('https://app.restorani.bitlab.host/gallery/${restaurant.id}/medium/${restaurant.settings.header}'); background-size: cover; background-position: center;`">
      <img
      class="hero-logo"
      :src="
        conf.apiUrlBase +
        '/gallery/' +
        restaurant.id +
        '/' +
        restaurant.settings.logo
      "
    />
    </div>
  <v-container>
    <v-card-title>
<!--      <v-row align="center" justify="center">-->
<!--        <strong>-->
<!--          {{translate("НАРУЧИВАЊЕ, ОТКАЗИВАЊЕ, ПРОМЈЕНА СМЈЕНЕ - ОБАВЕЗНО")}}-->
<!--          {{restaurant.settings.order_before}}-->
<!--          {{(restaurant.settings.order_before==1)?translate("ДАН"):translate("ДАНА")}}-->
<!--          {{translate("РАНИЈЕ")}}-->
<!--        </strong>-->
<!--      </v-row>-->
      <v-row cols="12" align="center" justify="center" class="mx-2">
        <strong class="py-2 rezervisati">
          <span v-if="restaurant.settings.rok_sati">
            {{$t("ordering_cancelling_shift_change_before")}}
            <span style="color: #c20606">
              {{switchDay(restaurant.settings.dan).toUpperCase()}} ДО {{restaurant.settings.broj_sati + ':00' }}</span>
          </span>
          <span v-else>
            <span v-if="restaurant.settings.order_before > 0">
             {{$t("order_cancel_change_shift_mandatory")}}
              <span style="color: #c20606">
                {{restaurant.settings.order_before}}
                {{(restaurant.settings.order_before==1)?translate("ДАН"):translate("ДАНА")}}
                {{$t("early")}}
              </span>
            </span>
            <span v-else>{{$t("order_cancel_change_shift")}}</span>
          </span>
        </strong>
      </v-row>
    </v-card-title>
    <v-divider class="ma-4"></v-divider>
    <v-row  class="mt-5" align="center" justify="center">
      <v-card align="center" justify="center" width="400px">
        <!-- <img
          class="logo-info"
          :src="
            conf.apiUrlBase +
            '/gallery/' +
            restaurant.id +
            '/medium/' +
            restaurant.settings.logo
          "
        /> -->
        <v-card-title>
          <v-row align="center" justify="center">{{
            translate(restaurant.name)
          }}</v-row>
        </v-card-title>
        <v-card-text align="center" justify="center">
          {{ translate(restaurant.address) }}
        </v-card-text>
        <v-divider class="ma-1"></v-divider>
<!--        <v-card-subtitle>-->
<!--          {{ translate("Контактирајте нас") }}-->
<!--        </v-card-subtitle>-->
<!--        <v-divider></v-divider>-->
        <v-card-text align="center" justify="center">
          <v-icon class="mr-1" color="red" small>mdi-cellphone</v-icon>
          {{ translate(restaurant.phone) }}
        </v-card-text>

        <v-card-text align="center" justify="center">
          <v-icon class="mx-1" color="red" small>mdi-email</v-icon>
          {{ translate(restaurant.email) }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text align="center" justify="center">
          <a :href="restaurant.settings.website" target="_blank" style="text-decoration: none"><strong  >{{restaurant.settings.website}}</strong></a>
        </v-card-text>

        <v-row class="mx-0 my-2" align="center" justify="center">
          <v-btn
              v-for="icon in icons"
              :key="icon.type"
              class="my-3 black--text"
              icon
              v-if="restaurant.settings[icon.type] != null"
              :href="restaurant.settings[icon.type]"
              target="_blank"
          >
            <v-icon color="red" size="24px">
              {{ icon.icon }}
            </v-icon>
          </v-btn>
        </v-row>
      </v-card>
    </v-row>
    <v-row class="mx-0" align="center" justify="center">
      <div style="display: flex; gap: 20px; width: 100%; max-width: 400px; margin: 30px 0;">
        <div v-for="image in mobile"
          :key="image.type">
          <a v-if="restaurant.settings[image.type] != null" :href="restaurant.settings[image.type]">
            <img style="width: 100%;" :style="image.type == 'huawei' ? 'width:127%' : ''" :src="image.image" alt="">
          </a>
        </div>
      </div>

      </v-row>

      <p class="text-center mb-0 pb-0">
        {{$t('technical_support')}} help@ordera.app
      </p>

      <p class="text-center mb-0 pb-0">
        <a href="https://topli-obrok.ordera.app/lat/" target="_blank" style="text-decoration: none"><strong  >{{$t('about_application')}}</strong></a>
      </p>
      <p class="text-center mb-0 pb-0">
        {{$t('app_developed_by')}}<a href="https://bitlab.rs/" target="_blank" style="text-decoration: none"><strong  >{{$t('bitlab')}}</strong></a>
      </p>
  </v-container>
</div>
</template>
<script>
import conf from "../config";
export default {
  data: () => ({
    conf: conf,
    icons: [
      { icon: "mdi-facebook", type: "facebook" },
      { icon: "mdi-twitter", type: "twitter" },
      { icon: "mdi-linkedin", type: "linkedin" },
      { icon: "mdi-instagram", type: "instagram" },
    ],
    mobile:[
      { image: "https://app.restorani.bitlab.host/gallery/60/LBca_playstore.png", type: "google" },
      { image: "https://app.restorani.bitlab.host/gallery/60/NhQp_appstore.png", type: "apple" },
      { image: "https://app.restorani.bitlab.host/gallery/64/huawei.png", type: "huawei" },
    ]
  }),
  methods: {
    switchDay(dani) {
      let dan = ''

      if (dani == 'Понедељак') {
        dan = 'Понедељакa'
      }
      if (dani == 'Уторак') {
        dan = 'Уторкa'
      }
      if (dani == 'Среда') {
        dan = 'Средe'
      }
      if (dani == 'Четвртак') {
        dan = 'Четврткa'
      }
      if (dani == 'Петак') {
        dan = 'Петкa'
      }
      if (dani == 'Субота') {
        dan = 'Суботe'
      }
      if (dani == 'Недеља') {
        dan = 'Недељe'
      }
      return dan
    },
  },
  computed: {
    restaurant() {
      return this.$store.state.restaurant
        ? this.$store.state.restaurant
        : this.$store.state.company.restaurant;
    },
  },
};
</script>

<style scoped>
.logo-info {
  width: 100%;
  object-fit: contain;
}
</style>
