<template>
  <v-row class="py-4 home-page">
    <template>
      <v-dialog v-model="edit_user_location" max-width="25%" class="p-2">
        <v-card>
          <v-card-title style="background: #fff" class="position-relative">
            <v-btn color="#FB6D3B" text style="position: absolute;top: 5px;color: #333 !important;right: 5px;border: 1px solid #cfcfcf" @click="edit_user_location = false">
              {{ translate("X") }}
            </v-btn>
          </v-card-title>

          <v-col cols="12">
            <p style="padding: 10px; font-weight: 700;text-align: center">{{ $t("change_current_location") }}</p>
            <div class="text-center">
              <v-row>
              <v-col cols="6">
                <v-select
                    @change="getCitiesByCountry"
                    :items="countries"
                    item-value="id"
                    item-text="name"
                    v-model="edit_country"
                    :label="$t('country')">
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                    :items="editCountries"
                    item-value="id"
                    item-text="name"
                    v-model="edit_city"
                    :label="$t('city')">
                </v-select>
              </v-col>
              </v-row>
              <input style="width: 100%;border:1px solid #bdbdbd;padding: 8px 10px;border-radius: 7px" type="text" v-model="changedUserLocation">
            </div>
          </v-col>
          <div class="pb-7 text-center">
            <v-btn color="#FB6D3B" dark class="" style="width: 93%" @click.prevent="setEditLocation()">
              {{ $t("change") }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-if="guestErrorMessagePopup" v-model="guestErrorMessagePopup" max-width="30%" class="p-2">
        <v-card>
          <v-card-title style="background: #FB6D3B" class="position-relative">
            <span class="headline white--text">
              {{ translate(guestErrorMessageHeader) }}
            </span>
            <v-btn color="primary" text style="position: absolute;top: 5px;color: white !important;right: 5px" @click="guestErrorMessagePopup = false">
              {{ translate("X") }}
            </v-btn>
          </v-card-title>

          <v-col cols="12">
            <p class="text-center">{{ guestErrorMessage }}</p>
          </v-col>
          <div class="pb-7 text-center">
            <v-btn color="gray" dark @click="guestErrorMessagePopup = false">
              {{ $t("close") }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="order_success" max-width="40%" class="p-2">
        <v-card>
          <v-card-title style="background: #fff" class="position-relative">
            <v-btn color="#FB6D3B" text style="position: absolute;top: 5px;color: #009924 !important;right: 5px;border: 1px solid #009924" @click="order_success = false">
              {{ translate("X") }}
            </v-btn>
          </v-card-title>

          <v-col cols="12">
            <div class="text-center">
              <img style="cursor: pointer;border-top-left-radius: 10px;border-top-right-radius: 10px;" class="card-img-top" width="40%" height="40%" src="https://app.restorani.bitlab.host/gallery/Frame.jpg" alt="Logo">
              <p style="color: #009924; font-weight: 700; font-size: 20px">{{ $t("successfully_ordered") }}</p>
            </div>
          </v-col>
          <div class="pb-2 text-center">
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-if="$store.state.user" persistent v-model="phone_popup" max-width="25%" class="p-2">
        <v-card>
          <v-card-title style="background: #fff" class="position-relative">
            <v-btn color="#FB6D3B" text style="position: absolute;top: 5px;color: #333 !important;right: 5px;border: 1px solid #cfcfcf" @click="phone_popup = false">
              {{ translate("X") }}
            </v-btn>
          </v-card-title>

          <v-col cols="12">
            <p style="padding: 10px; font-weight: 700;text-align: center">{{ $t("change_phone_number") }}</p>
            <div class="text-center">
              <input style="width: 100%;border:1px solid #bdbdbd;padding: 8px 10px;border-radius: 7px" type="text">
            </div>
          </v-col>
          <div class="pb-7 text-center">
            <v-btn color="#FB6D3B" dark class="" style="width: 93%" @click.prevent="changePhone()">
              {{ $t("change") }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="image_popup" max-width="30%" class="p-2">
        <v-card v-if="mealDesc" style="position: relative">
          <v-card-title style="position: absolute;right: 10px;top:10px;">
            <v-btn color="#FB6D3B" text style="position: absolute;top: 5px;color: white !important;right: 5px;background: rgba(0,0,0,0.29);border: 1px solid #cfcfcf" @click="image_popup = false">
              {{ translate("X") }}
            </v-btn>
          </v-card-title>
          <img v-if="mealDesc.image" :key="mealDesc.image_id"  class="card-img-top" width="100%" :src="conf.apiUrlBase + '/gallery/' +  mealDesc.image.restaurant_id + '/medium/' +  mealDesc.image.name" alt="image">
          <span v-else style="text-align: center;display: block">
            <v-icon style="padding:50px 0" small>mdi-image</v-icon>
          </span>
          <div style="border-top-left-radius: 20px;border-top-right-radius: 20px;background: rgb(255, 255, 255);position: relative;z-index: 99999999;box-shadow: 0 0 2px #cfcfcf;padding: 0;margin-top: -35px;">
            <p class="text-center mt-2 py-3 mb-0 mx-9" style="border-bottom: 1px solid #dcdcdc">{{ translate(mealDate) }}.</p>
            <h2 class="text-center mt-2 mx-9 py-1" style="display: flex;justify-content: space-between;align-items: center">
              <span style="font-size: 15px">
                {{ translate(mealDesc.name) }}
                <span style="font-size: 12px;font-weight: 400;color: #595959;display: block" v-if="mealDesc.description">{{ translate(mealDesc.description) }}</span>
              </span>
              <span>
                <span v-if="mealDesc.price">
                  {{ mealDesc.price ? formatPrice(mealDesc.price) : 0 }}
                  <span style="font-size: 12px">{{ noUserRestoran ? noUserRestoran.restaurant.settings.price : restaurant.settings.price }}</span>
                </span>
              </span>
            </h2>
            <div class="px-9 mt-3">
              <p style="font-size: 12px" class="font-weight-bold">{{ translate('Садржи') }}:</p>
              <p v-for="food in mealDesc.foods">
                - {{ translate(food.name) }}
              </p>
            </div>
            <div class="pb-7 mt-8 mx-4 text-center">
              <div style="display: flex;align-items: center;justify-content: center;width: 100%;margin-bottom: 15px">
                <a @click.prevent="qty(buyListMeal, 'minus', mealDesc.date, buyListShift)" style="width: 11%;display: flex;justify-content: center;align-items: center;margin-right: 20px">
                  <span style="border-radius: 7px;padding: 0 15px;color: #ff4c0b;background: rgba(251,109,59,0.43);font-size: 27px">-</span>
                </a>
                <span> {{ buyListMeal.qty ? buyListMeal.qty : 0 }} </span>
                <a @click.prevent="qty(buyListMeal, 'plus', mealDesc.date, buyListShift)" style="width: 11%;display: flex;justify-content: center;align-items: center;margin-left: 20px">
                  <span style="border-radius: 7px;padding: 0 10px;font-size: 27px;color: #06B04D;background: rgba(95,227,148,0.6)">+</span>
                </a>
              </div>
              <v-btn @click.prevent="addToBuyList(buyListMeal, buyListItem, buyListShift, buyListIndex)" color="#FB6D3B" style="width:100%;color: #fff;text-transform: capitalize;font-weight: 400">
                {{ $t('order') }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
<!--      <v-dialog v-model="dialogRestoran" max-width="70%" class="p-2">-->
<!--        <v-card>-->
<!--          <v-card-title style="background:#FB6D3B" class="position-relative">-->
<!--              <span class="headline white&#45;&#45;text"-->
<!--              >{{ translate("Изаберите ресторан:") }}</span-->
<!--              >-->
<!--            <v-btn color="primary" text style="position: absolute;top: 5px;color: white !important;right: 5px" @click="dialogRestoranClose">-->
<!--              {{ translate("X") }}-->
<!--            </v-btn>-->
<!--          </v-card-title>-->
<!--          <br><br>-->
<!--          <v-col cols="12">-->
<!--            <v-row class="align-content-center justify-content-center">-->
<!--              <div class="card text-center" style="width: 33%;padding: 10px 5px;" v-for="res in allRestorants">-->
<!--                <div style="box-shadow: 0 1px 14px 2px #cfcfcf;border-radius: 7px;height: 230px;position: relative;">-->
<!--                  <img onerror="javascript:this.src='https://app.restorani.bitlab.host/gallery/no-image-restaurant.jpg'" style="cursor: pointer" @click="selectRes(res)" class="card-img-top " width="100%" height="75%" :src="res.settings ?  conf.apiUrlBase + '/gallery/' + res.id + '/' + res.settings.logo : ''" alt="Logo">-->
<!--                  <div class="card-body">-->
<!--                    <h5 class="card-title">{{ res.name }}</h5>-->
<!--                    <p style="font-size: 11px;" class="card-text mb-0">-->
<!--                      <span>{{ res.address }}</span>-->
<!--                    </p>-->
<!--                    <p style="font-size: 11px;" class="card-text mb-0 font-weight-bold">-->
<!--                      <span>{{ res.city ? res.city.name : '' }}</span>-->
<!--                    </p>-->
<!--&lt;!&ndash;                    <a @click="selectRes(res)" style="font-size: 10px;" href="#" class="btn-primary">Изабери</a>&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </v-row>-->
<!--            &lt;!&ndash;                <v-select&ndash;&gt;-->
<!--            &lt;!&ndash;                    v-model="restoran_id"&ndash;&gt;-->
<!--            &lt;!&ndash;                    :items="allRestorants"&ndash;&gt;-->
<!--            &lt;!&ndash;                    :label="translate('Ресторан')"&ndash;&gt;-->
<!--            &lt;!&ndash;                    item-text="name"&ndash;&gt;-->
<!--            &lt;!&ndash;                    item-id="id"&ndash;&gt;-->
<!--            &lt;!&ndash;                    return-object&ndash;&gt;-->
<!--            &lt;!&ndash;                ></v-select>&ndash;&gt;-->
<!--          </v-col>-->
<!--          <br>-->
<!--          <br>-->
<!--          &lt;!&ndash;              <div class="pb-7 text-center">&ndash;&gt;-->
<!--          &lt;!&ndash;                <v-btn color="success" dark @click="chooseRestoran">&ndash;&gt;-->
<!--          &lt;!&ndash;                  {{ translate("Изабери ресторан") }}&ndash;&gt;-->
<!--          &lt;!&ndash;                </v-btn>&ndash;&gt;-->
<!--          &lt;!&ndash;              </div>&ndash;&gt;-->
<!--        </v-card>-->
<!--      </v-dialog>-->
<!--      <v-dialog v-model="dialogNewRestoran" persistent max-width="80%" class="p-2">-->
<!--        <v-card>-->
<!--          <v-card-title class="primary position-relative">-->
<!--              <span class="headline white&#45;&#45;text"-->
<!--              >{{ translate("Изаберите ресторан") }}</span-->
<!--              >-->
<!--          </v-card-title>-->
<!--          <br><br>-->
<!--          <v-col cols="12">-->
<!--            <v-row class="align-content-center justify-content-center">-->
<!--              <div class="card text-center" style="width: 33%;padding: 10px 5px;" v-for="res in chooseNewRestoran">-->
<!--                <div style="box-shadow: 0 1px 14px 2px #cfcfcf;border-radius: 7px;height: 230px;position: relative;">-->
<!--                  <img onerror="javascript:this.src='https://app.restorani.bitlab.host/gallery/no-image-restaurant.jpg'" @error="backupImage" style="cursor: pointer;border-top-left-radius: 10px;border-top-right-radius: 10px;" @click="selectNewRes(res)" class="card-img-top" width="100%" height="80%" :src="res.settings ?  conf.apiUrlBase + '/gallery/' + res.id + '/' + res.settings.header : backupImage" alt="Logo">-->
<!--                  <div class="card-body">-->
<!--                    <h5 class="card-title">{{ res.name }}</h5>-->
<!--                    <p style="font-size: 11px;" class="card-text mb-0 font-weight-bold">-->
<!--                      <span>-->
<!--                          <v-icon small class="mr-2">-->
<!--                            mdi-edit_location-->
<!--                          </v-icon>-->
<!--                        {{ res.city ? res.city.name : '' }}-->
<!--                      </span>-->
<!--                    </p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </v-row>-->
<!--          </v-col>-->
<!--          <br><br>-->
<!--        </v-card>-->
<!--      </v-dialog>-->
      <v-row justify="center">
<!--        <div v-if="restaurant"-->
<!--          class="hero"-->
<!--          :style="`background: url('https://app.restorani.bitlab.host/gallery/${restaurant.id}/medium/${restaurant.settings.header}'); background-size: cover; width:100vw; background-repeat: no-repeat;`"-->
<!--        >-->
<!--          <img-->
<!--            class="hero-logo"-->
<!--            :src="-->
<!--              conf.apiUrlBase +-->
<!--              '/gallery/' +-->
<!--              restaurant.id +-->
<!--              '/' +-->
<!--              restaurant.settings.logo-->
<!--            "-->
<!--          />-->
<!--        </div>-->
<!--        <div v-else-->
<!--             class="hero"-->
<!--             :style="`background: url('https://app.restorani.bitlab.host/gallery/${noUserRestoran.restaurant.id}/medium/${noUserRestoran.restaurant.settings.header}'); background-size: cover; width:100vw; background-repeat: no-repeat;`"-->
<!--        >-->
<!--          <img-->
<!--              class="hero-logo"-->
<!--              :src="-->
<!--              conf.apiUrlBase +-->
<!--              '/gallery/' +-->
<!--              noUserRestoran.restaurant.id +-->
<!--              '/' +-->
<!--              noUserRestoran.restaurant.settings.logo-->
<!--            "-->
<!--          />-->
<!--        </div>-->
        <v-col class="px-5">
          <v-card-title style="width: 95%;margin: 5px auto 0;padding: 20px 0;position: relative;background: #fff;border-radius: 12px;box-shadow: 0 0 3px 0 #cfcfcf;">
            <aside class="sidebar" style="width: 100%">
              <div class="sidebar__widget" style="display: flex;align-items: center">
                <a @click.prevent="cat = false" :style="cat == false ? 'color:#ff5211;font-weight: 700' : ''" style="padding: 1px 20px;display: block;text-transform: uppercase;font-weight: 400;font-size: 13px;color: #595959;">
                  <span>
                    {{ $t('hot_meals') }}
                  </span>
                </a>
                <a class="cat_link" @click.prevent="changeCategory(category)" :style="category.name == cate.name && cat == true ? 'color:#ff5211;font-weight: 700' : ''" style="padding: 11px 20px;display: block;text-transform: uppercase;font-weight: 400;font-size: 13px;color: #595959;" v-for="category in categories">
                  <span>
                    {{ translate(category.name ) }}
                  </span>
                </a>
              </div>
            </aside>
          </v-card-title>
          <div style="width:100%;margin:-30px auto 0;padding: 1px 9px;">
            <div style="width: 100%;padding: 0 10px">
              <template v-for="(item,index) in menues">
                <span style="margin-top: 0" class="d-block" :id="index" :ref="index"></span>
<!--                <div class="relative" style="width: 52%">-->
<!--                <div style="display: flex;align-items: center" class="mb-4">-->
<!--                  <a href="" @click.prevent="scrollMeBack(index)">-->
<!--                   <img class="mobile-image" style="width: 37px;margin-top: 2px;margin-right:20px" :src="conf.apiUrlBase + '/gallery/left.png'" align="center" alt="" />-->
<!--                  </a>-->
<!--  &lt;!&ndash;                v-html="translate(item.name)"&ndash;&gt;-->
<!--                  <p style="font-weight: 700;color:#333 !important;font-size: 20px"-->
<!--                    v-html="translate(item.name.split(' ')[0]) + ' ' + item.name.split(' ')[1] + '.'"-->
<!--                    align="center"-->
<!--                    class="px-2 mb-0"-->
<!--                  ></p>-->
<!--                  <a @click.prevent="scrollMeTo(index)" href="">-->
<!--&lt;!&ndash;                    <img class="mobile-image" style="width: 55px;margin-top: 2px;margin-left:20px" src="../assets/arrow-next.png" align="center" alt="" />&ndash;&gt;-->
<!--                    <img class="mobile-image" style="width: 37px;margin-top: 2px;margin-left:20px" :src="conf.apiUrlBase + '/gallery/right.png'" align="center" alt="" />-->
<!--                  </a>-->
<!--                </div>-->
<!--                  <p v-if="mealOrderdErrorMessage && item.date == errorDate" class="text-center font-weight-bold my-0 py-0" style="color: #c20606;font-size: 19px">Не можете отказати преузети оброк</p>-->
<!--                  <p style="position: absolute;top: 6px;right: -400px;">-->
<!--                    <v-tooltip bottom>-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                    </template>-->
<!--                  </v-tooltip>-->
<!--                  </p>-->
<!--                </div>-->
                <div style="display: flex;align-items: center" class="transfer-lista" v-if="$store.state.company&&$store.state.company.parent_id&&$store.state.user&&$store.state.user.type!=3&&$store.state.user.is_transferable">
                 <label v-if="!item.disabled" style="margin-bottom: 1px; padding-right: 7px;text-transform: uppercase;font-weight: 700" for="">{{ translate('Локација') }}: </label>
                  <v-select
                   v-if="!item.disabled"
                    v-model="item.location"
                    :items="locations"
                    filled
                    max-width="60%">
                  </v-select>
                </div>
                <v-data-table
                    :headers="headers"
                    :items="[item]"
                    :items-per-page="-1"
                    class="elevation-1"
                    fixed-header
                    hide-default-footer
                    :item-class="rowClass">
                  <template style="box-shadow: none !important;height: 1px !important;" v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
                    <div class="relative" style="box-shadow: none !important;">
                      <template style="box-shadow: none !important;" v-if="header.value == 'shift_one'">
                      </template>
                      <template style="box-shadow: none !important;" v-else-if="header.value == 'shift_two'">
                      </template>
                      <template style="box-shadow: none !important;" v-else-if="header.value == 'shift_three'">
                        <span style="font-size: 19px;font-weight: 900;color: #FB6D3B;border-bottom: 6px solid #FB6D3B;border-radius: 4px">
                        </span>
                      </template>
                      <template style="box-shadow: none !important;" v-else-if="header.value == 'korpa'">
                        <span style="font-size: 19px;font-weight: 900;color: #333;border-bottom: 6px solid #FB6D3B;border-radius: 4px"></span>
                      </template>
                      <template v-else>
<!--                        <img class="datumico" src="../assets/calendar.png" alt="" />-->
<!--                        <span class="datum">{{ translate(header.text) }}</span>-->
                      </template>
                    </div>
                  </template>
                  <template style="height: 1px !important;"
                      v-for="values in headers"
                      v-slot:[`item.${values.value}`]="{ item }"
                  >
<!--                    {{ translate(item[values.value]) }}-->
                  </template>
                  <template v-slot:top> </template>
                  <!-- CATEGORY -->
                  <template v-if="cat" v-slot:item.shift_one="{ item, i }">
                    <div style="display: flex;flex-wrap: wrap; padding-bottom: 80px">
                      <p v-if="cate.food == 0" class="py-12">Тренутно нема ништа у понуди...</p>
                      <template v-if="cate.food" v-for="(meal, mealCatIndex) in cate.food">
                        <div style="width: 25%;padding: 0 7px 7px;height: 250px;margin-bottom: 7px">
                          <v-card style="box-shadow: 0 0 4px 0 #cfcfcf;border-radius: 12px;width: 100%" :title="
                          !(item.disabled && item.test != meal.id + '_2-smena')? '' : translate('Истекао је рок за промјену или отказивање наруџбе')" class="my-1">
                          <v-card-title style="padding: 0">
                            <v-col style="padding: 0">
                              <v-col v-if="meal.image && meal.image.restaurant_id" md="12" class="text-center" style="cursor: pointer;position:relative;overflow:hidden;width: 100%;height: 140px;">
                                <img @click.prevent="addToBuyList(meal, item, 'shift_one', index)" style="cursor: pointer;border-top-left-radius: 12px;border-top-right-radius: 12px;position: absolute;left: 0;width: 100%;background-size: cover;" :key="meal.image_id"  class="card-img-top " width="100" :src="conf.apiUrlBase + '/gallery/' +  meal.image.restaurant_id + '/medium/' +  meal.image.name" alt="image">
                              </v-col>
                              <v-col v-if="! meal.image" md="12" class="text-center" style="cursor: pointer;position:relative;overflow:hidden;width: 100%;height: 140px;">
                                <v-icon @click.prevent="addToBuyList(meal, item, 'shift_one', index)" large>mdi-image</v-icon>
                              </v-col>
                              <div>
                                <v-col md="12" :align="'left'" @click.prevent="openImage(meal, item.name, item, 'shift_one', mealCatIndex)" >
                                  <span style="color: #888888;font-weight: 400;font-size: 13px;line-height: 17px;display: block">{{ translate(meal.name) }}</span>
                                </v-col>
                                <v-col md="12" style="align-items: center;display: flex;justify-content: center;">
                                  <v-btn @click.prevent="addToBuyList(meal, item, 'shift_one', index)" :style="buyingList[item.date] && buyingList[item.date].shifts.shift_one.find(m => m.id == meal.id) ? 'background:#06B04D !important' : ''" color="#FB6D3B" style="color: #fff;text-transform: capitalize;font-weight: 400;width: 100%">
                                    {{ translate('Наручи') }}
                                    <span v-if="meal.price" style="font-size: 14px;color: #232323;display: block;font-weight: 900;margin-bottom: -7px">
                                      {{ formatPrice(meal.price) }} {{ $store.state.company ? $store.state.company.restaurant.settings.price : '' }}
                                    </span>
                                  </v-btn>
                                </v-col>
                              </div>
                            </v-col>
                          </v-card-title>
                          </v-card>
                        </div>
                      </template>
                    </div>
                  </template>
                  <!-- END CATEGORY -->
                  <template v-else v-slot:item.shift_one="{ item, i }">
                    <div id="skrol" style="display: flex;flex-wrap: wrap; padding-bottom: 80px">
                      <template v-for="(meal, mealIndex) in item.shift_one">
                      <div style="width: 25%;padding: 0 7px 7px;height: 215px;margin-bottom: 7px">
                        <v-card style="box-shadow: 0 0 4px 0 #cfcfcf;border-radius: 12px;height: 100%" :title="!(item.disabled && item.test != meal.id + '_1-smena')? '' : translate('Истекао је рок за промјену или отказивање наруџбе')" class="my-1">
                        <v-card-title style="padding: 0">
                          <v-row>
                            <v-col>
                              <div @click.prevent="addToBuyList(meal, item, 'shift_one', index)" class="text-center" style="cursor: pointer;position:relative;overflow:hidden;width:100%;height: 120px;">
                                <img v-if="meal.image && meal.image.restaurant_id" style="cursor: pointer;border-top-left-radius: 12px;border-top-right-radius: 12px;position: absolute;left: 0;width: 100%;background-size: cover;" @click.prevent="openImage(meal, item.name, item, 'shift_one', mealIndex)" class="card-img-top " :src="conf.apiUrlBase + '/gallery/' +  meal.image.restaurant_id + '/' +  meal.image.name" alt="image">
                              </div>
                              <div @click.prevent="openImage(meal, item.name, item, 'shift_one', mealIndex)" style="padding: 0 7px;line-height: 18px;margin-top: 12px">
                                <span style="color: #888888;font-weight: 400;font-size: 13px;width: 100%;text-align: left;display: block">{{ textLimited(translate(meal.name)) }}</span>
                              </div>
                              <v-col md="12" style="align-items: center;display: flex;justify-content: center;position: absolute;bottom: -2px">
                                <v-btn v-if="restaurant" :disabled="
                                item.test !== meal.id + '_1-smena' &&
                                !!item.test || ! restaurant.settings.guest.rok_dana && meal.orders.find((order) => order.shift === '1-smena').quantity_payed > 0 || (dayTo > item.date && ! checkTime)"
                                  @click.prevent="addToBuyList(meal, item, 'shift_one', index)" color="#FB6D3B" :style="buyingList[item.date] && buyingList[item.date].shifts.shift_one.find(m => m.id == meal.id) ? 'background:#06B04D !important' : ''" style="width:100%;margin:0 auto;color: #fff;text-transform: capitalize;font-weight: 400;margin-top: auto">
                                   {{ $t('order') }}
                                  <span v-if="meal.price">({{ formatPrice(meal.price) }} {{ $store.state.company ? $store.state.company.restaurant.settings.price : ''}})</span>
                                </v-btn>
                              </v-col>
                            </v-col>
                          </v-row>
                        </v-card-title>
                      </v-card>
                      </div>
                    </template>
                    </div>
                  </template>
<!--                  <template v-slot:item.shift_two="{ item, i }">-->
<!--                    <template v-for="(meal, mealTwoIndex) in item.shift_two">-->
<!--                      <v-card style="box-shadow: 0 0 4px 0 #cfcfcf;border-radius: 12px" :title="-->
<!--                      !(item.disabled && item.test != meal.id + '_2-smena')? '' : translate('Истекао је рок за промјену или отказивање наруџбе')" class="my-4">-->
<!--                        &lt;!&ndash;  v-if="!(item.disabled && item.test != meal.id + '_1-smena')"&ndash;&gt;-->
<!--                        <v-card-title>-->
<!--                          <v-row>-->
<!--                            <v-col>-->
<!--                              <div>-->
<!--                                <v-row>-->
<!--                                  <v-col md="2" class="text-right">-->
<!--                                    <img @click.prevent="openImage(meal, item.name, item, 'shift_two', mealTwoIndex)" class="card-img-top " width="100" :src="conf.apiUrlBase + '/gallery/' +  meal.image.restaurant_id + '/' +  meal.image.name" alt="image">-->
<!--                                  </v-col>-->
<!--                                  <v-col-->
<!--                                      md="7"-->
<!--                                      :align="'left'">-->
<!--                                    <span v-if="meal.price" style="font-size: 16px;color: #232323;display: block;font-weight: 900;margin-bottom: -7px">-->
<!--                                      {{ formatPrice(meal.price) }} {{ $store.state.company ? $store.state.company.restaurant.settings.price : '' }}-->
<!--                                    </span>-->
<!--                                    <span style="color: #888888;font-weight: 400">{{ translate(meal.name) }}</span>-->
<!--                                  </v-col>-->
<!--                                  <v-col md="3" style="align-items: center;display: flex;justify-content: end">-->
<!--                                    <v-btn @click.prevent="addToBuyList(meal, item, 'shift_two', index)" color="#FB6D3B" :style="buyingList[item.date] && buyingList[item.date].shifts.shift_two.find(m => m.id == meal.id) ? 'background:#06B04D !important' : ''" style="color: #fff;text-transform: capitalize;font-weight: 400">-->
<!--                                       {{ translate('Наручи') }}-->
<!--                                    </v-btn>-->
<!--                                  </v-col>-->
<!--                                </v-row>-->
<!--                              </div>-->
<!--                            </v-col>-->
<!--                            <v-col cols="auto" v-if="meal.name !== 'Укупно'">-->
<!--                              <v-row-->
<!--                                  align="center"-->
<!--                                  justify="center"-->
<!--                                  class="fill-height ma-0"-->
<!--                              >-->
<!--                              </v-row>-->
<!--                            </v-col>-->
<!--                          </v-row>-->
<!--                        </v-card-title>-->
<!--                      </v-card>-->
<!--                    </template>-->
<!--                  </template>-->
<!--                  <template v-slot:item.shift_three="{ item, i }">-->
<!--                    <template v-for="meal in item.shift_three">-->
<!--                      <v-card-->
<!--                          class="my-4"-->
<!--                          :style="{backgroundColor:'#efefef',}"-->
<!--                      >-->
<!--                        &lt;!&ndash;                    v-if="!(item.disabled && item.test != meal.id + '_3-smena')"&ndash;&gt;-->
<!--                        <v-card-title :class="item.disabled ? 'disabled-meal' : ''">-->
<!--                          <v-row>-->
<!--                            <v-col>-->
<!--                              <div>-->
<!--                                <v-row>-->
<!--                                  <v-col-->
<!--                                      md="9"-->
<!--                                      :align="'left'"-->
<!--                                  >-->
<!--                                    <span v-if="meal.price" style="font-size: 14px;color: #73757c;display: block">{{ meal.price }} {{ $store.state.company ? $store.state.company.restaurant.settings.price : '' }}</span>-->
<!--                                    {{ meal.name }}-->
<!--                                  </v-col-->
<!--                                  >-->
<!--                                  <v-col md="3">-->
<!--                                    <v-row-->
<!--                                        align="center"-->
<!--                                        justify="center"-->
<!--                                        class="fill-height ma-0"-->
<!--                                    >-->
<!--                                      <v-text-field-->
<!--                                          :disabled="item.disabled || (dayTo > item.date && ! checkTime)"-->
<!--                                          v-if="meal.name !== 'Укупно'"-->
<!--                                          :label="translate('Количина')"-->
<!--                                          hide-details-->
<!--                                          dense-->
<!--                                          outlined-->
<!--                                          class="pb-2"-->
<!--                                          v-model="-->
<!--                                      meal.orders.find(-->
<!--                                        (i) => i.shift === '3-smena'-->
<!--                                      ).quantity-->
<!--                                    "-->
<!--                                          @change="-->
<!--                                      reserveMore($event, item, meal, '3-smena')-->
<!--                                    "-->
<!--                                          type="number"-->
<!--                                          min="0"-->
<!--                                      >-->
<!--                                      </v-text-field>-->
<!--                                      <span v-else>-->
<!--                                    {{-->
<!--                                        item.shift_three.reduce((sum, three) => {-->
<!--                                          if (-->
<!--                                              three.orders &&-->
<!--                                              three.orders.length-->
<!--                                          ) {-->
<!--                                            return (-->
<!--                                                sum +-->
<!--                                                three.orders.reduce(-->
<!--                                                    (total, order) => {-->
<!--                                                      if (order.shift === "3-smena") {-->
<!--                                                        return (-->
<!--                                                            total +-->
<!--                                                            parseInt(order.quantity)-->
<!--                                                        );-->
<!--                                                      } else {-->
<!--                                                        return total;-->
<!--                                                      }-->
<!--                                                    },-->
<!--                                                    0-->
<!--                                                )-->
<!--                                            );-->
<!--                                          } else {-->
<!--                                            return sum;-->
<!--                                          }-->
<!--                                        }, 0)-->
<!--                                      }}-->
<!--                                  </span>-->
<!--                                    </v-row>-->
<!--                                  </v-col>-->
<!--                                </v-row>-->
<!--                              </div>-->
<!--                            </v-col>-->
<!--                            <v-col cols="auto" v-if="meal.name !== 'Укупно'">-->
<!--                              <v-row-->
<!--                                  align="center"-->
<!--                                  justify="center"-->
<!--                                  class="fill-height ma-0"-->
<!--                              >-->
<!--                                <v-btn-->
<!--                                    text-->
<!--                                    color="primary"-->
<!--                                    small-->
<!--                                    class="px-2"-->
<!--                                    @click="-->
<!--                                dialog = true;-->
<!--                                selectedMeal = meal;-->
<!--                              "-->
<!--                                    icon-->
<!--                                >-->
<!--                                  <v-icon>mdi-food-fork-drink</v-icon>-->
<!--                                </v-btn>-->
<!--                              </v-row>-->
<!--                            </v-col>-->
<!--                          </v-row>-->
<!--                        </v-card-title>-->
<!--                      </v-card>-->
<!--                    </template>-->
<!--                  </template>-->
                  <template v-slot:item.korpa="{ item, index }">
                    <template>
                      <v-card id="skrol1" class="mb-1 w-100" style="border-radius: 12px">
                        <v-card-title>
                          <section class="header" style="min-height: 300px;width: 100%">
                            <div v-if="buyingList[item.date]">
<!--                              <label style="font-size: 11px;font-weight: 700;text-align: left" for="" v-if="buyingList[item.date].shifts.shift_one.length">Prva smena</label>-->
                              <div v-for="(old_meal,inx) in buyingList[item.date].shifts.shift_one" style="width: 100%;padding: 0;border-bottom: 2px solid rgba(231,231,231,0.56)" class="text-left w-100 py-2 border-bottom flex-wrap d-flex justify-content-between align-content-center" >
                                <div class="text-left pl-3" style="width: 100%;font-size: 13px;margin-bottom: 0;padding-bottom: 0;display: flex;flex-wrap: wrap">
                                  <span style="display: flex;justify-content: space-between;align-items: center;width: 70%">
                                    <span style="font-size: 11px;font-weight: 700;padding-right: 10px;line-height: 13px">
                                      <span class="text-left" style="font-size: 11px;font-weight: 700;display: block;text-align: left;">
                                        <span v-if="restaurant" style="font-weight: 900;font-size: 13px;line-height: 13px !important;">
                                          {{ old_meal.new_price != null ?  formatPrice(old_meal.new_price) : 0 }} {{  old_meal.new_price != null ? restaurant.settings.price : '' }}
                                        </span>
                                         <span v-else style="font-weight: 900;font-size: 13px;line-height: 13px !important;">
                                          {{ old_meal.new_price != null ?  formatPrice(old_meal.new_price) : 0 }} {{  old_meal.new_price != null ? noUserRestoran.restaurant.settings.price : '' }}
                                        </span>
                                      </span>
                                      <span style="color: #7E7A7A;font-size: 16px;padding-top: 3px;margin-top: 2px;display: block">{{ old_meal.name }}</span>
                                    </span>
                                  </span>
                                  <div style="display: flex;align-items: center;justify-content: center;width: 30%">
                                    <a @click.prevent="qty(old_meal, 'minus', item.date, 'shift_one')" style="width: 11%;display: flex;justify-content: center;align-items: center;margin-right: 20px">
                                      <span style="border-radius: 7px;padding: 0 12px 3px;color: #ff4c0b;background: rgba(251,109,59,0.43);font-size: 39px">-</span>
                                    </a>
                                    <span> {{ old_meal.qty }} </span>
                                    <a @click.prevent="qty( old_meal, 'plus', item.date, 'shift_one')" style="width: 11%;display: flex;justify-content: center;align-items: center;margin-left: 20px">
                                      <span style="border-radius: 7px;padding: 0 10px;font-size: 27px;color: #06B04D;background: rgba(95,227,148,0.6)">+</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
<!--                              <div v-if="restaurant.settings.guest.restoran_delivery" style="display: flex;justify-content: center;margin-top: 20px">-->
<!--                                <v-switch-->
<!--                                    @click="setDelivery(buyingList[item.date])"-->
<!--                                    v-model="buyingList[item.date].delivery_type"-->
<!--                                    label="Преузимање у ресторану"-->
<!--                                ></v-switch>-->
<!--                              </div>-->
<!--                              <label style="font-size: 11px;font-weight: 700;text-align: left" for="" v-if="buyingList[item.date].shifts.shift_two.length">Druga smena</label>-->
                              <div v-for="(old_meal,inx) in buyingList[item.date].shifts.shift_two" style="width: 100%;padding: 0;border-bottom: 2px solid rgba(231,231,231,0.56)" class="text-left w-100 py-2 border-bottom flex-wrap d-flex justify-content-between align-content-center" >
                                <div class="text-left pl-3" style="width: 100%;font-size: 13px;margin-bottom: 0;padding-bottom: 0;display: flex;flex-wrap: wrap">
                                  <span style="display: flex;justify-content: space-between;align-items: center;width: 70%">
                                    <span style="font-size: 11px;font-weight: 700;padding-right: 10px;line-height: 13px">
                                      <span class="text-left" style="font-size: 11px;font-weight: 700;display: block;text-align: left;">
                                        <span style="font-weight: 900;font-size: 13px;line-height: 13px !important;">
                                          {{ old_meal.new_price != null ?  old_meal.new_price : 0 }} {{  old_meal.new_price != null ? restaurant.settings.price : '' }}
                                        </span>
                                      </span>
                                      <span style="color: #7E7A7A;font-size: 13px;padding-top: 3px">{{ old_meal.name }}</span>
                                    </span>
                                  </span>
                                  <div style="display: flex;align-items: center;justify-content: center;width: 30%">
                                    <a @click.prevent="qty(old_meal, 'minus', item.date, 'shift_two')" style="width: 11%;display: flex;justify-content: center;align-items: center;margin-right: 20px">
                                      <span style="border-radius: 7px;padding: 0 12px 3px;color: #ff4c0b;background: rgba(251,109,59,0.43);font-size: 39px">-</span>
                                    </a>
                                    <span> {{ old_meal.qty }} </span>
                                    <a @click.prevent="qty( old_meal, 'plus', item.date, 'shift_two')" style="width: 11%;display: flex;justify-content: center;align-items: center;margin-left: 20px">
                                      <span style="border-radius: 7px;padding: 0 10px;font-size: 27px;color: #06B04D;background: rgba(95,227,148,0.6)">+</span>
                                    </a>
                                  </div>
                                </div>
<!--                                <a href="" @click.prevent="removeFromList(old_meal, item.date, inx)" style="width: 5%;text-decoration: none">-->
<!--                                  <v-icon style="color: #c20606">mdi-trash-can-outline</v-icon>-->
<!--                                </a>-->
                              </div>
                              <div v-if="$store.state.user">
<!--                                <div v-if="buyingList[item.date].shifts.shift_one.length && restaurant.settings.guest.restoran_delivery" class="mt-2" style="font-size: 11px; background: #fff;padding: 3px;align-items: center">-->
<!--                                  <div v-if="!buyingList[item.date].delivery_type">-->
<!--                                    <div class="text-left">-->
<!--                                      <img class="card-img-top " width="14" :src="conf.apiUrlBase + '/gallery/location.png'" alt="image">-->
<!--                                      <span style="color: #131313;font-weight: 700;font-size: 13px" class="pl-3">-->
<!--                                        Адреса доставе <span style="color:red">*</span>-->
<!--                                      </span>-->
<!--                                    </div>-->
<!--                                    <div style="display: flex;flex-wrap: wrap;align-items: center">-->
<!--                                    <div style="width: 85%;font-size: 13px">-->
<!--                                      <select @change="changeAddress(set_user_location[item.date], $store.state.user.id, item.date)" v-model="set_user_location[item.date]" style="width: 100%;padding: 0 15px;border-bottom:1px solid #cfcfcf;cursor: pointer;color: #656565">-->
<!--                                        <option value="0">Додај адресу</option>-->
<!--                                        <option v-if="address.address != ''" v-for="address in allUserLocation" :value="address">{{ address.address }}</option>-->
<!--                                      </select>-->
<!--                                    </div>-->
<!--                                    <div style="width: 15%">-->
<!--                                      <v-icon>mdi-location-radius</v-icon>-->
<!--                                      <a @click.prevent="current_location = true, locatorButtonPressed()" style="padding: 4px 15px;font-weight: 700;text-decoration: none;font-size: 12px" href="">-->
<!--                                        <img class="card-img-top " width="14" :src="conf.apiUrlBase + '/gallery/plus.png'" alt="image">-->
<!--                                      </a>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                                <div v-if="buyingList[item.date].shifts.shift_one.length && restaurant.settings.guest.restoran_delivery" class="mt-2" style="font-size: 12px; background: #fff;padding: 3px;align-items: center">-->
<!--                                  <div class="text-left">-->
<!--                                    <img class="card-img-top " width="14" :src="conf.apiUrlBase + '/gallery/phone.png'" alt="image">-->
<!--                                    <span style="color: #131313;font-size: 13px;font-weight: 700" class="pl-3">-->
<!--                                      Број телефона-->
<!--                                    </span>-->
<!--                                  </div>-->
<!--                                  <div style="display: flex;align-items: center">-->
<!--                                    <input v-if="$store.state.user" class="ml-3" style="border-bottom: 1px solid #cfcfcf;width: 85%;color: #131313;font-size: 13px" v-model="$store.state.user.phone" placeholder="Унесите ваш број телефона">-->
<!--                                    <input v-else class="ml-3" style="border-bottom: 1px solid #cfcfcf;width: 85%;color: #131313;font-size: 13px" placeholder="Унесите ваш број телефона">-->
<!--                                    <div style="width: 15%">-->
<!--  &lt;!&ndash;                                    href="/#/profil"&ndash;&gt;-->
<!--                                      <a @click.prevent="openPhonePopup"-->
<!--                                         style="padding: 4px 15px;font-weight: 700;text-decoration: none;font-size: 12px">-->
<!--                                        <img class="card-img-top " width="14" :src="conf.apiUrlBase + '/gallery/pencil.png'" alt="image">-->
<!--                                      </a>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                                <div v-if="buyingList[item.date].shifts.shift_one.length > 0 && restaurant.settings.guest.restoran_delivery" style="padding: 3px;align-items: center;font-size: 12px" class="position-relative mt-2">-->
<!--                                <div class="text-left">-->
<!--                                  <img class="card-img-top " width="14" :src="conf.apiUrlBase + '/gallery/time.png'" alt="image">-->
<!--                                  <span class="pl-3" style="margin-top: -3px;font-size: 13px;font-weight: 700">-->
<!--                                    Када желите да Вам доставимо поруџбину <span style="color:red">*</span> :-->
<!--                                  </span>-->
<!--                                </div>-->
<!--                                <p style="font-size:10px;font-weight: 400;text-align: center;margin-bottom: -28px;color: #131313">-->
<!--                                  {{ translate(item.name.split(' ')[0]) + ' ' + translate(item.name.split(' ')[1]) }}.-->
<!--                                </p>-->
<!--                                <v-row class="" style="justify-content: center !important;">-->
<!--                                  <v-col cols="1"><img class="card-img-top " width="14" :src="conf.apiUrlBase + '/gallery/time1.png'" alt="image"></v-col>-->
<!--                                  <v-col cols="3">-->
<!--                                    <v-select-->
<!--                                        :items="setHoures"-->
<!--                                        item-value="val"-->
<!--                                        v-model="delivery_from[item.date]"-->
<!--                                        label="hh"-->
<!--                                        @change="setDeliveryTime(item.date)">-->
<!--                                    </v-select>-->
<!--                                  </v-col>-->
<!--                                  :-->
<!--                                  <v-col cols="3">-->
<!--                                    <v-select-->
<!--                                        :items="setMinutes"-->
<!--                                        @change="setDeliveryTime(item.date)"-->
<!--                                        item-value="val"-->
<!--                                        v-model="delivery_to[item.date]"-->
<!--                                        label="mm">-->
<!--                                    </v-select>-->
<!--                                  </v-col>-->
<!--                                </v-row>-->
<!--                              </div>-->
                              </div>
<!--                              <div v-if="buyingList[item.date].delivery_type" style="font-size: 14px"><span class="font-weight-bold">Ресторан Грош</span>, Краља Петра 78, Бања Лука</div>-->
                              <div v-if="buyingList[item.date].shifts.shift_one.length > 0" style="width: 100%" class="text-center mt-2">
                                <button class="px-8 py-1" style="color: #fff;border-radius: 5px;background: #FB6D3B;font-size: 12px;width: 100%" @click.prevent="sentOrder(buyingList[item.date], item.date, index)">
                                  {{ $t('confirm') }}
                                  <span v-if="total[item.date]">{{ formatPrice(total[item.date]) }} {{ $store.state.company ? $store.state.company.restaurant.settings.price : '' }}</span>
                                </button>
                              </div>
                            </div>
                            <p v-else-if="! allOrderOrders[item.date] && buyList.length == 0" style="font-size: 14px; text-align: center;font-weight: 300">
                              {{ $t('you_have_not_selected_any_item') }} <br>
                              {{ $t('when_selected_your_order_appears_here') }}
                            </p>
                            <p v-if="buyingList[item.date] && buyingList[item.date].shifts.shift_one.length == 0" style="font-size: 14px;text-align: center;font-weight: 300">
                              {{ $t('you_have_not_selected_any_item') }} <br>
                              {{ $t('when_selected_your_order_appears_here') }}
                            </p>
                          </section>
                        </v-card-title>
                      </v-card>
                    </template>
                  </template>
                </v-data-table>
                <!-- <span v-html="item.name"></span> -->
              </template>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>

    <v-snackbar
      v-model="snackbar.status"
      :timeout="2000"
      top
      rounded="pill"
      color="#C20606"
    >
      {{ translate(snackbar.text) }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.status = false"
        >
          {{ translate("Затвори") }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialog1" max-width="500px" class="p-2">
      <v-card>
        <v-card-title class="error position-relative">
          <span class="headline white--text">{{ $t('time_of_delivery') }}</span>
          <v-btn color="primary" text style="position: absolute;top: 5px;color: white !important;right: 5px" @click="dialog1 = false">
            {{ translate("X") }}
          </v-btn>
        </v-card-title>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-select
                  :items="setHoures"
                  item-value="val"
                  v-model="delivery_from"
                  label="Сати"
                  outlined
              >
             </v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                  :items="setMinutes"
                  item-value="val"
                  v-model="delivery_to"
                  :label="$t('minutes')"
                  outlined
              >
              </v-select>
            </v-col>
          </v-row>
          <div class="pb-7 text-center">
            <v-btn color="success" dark @click="saveTime">
              {{ $t("save") }}
            </v-btn>
          </div>
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="60%" scrollable>
      <v-card>
        <v-card-text>
          <v-card elevation="0">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon x-large>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mb-4">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-img
                    :src="
                      selectedMeal.image
                        ? conf.apiUrlBase +
                          '/gallery/' +
                          restaurant.id +
                          '/medium/' +
                          selectedMeal.image.name
                        : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" sm="6" class="ml-sm-n16 my-sm-2 mt-sm-16">
                  <v-row align="end" class="fill-height">
                    <v-card elevation="0" class="pa-5">
                      <v-card-title
                        ><h3>
                          {{ translate(selectedMeal.name) }}
                        </h3></v-card-title
                      >
                      <v-card-text class="mb-4">
                        <v-row no-gutters>
                          <template v-for="(eat, i) in selectedMeal.foods">
                            <v-col cols="12" class="font-weight-bold pt-2">
                              <v-row no-gutters justify="space-between">
                                <h3>{{ translate(eat.name) }}</h3>
                              </v-row>
                            </v-col>
                            <v-col cols="12">{{
                              translate(eat.description)
                            }}</v-col>
                          </template>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "../plugins/axios";
import conf from "../config";
import moment from "moment";
import { latLng, Icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LIconDefault } from "vue2-leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  data() {
    return {
      order_success: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '',
      zoom: 16,
      center: [44.78624721128064, 17.216139767293512],
      markerLatLng: [44.78624721128064, 17.216139767293512],
      conf: conf,
      loading: false,
      current_location: false,
      edit_user_location: false,
      dialogRestoran: false,
      openTimer: {},
      location: [0],
      locations: [],
      selectedDate: {
        name: "rucak",
        date: new Date().toISOString().substr(0, 10),
        shift: {
          text: "1-smena",
          key: "shift_one",
          value: 1,
        },
      },
      lockedAll: false,
      meals: [],
      menues: [],
      snackbar: {
        status: false,
        text: null,
      },
      dialog: false,
      dialog1: false,
      selectedMeal: [],
      rok_sati: false,
      dayTo:'',
      mealOrderdErrorMessage: '',
      errorDate: '',
      time: '',
      sati: '',
      menuTime: [],
      delivery_time: {},
      total: {},
      openTime: '',
      delivery_from: {},
      delivery_to: {},
      totalTime: {},
      index: '',
      user_location: '',
      locationForUser: '',
      noLocation: false,
      restoran_delivery: false,
      allRestorants: '',
      newUserLocation: {
        entity_id: this.$store.state.user ? this.$store.state.user.id : '',
        coordinates: {
          longitude: '',
          latitude: ''
        },
        entity_type: 3,
        address: ''
      },
      buyList: [],
      listItems: {
        user_id: '',
        date: '',
        type: '',
        shifts: {
          shift_one: [],
          shift_two: [],
          shift_three: [],
        },
        delivery_time: '',
        location: {
          longitude: '',
          latitude: '',
          address: '',
          id: ''
        },
      },
      guestErrorMessage: '',
      guestErrorMessagePopup: false,
      guestErrorMessageHeader: '',
      deliveryRestoran: '',
      allOrderOrders: [],
      chooseNewRestoran: '',
      dialogNewRestoran: false,
      buttonIndex: {},
      selectedUserLocation: '',
      allUserLocation: '',
      current_user_location: '',
      new_user_location: {
        address: '',
        latitude: '',
        longitude: '',
      },
      set_user_location: '',
      showSelect: false,
      buyingList: {},
      categories: '',
      cat: false,
      cate: '',
      countries: '',
      country: '',
      cities: [{name : 'Изаберите државу'}],
      city: '',
      phone_popup: false,
      image_popup: false,
      mealDesc: '',
      mealDate: '',
      buyListItem: '',
      buyListShift: '',
      buyListIndex: '',
      buyListMeal: '',
      noUserRestoran: '',
      editUserLocation: '',
      changedUserLocation: '',
      take_order_in_restoran: true,
      edit_city: '',
      edit_country: '',
    };
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIconDefault
  },
  mounted() {
    setTimeout(() => {
      document.getElementById('skrol1').style.marginTop = 37 + "px"
      document.getElementById('skrol').style.paddingTop = 32 + "px"

      let a = false
      this.menues.filter((menu, index) => {
        if (a) {
          return;
        }
        let brojSati = this.restaurant.settings.guest.broj_sati
        let rokSati = this.restaurant.settings.guest.rok_sati
        let rokDana = this.restaurant.settings.guest.rok_dana
        let dan = this.restaurant.settings.guest.dan

        let sati = brojSati <= 9 ? + '0' + brojSati : brojSati

        if (rokSati) {
          let date = this.getDate(dan, sati)
          this.dayTo = moment(date).format('YYYY-MM-DD');

          if (menu.date == moment(date).format('YYYY-MM-DD')) {
            a = true
            const dokle = document.getElementById(index);
            dokle.scrollIntoView({
              block: 'start',
              behavior: "smooth",
              inline: "nearest"
            });
          }
          return;
        }
        if (rokDana) {
          if (menu.disabled == false) {
            this.dayTo = menu.date
            a = true
            const dokle = document.getElementById(index);
            dokle.scrollIntoView({
              block: 'start',
              behavior: "smooth",
              inline: "nearest"
            });
          }
        }

        if (menu.disabled == false) {
          this.dayTo = menu.date
          a = true
          const dokle = document.getElementById(index);
          dokle.scrollIntoView({
            block: 'start',
            behavior: "smooth",
            inline: "nearest"
          });
        }
      })
    }, 1200)
  },

  created() {
    // this.noUserRestoran = JSON.parse(localStorage.getItem('guest'))
    // this.$root.$on('setRestoranId', (data) => {
    //   this.noUserRestoran = this.$route.params.foo
    // })
    this.getMenues();
    this.getUserLocation()
    this.locatorButtonPressed()
    // this.getEntityCoordinates()
    // this.getAllRestorans()
    this.selectRes()
    this.getCategories()
    this.countryCity()
    // this.oldOrders()
    // this.$root.$on('openRestorants', (data) => {
    //   this.dialogRestoran = true
    // })
  },
  methods: {
    textLimited(text){
      if ( text.length > 25 ) {
        return text.substring(0,25) + '...'
      } else {
        return text
      }
    },
    setDelivery() {
       this.take_order_in_restoran = !this.take_order_in_restoran
       this.$forceUpdate()
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    changePhone() {
      axios.post('/user/change-user-phone', {'user': this.$store.state.user}).then(({data}) => {
        if (data) {
          this.phone_popup = false
        }
      }).catch((error) => {
        if (error.response.data.status == "failed" && error.response.data.message == "Nema korisnika") {
          this.phone_popup = false
          this.guestErrorMessagePopup = true
          this.guestErrorMessage = error.response.data.message
          this.guestErrorMessageHeader = 'Дошло је до грешке'
        }
      });
    },
    openImage(meal, date, item, shift, index) {
      this.mealDesc = meal
      this.mealDate = date
      this.buyListShift = shift
      this.buyListItem = item
      this.buyListIndex = index
      if (this.buyingList[item.date] && this.buyingList[item.date].shifts[shift][index]) {
        meal.qty = this.buyingList[item.date].shifts[shift][index].qty
      } else {
        meal.qty = 0
      }
      this.buyListMeal = meal
      this.image_popup = true
    },
    openPhonePopup() {
      this.phone_popup = true
    },
    closeWindow() {
     this.order_success = false
    },
    getCitiesByCountry() {
      this.cities = [{name : 'Изаберите државу'}]
      this.countries.filter(country => {
        if (country.id == this.country) {
          this.cities = country.city
        }
      })
    },
    countryCity() {
      axios.get('/restaurants/countries-all').then(({data}) => {
        this.countries = data
      })
    },
    changeCategory(cate) {
      this.cat = true
      this.cate = cate
    },
    getCategories() {
      let config = {
        headers: {
          restaurant: this.$store.state.company.restaurant.id,
        }
      }

      axios.get("/categories-guest" , config).then((res) => {
        res.data.filter(item => {
          if (item.food.length) {
            item.food.filter(fo => {
              fo.food_id = fo.id
            })
          }
        })
        this.categories = res.data;
      });
    },
    changeAddress(location, user, date) {
      let data = {}
      data['user_id'] = user
      data['date'] = date
      data['location_id'] = location.id
      console.log(data)
      this.buyingList[date].location.id = location.id
      this.buyingList[date].location.address = location.address
      axios.post('/orders/edit-location', data).then(({data}) => {
        console.log(data)
      })
      this.$forceUpdate()
    },
    addUserLocation() {
      let data = {}
      data['user_id'] = this.$store.state.user.id
      data['new_user_location'] = this.new_user_location
      data['city_id'] = this.city
      axios.post('/user/add-new-user-location', data).then(({data}) => {
        this.allUserLocation = data.data
        this.new_user_location.address = ''
        this.new_user_location.latitude = ''
        this.new_user_location.longitude = ''
        this.city = ''
        this.country = ''
        this.$forceUpdate()
      })
    },
    editLocation(address) {
      this.edit_user_location = true
      this.editUserLocation = address
      this.edit_city = address.city_id
      this.edit_country = address.country_id
      this.changedUserLocation = address.address
      // axios.post('/user/edit-user-location', {
      //   'address': address
      // }).then(({data}) => {
      //   this.allUserLocation = data.data
      //   this.$forceUpdate()
      // })
    },
    setEditLocation() {
      let data = {}
      data['address'] = this.changedUserLocation
      data['id'] = this.editUserLocation.id
      data['city_id'] = this.edit_city
      axios.post('/user/edit-user-location', data).then(({data}) => {
        if (data) {
          this.edit_user_location = false
          this.allUserLocation.filter(location => {
            if (location.id == data.data.id) {
              location.address = data.data.address
              location.city_id = data.data.city_id
            }
          })
        }
        this.$forceUpdate()
      })
    },
    deleteLocation(address) {
      axios.post('/user/delete-location', address).then(({data}) => {
        this.allUserLocation = data.data
        this.$forceUpdate()
      })
    },
    removeFromList(list, date, shift) {
      console.log(shift)
      let indexOfObject = this.buyingList[date].shifts[shift].findIndex(object => {
        return object.meal_id === list.meal_id;
      });
      this.$delete(this.buyingList[date].shifts[shift], date, 1)
      this.buyingList[date].shifts[shift].splice(indexOfObject, 1);
      this.total[date] = 0
      for (let i in this.buyingList[date].shifts) {
        this.buyingList[date].shifts[i].filter(it => {
          this.total[date] += it.qty * Number(it.price)
        })
      }

      if (list.order_id) {
        axios.post('/orders/remove-from-order-list', list).then(({data}) => {
          console.log(data)
        })
      }
      this.$forceUpdate()
    },
    groupBy(array, key){
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]){
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    },
    oldOrders() {
      axios.post('/orders/guest-old-orders', {
        'user_id': this.$store.state.user ? this.$store.state.user.id : ''
      }).then(({data}) => {
        let podaci = {}
        data.filter(item => {
          podaci[item.date] = []
          this.delivery_from[item.date] = item.delivery_time
          this.delivery_to[item.date] = item.delivery_time
          this.buyingList[item.date] = {}
          this.buyingList[item.date]['guest_order'] = item.id
          this.buyingList[item.date]['user_id'] = item.user_id
          this.buyingList[item.date]['date'] = item.date
          this.buyingList[item.date]['type'] = 'ручак'
          this.buyingList[item.date]['delivery_type'] = item.delivery_type
          this.buyingList[item.date]['shifts'] = {}
          this.buyingList[item.date]['shifts']['shift_one'] = []
          this.buyingList[item.date]['shifts']['shift_two'] = []
          this.buyingList[item.date]['shifts']['shift_three'] = []
          this.buyingList[item.date]['delivery_time'] = item.delivery_time
          this.buyingList[item.date]['location'] = {}
          this.buyingList[item.date]['location']['longitude'] = ''
          this.buyingList[item.date]['location']['latitude'] = ''
          this.buyingList[item.date]['location']['address'] = ''
          this.buyingList[item.date]['location']['id'] = ''
        })

        data.filter(item => {
          this.total[item.date] = 0
          if(item.order_guest_transactions) {
            item.order_guest_transactions.filter(item1 => {
              this.total[item.date] += item1.quantity * Number(item1.price)
              podaci[item.date].push(item1)
              if (item1.meal) {
                item1.meal.guest_order = item.id
                item1.meal.order_id = item1.order_id
                item1.meal.id = item1.meal_id
                item1.meal.qty = item1.quantity
                item1.meal.new_price = item1.meal.price ? item1.meal.price : 0
              }
              if (item1.food) {
                item1.food.guest_order = item.id
                item1.food.order_id = item1.order_id
                item1.food.id = item1.food_id
                item1.food.qty = item1.quantity
                item1.food.new_price = item1.food.price ? item1.food.price : 0
              }
              if (item.delivery_time != null) {
                this.delivery_from[item.date] = Number(item.delivery_time.split(':')[0])
                this.delivery_to[item.date] = Number(item.delivery_time.split(':')[1])
              }

              // this.set_user_location[item.date]
              this.buyingList[item.date].location.id = this.set_user_location[item.date] ? this.set_user_location[item.date].id : ''
              this.buyingList[item.date].location.address = this.set_user_location[item.date] ? this.set_user_location[item.date].address : ''
              this.buyingList[item.date].phone = this.$store.state.user.phone
              if (item1.meal) {
                this.buyingList[item.date]['shifts'][item.shift == 1 ? 'shift_one' : item.shift == 2 ? 'shift_two' : 'shift_three'].push(item1.meal)
              }
              if (item1.food) {
                this.buyingList[item.date]['shifts'][item.shift == 1 ? 'shift_one' : item.shift == 2 ? 'shift_two' : 'shift_three'].push(item1.food)
              }
            })
          }
        })
        this.allOrderOrders = podaci
      })
     this.$forceUpdate()
    },
    qty(meal, type, date, shift) {
      meal.price = Number(meal.price)
      if (type == 'minus') {
        if (meal.qty == 0 || meal.quantity == 0) {
          meal.quantity = 0
          meal.qty = 0
        } else if(meal.qty == -1 || meal.quantity == -1) {
          meal.quantity = 0
          meal.qty = 0
          this.removeFromList(meal, date, shift)
        }  else {
          meal.quantity = meal.quantity - 1
          meal.qty = meal.qty - 1
        }
        if (meal.qty == 0) {
          this.removeFromList(meal, date, shift)
        }
        // meal.new_price = meal.qty * Number(meal.price)
      }
      if (type == 'plus') {
        console.log(meal)
        if (meal.qty == 0 || meal.quantity == 0) {
          meal.quantity = meal.quantity + 1
          meal.qty = meal.qty + 1
        } else if(meal.qty == -1 || meal.quantity == -1) {
          meal.quantity = 0
          meal.qty = 0
        } else {
          meal.quantity = meal.quantity + 1
          meal.qty = meal.qty + 1
        }
        // meal.new_price = meal.qty * Number(meal.price)
      }

      if(this.buyingList[date]) {
        this.total[date] = 0
        for (let i in this.buyingList[date].shifts)
          this.buyingList[date].shifts[i].filter(it => {
            this.total[date] += it.qty * Number(it.price)
          })
      }

      this.$forceUpdate()
    },
    setDeliveryTime(index) {
      this.buyingList[index].delivery_time = this.delivery_from[index] + ':' + this.delivery_to[index]
      this.$forceUpdate()
    },
    addToBuyList(meal, item, shift, index) {
      this.buttonIndex[item.date] = true
      if (this.buyingList[item.date] && this.buyingList[item.date].shifts[shift].find((i,index) => [i.id ? i.id : i.meal_id].includes(meal.id))) {
        this.buyingList[item.date].shifts[shift].filter((bu, index1) => {
          if (bu.id && bu.id == meal.id) {
            if (this.mealDesc.qty) {
              this.buyingList[item.date].shifts[shift][index1].qty = this.mealDesc.qty
            } else {
              this.buyingList[item.date].shifts[shift][index1].qty++
            }
          } else if (bu.meal_id == meal.id) {
            if (this.mealDesc.qty) {
              this.buyingList[item.date].shifts[shift][index1].qty = this.mealDesc.qty
            } else {
              this.buyingList[item.date].shifts[shift][index1].qty++
            }
            // this.buyingList[item.date].shifts[shift][index1].qty++
          }
        })
        this.total[item.date] = 0
        this.buyingList[item.date].shifts.shift_one.filter(it => {
          this.total[item.date] += it.qty * Number(it.price)
        })
        this.buyingList[item.date].shifts.shift_two.filter(it => {
          this.total[item.date] += it.qty * Number(it.price)
        })
        this.$forceUpdate()
      } else {
        let data = {}
        data['user_id'] = this.$store.state.user ? this.$store.state.user.id : ''
        data['date'] = item.date
        data['type'] = 'ручак'
        data['qty'] = 1
        data['name'] = meal.name
        data['id'] = meal.id
        data['price'] = meal.price
        data['shifts'] = {}
        data['shifts'][shift] = {}
        data['shifts'][shift]['meals'] = []
        data['shifts'][shift]['meals'].push({
          'meal': meal,
          'qty': 1
        })

        this.listItems.user_id = this.$store.state.user ? this.$store.state.user.id : ''
        this.listItems.date = item.date
        this.listItems.type = 'ручак'
        this.listItems.location.address = this.restaurant.address
        this.listItems.location.id = this.newUserLocation.entity_type
        this.listItems.location.longitude = ''
        this.listItems.location.latitude = ''
        this.listItems.delivery_time = moment().format('YYYY-MM-DD')

        if (! this.buyingList[item.date]) {
          this.buyingList[item.date] = {}
          this.buyingList[item.date]['date'] = item.date
          this.buyingList[item.date]['type'] = "ручак"
          this.buyingList[item.date]['delivery_time'] = moment().format('HH:mm')
          this.buyingList[item.date]['location'] = {}
          this.buyingList[item.date]['location']['address'] = this.restaurant.address
          this.buyingList[item.date]['location']['id'] = this.newUserLocation.entity_type
          this.buyingList[item.date]['location']['longitude'] = ''
          this.buyingList[item.date]['location']['latitude'] = ''
          this.buyingList[item.date]['user_id'] = this.$store.state.user ? this.$store.state.user.id : ''
          this.buyingList[item.date]['shifts'] = {}
          this.buyingList[item.date]['shifts']['shift_one'] = []
          this.buyingList[item.date]['shifts']['shift_two'] = []
          this.buyingList[item.date]['shifts']['shift_three'] = []
          this.buyingList[item.date]['delivery_type'] = true

          this.buyingList[item.date]['shifts'][shift].push({
            'meal_id': meal.id,
            'food_id': meal.food_id ? meal.food_id : '',
            'name': meal.name,
            'price': Number(meal.price),
            'qty': meal.qty ? meal.qty : 1,
            'new_price': Number(meal.price)
          })
        } else {
          this.buyingList[item.date].shifts[shift].push({
            'meal_id': meal.id,
            'food_id': meal.food_id ? meal.food_id : '',
            'name': meal.name,
            'price': Number(meal.price),
            'qty': meal.qty ? meal.qty : 1,
            'new_price': Number(meal.price)
          })
        }
        this.total[item.date] = 0
        for (let i in this.buyingList[item.date].shifts) {
          this.buyingList[item.date].shifts[i].filter(it => {
              this.total[item.date] += it.qty * Number(it.price)
          })
        }
        this.$forceUpdate()
      }
      this.image_popup = false
    },
    sentOldOrder(oldOrders, date, index) {
      oldOrders.filter(itm => {
        itm.order_id = itm.id
      })
      let data = {}
      data['user_id'] = this.$store.state.user.id
      data['date'] = date
      data['type'] = 'ручак'
      data['shifts'] = {}
      data['shifts']['shift_one'] = oldOrders
      data['shifts']['shift_two'] = {}
      data['shifts']['shift_three'] = {}
      data['delivery_time'] = this.delivery_from[date] + ':' + this.delivery_to[date]
      data['location'] = {}
      data['location']['longitude'] = this.user_location.coordinates ? this.user_location.coordinates.longitude : ''
      data['location']['latitude'] = this.user_location.coordinates ? this.user_location.coordinates.latitude : ''
      data['location']['address'] = this.user_location.address ? this.user_location.address : ''
      data['location']['id'] = this.user_location.id
      this.buyList.push(data)
      if (data['delivery_time'] == ':') {
        this.guestErrorMessagePopup = true
        this.guestErrorMessage = 'Молимо Вас да изаберете време доставе...'
        this.guestErrorMessageHeader = 'Изаберите време доставе...'
        return this.guestErrorMessage
      }
    },
    sentOrder(order, date, index) {
      this.guestErrorMessage = ''
      this.guestErrorMessagePopup = false
      axios.post('/orders/guest-orders', this.buyingList[date]).then(({data}) => {
        if(data) {
          this.order_success = true
          setTimeout(() => {
            this.order_success = false
          }, 3000)
          // this.oldOrders()
          this.buyingList[date].shifts.shift_one = []
          this.payReserved(data)
        }
      })
    },
    payReserved(reservedMeal) {
      axios.post('/orders/pay-guest', {
        state: 'payed',
        orders: reservedMeal,
      }).then(res => {
        this.payment.text = 'Успјешно испоручено'
        this.payment.color = 'success'
      }).catch(err => {
        this.payment.text = 'Грешка, молим Вас покушајте поново'
        this.payment.color = 'error'
      }).finally(() => {
        this.payment.status = true
        this.$forceUpdate()
      })
    },
    rowClass(item) {
      var rowClass = 'myclass'
      return rowClass;
    },
    backupImage() {
      return conf.apiUrlBase + '/gallery/no-image-restaurant.jpg'
    },
    getEntityCoordinates() {
      let data = {}
      data['entity_id'] = this.restaurant.id
      data['entity_type'] = 1
      axios.post('/get-entity-coordinates', data).then(({data}) => {
        console.log(data)
      })
    },
    addNewLocation() {
      axios.post('/user/add-user-location', this.newUserLocation).then(({data}) => {
        if(data.status == 'success') {
          this.deliveryRestoran = data
          this.newUserLocation.entity_id = data.entity_id
          this.newUserLocation.coordinates.longitude = ''
          this.newUserLocation.coordinates.latitude = ''
          this.newUserLocation.entity_type = data.entity_type
          this.newUserLocation.address = data.address
        }
      })
    },
    selectRes(res = null) {
      if (res == null && this.restaurant && this.$store.state.user.type == 'guest') {
        let data = {};
        data['user_id'] = this.$store.state.user.id
        data['restoran_id'] = this.restaurant.id
        axios.post('/choose-restaurant', data).then(({data}) => {
          if(data.status == "success") {
            this.deliveryRestoran = data.data
            // this.noUserRestoran = data.data
          }
        })
      } else {
        if (res && this.$store.state.user.type == 'guest') {
          let data = {};
          data['user_id'] = this.$store.state.user.id
          data['restoran_id'] = res.id
          axios.post('/choose-restaurant', data).then(({data}) => {
            if(data.status == 'success') {
              this.dialogRestoran = false
              // this.noUserRestoran = data.data
            }
          })
        }
      }

    },
    dialogRestoranClose() {
      this.dialogRestoran = false;
    },
    getAllRestorans() {
      axios.get('/all-restaurants').then(({data}) => {
        this.allRestorants = data.data
      })
    },
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
          position => {
            console.log(position.coords.latitude);
            console.log(position.coords.longitude);
          },
          error => {
            console.log(error.message);
          },
      )
    },
    getUserLocation() {
      this.set_user_location = {}
      axios.post('/user/get-user-location', {
        'user_id': this.$store.state.user ? this.$store.state.user.id : ''
      }).then(({data}) => {
        this.set_user_location = data.current_location
        this.current_user_location = data.current_location
        this.$delete(data.data, "data.data");
        let podaci = []
        data.data.filter(item => {
            podaci.push({
              'id': item.id,
              'address': item.address,
              'entity_id': item.entity_id,
              'city_id': item.city_id,
              'country_id': item.city.country_id
            })
        })
        this.allUserLocation = podaci
        this.newUserLocation.entity_id = data.data.entity_id
        this.newUserLocation.coordinates.longitude = ''
        this.newUserLocation.coordinates.latitude = ''
        this.newUserLocation.entity_type = data.data.entity_type
        this.newUserLocation.address = data.data.address
        this.current_location = false
        // this.dialogRestoran = true
      }).catch((error) => {
        if (error.response.data.status == "failed" && error.response.data.message == "Korisnik nema lokaciju") {
          this.current_location = true
          this.dialogRestoran = false
        }
      });

    },
    saveTime() {
      this.totalTime[this.index] = this.delivery_from + ':' + this.delivery_to
      this.dialog1 = false
    },
    openDialogTime(index) {
      this.dialog1 = true
      this.index = index
    },
    getDate(setLimitDay, setLimitHour){
      let orderDay;
      let limitDay = moment().day(setLimitDay); // cetvrtak  ( Thursday )
      let currentDay = limitDay.diff(moment().day(moment().format('dddd')), 'days');

      // srediti ako je vikend, ako ne postoji subota prebaci u ponedeljak
      let limitTime = (currentDay <= 0 && moment().hour() >= setLimitHour) ? false : true;
      let allowTime = (currentDay >= 0 && limitTime === true) ? true : false;
      // trenutni dan manji od setovanog dana
      if (moment().format('E') < setLimitDay) {
        orderDay = moment().startOf('isoWeek').add(1, 'week'); // Prvi ponedeljak
      }
      // trenutno dan jednak setovanom danu
      else if (moment().format('E') == setLimitDay) {
        // setovani sat manji od trenutnog sata
        if (setLimitHour < moment().format('H')) {
          orderDay = moment().startOf('isoWeek').add(2, 'week'); // Drugi ponedeljak
        }
        // setovani sat jednak trenutnom satu
        else if(setLimitHour == moment().format('H')) {
          // trenutni minuti veci od setvanih (0)
          if (moment().format('m') == 0) {
            orderDay = moment().startOf('isoWeek').add(1, 'week'); // Prvi ponedeljak
          } else {
            orderDay = moment().startOf('isoWeek').add(2, 'week'); // Drugi ponedeljak
          }
        }
        // setovan sat veci od trenutnog
        else {
          orderDay = moment().startOf('isoWeek').add(1, 'week'); // Prvi ponedeljak
        }
      }
      // trenutni dan veci od setovanog
      else {
        orderDay = moment().startOf('isoWeek').add(2, 'week'); // Drugi ponedeljak
      }
      orderDay.set('hour', setLimitHour);
      orderDay.set('minute', 0);

      return orderDay;
    },
    getMonday(days) {
      let date = new Date()
      let dateCopy = new Date(date.getTime());

      return new Date(
          dateCopy.setDate(
              dateCopy.getDate() + ((days - dateCopy.getDay() + 1) % days || days),
          ),
      );
    },
    getNextMonday(date = new Date()) {
      let dateCopy = new Date(date.getTime());
      let nextMonday = new Date(
          dateCopy.setDate(
              dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
          ),
      );
      return nextMonday;
    },

    getNext2Monday(date = new Date()) {
      let dateCopy = new Date(date.getTime());
      let nextMonday = new Date(
          dateCopy.setDate(
              dateCopy.getDate() + ((14 - dateCopy.getDay() + 1) % 14 || 14),
          ),
      );

      return nextMonday;
    },
    switchDay(dani) {
      let dan = ''

      if (dani == 1) {
        dan = 'Понедељакa'
      }
      if (dani == 2) {
        dan = 'Уторкa'
      }
      if (dani == 3) {
        dan = 'Средe'
      }
      if (dani == 4) {
        dan = 'Четврткa'
      }
      if (dani == 5) {
        dan = 'Петкa'
      }
      if (dani == 6) {
        dan = 'Суботe'
      }
      if (dani == 7) {
        dan = 'Недељe'
      }
      return dan
    },
    getNextDayOfTheWeek(dayName, excludeToday = true, refDate = new Date()) {
      const dayOfWeek = ["sun","mon","tue","wed","thu","fri","sat"]
          .indexOf(dayName.slice(0,3).toLowerCase());
      if (dayOfWeek < 0) return;
      refDate.setHours(0,0,0,0);
      refDate.setDate(refDate.getDate() + +!!excludeToday +
          (dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7);
      return refDate ;
    },
    scrollMeBack(refName) {
      if (refName - 1 != -1) {
        let element = this.$refs[refName - 1];
        element[0].scrollIntoView({ behavior: 'smooth' });
      }
    },
    scrollMeTo(refName) {
      let element = this.$refs[refName + 1];
      element[0].scrollIntoView({ behavior: 'smooth' });
    },
    revertMore(item) {
      item.shift_one.forEach((item) => {
        axios
          .delete(
            `/orders/${
              item.orders.find((order) => order.shift === "1-smena").id
            }`
          )
          .then((res) => {
            let order = item.orders.find((order) => order.shift === "1-smena");
            order.id = null;
            order.quantity = 0;
          });
      });
      item.shift_two.forEach((item) => {
        axios
          .delete(
            `/orders/${
              item.orders.find((order) => order.shift === "2-smena").id
            }`
          )
          .then((res) => {
            let order = item.orders.find((order) => order.shift === "2-smena");
            order.id = null;
            order.quantity = 0;
          });
      });
      item.shift_three.forEach((item) => {
        axios
          .delete(
            `/orders/${
              item.orders.find((order) => order.shift === "3-smena").id
            }`
          )
          .then((res) => {
            let order = item.orders.find((order) => order.shift === "3-smena");
            order.id = null;
            order.quantity = 0;
          });
      });
    },
    reserveMore(val, item, i, ii, delivery_from, delivery_to) {
      if (item.disabled) return;
      let data = {
        user_id: this.$store.state.user.id,
        meal_id: i.id,
        date: item.date,
        type: this.selectedDate.name,
        quantity: val,
        quantity_payed: 0,
        shift: ii,
        delivery_time: {
          delivery_from: delivery_from,
          delivery_to: delivery_to,
        },
        location: this.newUserLocation.coordinates
      };
      if(this.$store.state.user.is_transferable&&item.location[0]&&item.location[0]!==this.$store.state.company.id)data.company_id=item.location[0];
      if (i.orders.find((order) => order.shift === ii).id) {
        // if(i.orders.find((order) => order.shift === ii).date == item.date && i.orders.find((order) => order.shift === ii).orginal_qty > val) {
        //   alert('Ne mozete smanjiti obrok')
        //   i.orders.find((order) => order.shift === ii).quantity = i.orders.find((order) => order.shift === ii).orginal_qty
        //   return;
        // }
        axios
          .put(
            `/orders/${i.orders.find((order) => order.shift === ii).id}`,
            data
          )
          .then((res) => {
            if(res.data.data) {
              i.orders.find((order) => order.shift === ii).quantity =
                res.data.data.quantity;
              i.orders.find((order) => order.shift === ii).id = res.data.data.id;
            }
            else {
                i.orders.find((order) => order.shift === ii).id = null;
            }
              this.snackbar.status = true;
              this.snackbar.text = this.translate("Сачувано");
              if (this.$store.state.user.type === 'guest') {
                this.getMenues();
              }
          });
      } else {
        if(this.$store.state.user.type == "guest") {
          axios.post('/orders/guest-order-pay', data).then(({data}) => {
            console.log(data)
          })
        } else {
          axios.post("/orders", data).then((res) => {
            i.orders.find((order) => order.shift === ii).quantity =
                res.data.data.quantity;
            i.orders.find((order) => order.shift === ii).id = res.data.data.id;
            this.snackbar.status = true;
            this.snackbar.text = this.translate("Сачувано");
          });
        }
      }
    },
    revert(item, meal) {
      let date = item.date
      this.mealOrderdErrorMessage = ''
      this.errorDate = ''
      axios.delete(`/orders/${item.id}`).then((res) => {
        let index = this.menues.findIndex((i) => i.id === item.id);
        this.menues[index].test = null;
        this.menues[index].id = null;
        this.menues[index].shift_one.filter(item => {
          item.orders[0].quantity = 0
          item.orders[0].date = ''
          item.orders[0].meal_id = ''
        })
        this.menues[index].shift_two.filter(item1 => {
          item1.orders[1].quantity = 0
          item1.orders[1].date = ''
          item1.orders[1].meal_id = ''
        })
        this.menues[index].shift_three.filter(item2 => {
          item2.orders[2].quantity = 0
          item2.orders[2].date = ''
          item2.orders[2].meal_id = ''
        })
      }).catch((error) => {
        if (error.response.data.status == "failed") {
          this.mealOrderdErrorMessage = error.response.data.message
          this.errorDate = date
        }
      });
    },
    async getMenues() {
      if (this.selectedDate.name) {
        let newDate = moment(this.selectedDate.date).add(0, "days").format("YYYY-MM-DD");
        let user = '&user=' + this.$store.state.user.id
        let company = '&company=' + this.comapny.id;
        let restoran = '&restaurant=' + this.restaurant.id
        await axios
          .get(`/guest-web-app?from=${this.selectedDate.date}&to=${newDate}&type=${this.selectedDate.name}${user}${company}${restoran}`,)
          .then((res) => {
            res.data.forEach((menu, i) => {
              this.totalTime[i] = ''
              let check = menu.meals.find((i) => i.shift_one || i.shift_two || i.shift_three);

              if (! this.set_user_location[menu.date]) {
                this.set_user_location[menu.date] = {
                  'id': 0,
                  'address': '',
                  'entity_id': '',
                }
              }

              if (check) {
                let dan = this.restaurant.settings.guest.dan
                let location =  [this.$store.state.company.id,this.$store.state.company.name]
                let shiftOne = this.comapny.shift_one
                let shiftTwo = this.comapny.shift_one
                let shiftThree = this.comapny.shift_one
                let rokDana = this.comapny.restaurant.settings.guest.rok_dana
                let orderBefore = this.comapny.restaurant.settings.guest.order_before
                let rokSati = this.comapny.restaurant.settings.guest.rok_sati

                let datum = moment(this.selectedDate.date).add(dan, 'days')
                let meal = menu.type === "rucak" ? menu.meals.find((meal) => meal.orders.length) : null;

                this.menues.push({
                  id: meal ? meal.orders[0].id : null,
                  location: location,
                  name: this.convertNameOfDay(moment(menu.date).format("dddd")) + " " + moment(menu.date).format("DD.MM.YYYY"),
                  shift_one: menu.meals
                    .filter((i) => i[this.findShift(shiftOne)])
                    .map((i) => {
                      if (i && (!i.orders.length || !i.orders.find((i) => i.shift === "1-smena")))
                        i.orders.push({ quantity: 0, shift: "1-smena" })
                        i.orders.find((i) => i.shift === "1-smena").orginal_qty = i.orders.find((i) => i.shift === "1-smena").quantity ? i.orders.find((i) => i.shift === "1-smena").quantity : 0
                        i.orders.find((i) => i.shift === "1-smena").quantity_payed = i.orders.find((i) => i.shift === "1-smena").quantity_payed ? i.orders.find((i) => i.shift === "1-smena").quantity_payed : 0
                      return i;
                    }),
                  shift_two: menu.meals
                    .filter((i) => i[this.findShift(shiftTwo)])
                    .map((i) => {
                      if (i && (!i.orders.length || !i.orders.find((i) => i.shift === "2-smena")))
                      i.orders.push({ quantity: 0, shift: "2-smena" });
                      i.orders.find((i) => i.shift === "2-smena").orginal_qty = i.orders.find((i) => i.shift === "2-smena").quantity ? i.orders.find((i) => i.shift === "2-smena").quantity : 0
                      i.orders.find((i) => i.shift === "2-smena").quantity_payed = i.orders.find((i) => i.shift === "2-smena").quantity_payed ? i.orders.find((i) => i.shift === "2-smena").quantity_payed : 0
                      return i;
                    }),
                  shift_three: menu.meals
                    .filter((i) => i[this.findShift(shiftThree)])
                    .map((i) => {
                      if (i && (!i.orders.length || !i.orders.find((i) => i.shift === "3-smena")))
                        i.orders.push({ quantity: 0, shift: "3-smena" });
                      i.orders.find((i) => i.shift === "3-smena").orginal_qty = i.orders.find((i) => i.shift === "3-smena").quantity ? i.orders.find((i) => i.shift === "3-smena").quantity : 0
                      i.orders.find((i) => i.shift === "3-smena").quantity_payed = i.orders.find((i) => i.shift === "3-smena").quantity_payed ? i.orders.find((i) => i.shift === "3-smena").quantity_payed : 0
                      return i;
                    }),
                  // poredi zadati dan sa trenutnim danom u meniju
                  disabled: rokDana ? (moment(this.selectedDate.date).add(Number(orderBefore), "days") > moment(menu.date) ? true : false) : rokSati ? (datum >= moment(menu.date) ? false : false) : false,
                  // sto se trazi ID na ovaj nacin
                  test: meal ? meal.id + "_" + meal.orders[0].shift : null,
                  date: menu.date,
                });

                if (this.$store.state.user && this.$store.state.user.type === "company") {
                  this.menues[this.menues.length - 1].shift_three.push({
                    name: "Укупно",
                  });
                  this.menues[this.menues.length - 1].shift_two.push({
                    name: "Укупно",
                  });
                  this.menues[this.menues.length - 1].shift_one.push({
                    name: "Укупно",
                  });
                }
              }
            });
          });

        if (this.$store.state.user && this.$store.state.user.is_transferable) {
          let cId=0;
          cId = this.$store.state.company.parent_id;
          if (!cId) cId = this.$store.state.company.id;
          await axios
            .get("/companies/list/" + cId)
            .then((res) => {
              this.locations = res.data;
              this.locations.forEach((item) => {
                if(item[0]===this.$store.state.company.id)this.location=item;
              })
            });
        }
      }
    },
    findShift(num) {
      switch (num) {
        case 1:
          return "shift_one";
          break;
        case 2:
          return "shift_two";
          break;
        case 3:
          return "shift_three";
          break;
      }
    },
    reserve(val, item, type = false) {
      if (item.disabled) return;
      let arr = "";

      if (type) {
        item.test = val.target.parentNode.offsetParent.querySelector(
          "input"
        ).value;
        arr = val.target.parentNode.offsetParent
          .querySelector("input")
          .value.split("_");
      } else {
        arr = val.split("_");
      }

      let id = arr[0];
      let shift = arr[1];

      let data = {
        user_id: this.$store.state.user.id,
        meal_id: id,
        date: item.date,
        type: this.selectedDate.name,
        quantity: 1,
        quantity_payed: 0,
        shift: shift,
      };
      if(this.$store.state.user.is_transferable&&item.location[0]&&item.location[0]!==this.$store.state.company.id)data.company_id=item.location[0];

      if (item.id) {
        axios.put(`/orders/${item.id}`, data).then((res) => {
          item.id = res.data.data.id;
          this.snackbar.status = true;
          this.snackbar.text = "Сачувано";
        });
      } else {
          axios.post("/orders", data).then((res) => {
            item.id = res.data.data.id;
            this.snackbar.status = true;
            this.snackbar.text = "Сачувано";
            if (shift == "1-smena") {
              item.shift_one.filter(smena => {
                smena.orders[0].quantity = res.data.data.quantity
                smena.orders[0].date = res.data.data.date
                smena.orders[0].meal_id = res.data.data.meal_id
              })
              this.$forceUpdate()
            }
            if (shift == "2-smena") {
              item.shift_two.filter(smena => {
                smena.orders[1].quantity = res.data.data.quantity
                smena.orders[1].date = res.data.data.date
                smena.orders[1].meal_id = res.data.data.meal_id
              })
              this.$forceUpdate()
            }
            if (shift == "3-smena") {
              item.shift_three.filter(smena => {
                smena.orders[2].quantity = res.data.data.quantity
                smena.orders[2].date = res.data.data.date
                smena.orders[2].meal_id = res.data.data.meal_id
              })
              this.$forceUpdate()
            }
          });
      }
    },
    selectNewRes(res) {
      let data = {};
      data['user_id'] = this.$store.state.user.id
      data['restoran_id'] = res.id
      axios.post('/choose-restaurant', data).then(({data}) => {
        if(data.data) {
          this.dialogNewRestoran = false;
          this.getMenues()
        }
      })
    },
    convertNameOfDay(name) {
      switch (name) {
        case "Sunday":
          return "Недјеља";
          break;
        case "Monday":
          return "Понедјељак";
          break;
        case "Tuesday":
          return "Уторак";
          break;
        case "Wednesday":
          return "Сриједа";
          break;
        case "Thursday":
          return "Четвртак";
          break;
        case "Friday":
          return "Петак";
          break;
        case "Saturday":
          return "Субота";
          break;
      }
    },
  },
  computed: {
    editCountries() {
      let cities = ''
      if (this.edit_country) {
        this.countries.filter(county => {
          if (county.id == this.edit_country) {
            cities = county.city
          }
        })
      }
      return cities
    },
    setMinutes() {
      let minutes = []
      if (this.restaurant) {
        minutes.push(Number(this.restaurant.settings.delivery_to))
        if(this.restaurant.settings.delivery_to1 == "15") {
          minutes.push(15)
        } else if(this.restaurant.settings.delivery_to1 == "30") {
          minutes.push(15, 30)
        } else if(this.restaurant.settings.delivery_to1 == "45") {
          minutes.push(15, 30, 45)
        } else {
          minutes.push(0, 15, 30, 45)
        }
      }

      return minutes
    },
    setHoures() {
      let hours = []
      if (this.restaurant) {
        for (let i = this.restaurant.settings.delivery_from; i <= this.restaurant.settings.delivery_from1; i++) {
          hours.push(Number(i))
        }
      }
      return hours
    },
    checkTime() {
      return moment(this.time, 'HH:mm').isBefore(moment(this.sati,'HH:mm'))
    },
    topBarColor() {
      return this.site && this.site.settings
          ? this.site.settings.topbar
              ? this.site.settings.topbar.background
              : "primary"
          : this.$store.state.company &&
          this.$store.state.company.restaurant.settings
              ? this.$store.state.company.restaurant.settings.topbar.background
              : "primary";
    },
    checkDate() {
      let daysArray = ['Недеља', 'Понедељак', 'Уторак', 'Среда', 'Четвртак', 'Петак', 'Субота'];
      let day = new Date(this.selectedDate.date).getDay();
      let dayName = daysArray[day];
      let selektovaniDatum = daysArray.indexOf(dayName);
      let noviDatum = daysArray.indexOf(this.restaurant.settings.guest.dan);
      let postavitiDatum = noviDatum - selektovaniDatum
      let result = new Date(this.selectedDate.date);
      result.setDate(result.getDate() + postavitiDatum);
      let prepMonth = (result.getMonth()+1) < 9 ?  "-0" + (result.getMonth()+1) : '-' + (result.getMonth()+1)
      let prepDay = result.getDate() <= 9 ?  "-0" + result.getDate() : '-' + result.getDate()
      let datestring = result.getFullYear() + prepMonth + prepDay

      return datestring
    },
    comapny() {
      //console.log(this.$store.state.company);
      return this.$store.state.company;
    },
    /* restaurant() {
      return this.$store.state.company
        ? this.$store.state.company.restaurant.id
        : this.$store.state.restaurant.id;
    }, */
    restaurant() {
      if(this.$store.state.restaurant == null && this.$store.state.company == null) {
        axios.get('/all-restaurants').then(({data}) => {
          this.chooseNewRestoran = data.data
          // this.dialogNewRestoran = true
        })
      } else{
        return this.$store.state.restaurant
            ? this.$store.state.restaurant
            : this.$store.state.company.restaurant;
      }

    },

    shifts() {
      let data = [];
      if (this.$store.state.company) {
        if (this.$store.state.company.shift_one)
          data.push({ text: "1-smena", value: 1, key: "shift_one" });
        if (this.$store.state.company.shift_two)
          data.push({ text: "2-smena", value: 2, key: "shift_two" });
        if (this.$store.state.company.shift_three)
          data.push({ text: "3-smena", value: 3, key: "shift_three" });
      } else {
        data.push({ text: "1-smena", value: 1, key: "shift_one" });
        data.push({ text: "2-smena", value: 2, key: "shift_two" });
        data.push({ text: "3-smena", value: 3, key: "shift_three" });
      }
      if (this.$store.state.user.type == 'guest') {
        data.push({text: "korpa", value: 4, key: "korpa"});
      }
      return data;
    },
    headers() {
      let data = [
        // { text: "Датум", value: 'name', sortable: false, align: 'center', width: '15%' }
      ];
      let shift_one = this.$store.state.company.shift_one
      let shift_two = this.$store.state.company.shift_two
      let shift_three =  this.$store.state.company.shift_three
      if (shift_one)
        data.push({
          text: "Прва смјена",
          value: "shift_one",
          sortable: false,
          align: "center",
          width: "33%",
        });
      // if (shift_two)
      //   data.push({
      //     text: "Друга смјена",
      //     value: "shift_two",
      //     sortable: false,
      //     align: "center",
      //     width: "33%",
      //   });
      // if (shift_three)
      //   data.push({
      //     text: "Трећа смјена",
      //     value: "shift_three",
      //     sortable: false,
      //     align: "center",
      //     width: "33%",
      //   });

        data.push({
          text: "Твоја ордера наруџба",
          value: "korpa",
          sortable: false,
          align: "center",
          width: "15%",
        });


      return data;
    },
  },
};
</script>

<style scoped>
.underline {
  border-bottom: 4px solid #ff914d;
  border-radius: 4px;
}
.sidebar__widget {
  position: sticky;
  top: 136px;
  height: 20px;
}
.cat_link:hover {
  background: rgba(207, 207, 207, 0.24);
}

.v-application .elevation-1 {
  box-shadow: none !important;
}
.theme--light.v-data-table > div.v-data-table__wrapper > table > tbody > tr.myclass:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  vertical-align: unset !important;
}
.home-page .v-data-table__mobile-row {
  display: flex!important;
  flex-direction: column!important;
}
tbody tr:nth-child(even):hover {
  background-color: transparent !important;
}
tbody td.text-center:nth-child(n + 2) {
  vertical-align: center;
}
tbody td.text-right {
  padding: 0px !important;
}
tbody tr:nth-child(odd) {
  /* background: #f3f4f4; */
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: white !important;
}
.row {
  align-items: center;
}
.v-input--selection-controls.v-input {
  margin-top: 0;
}

td.text-center {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
</style>

<style>
.theme--light.v-data-table > div.v-data-table__wrapper > table > tbody > tr.myclass:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: none;
}
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  box-shadow: none;
}
.sidebar.fix {
  position: fixed;
  top: 0;
  right: 0;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}
div.leaflet-bottom {
  display: none !important;
}
#sticky-div {
  position : sticky;
  bottom:0;
  font-size: 30px;
  color: #333;
}
div.v-input--selection-controls__input {
  margin-top: -10px !important;
}
.theme--light.v-data-table > div.v-data-table__wrapper > table > tbody > tr.myclass:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  vertical-align: top !important;
}
.v-data-table--fixed-header > .v-data-table__wrapper {
  overflow-y: clip;
}
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: none;
}
.kapica {
  position: absolute;
  left: -21px;
  top: -12px;
  width: 30px;
  transform: rotate(-30deg);
}
.shift-title {
  font-family: "Gagalin", Helvetica, sans-serif;
  font-size: 31px;
  font-weight: 400;
}
.relative {
  position: relative;
  width: max-content;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
}
.shift1 {
  color: #c20606;
}
.shift2 {
  color: #99ad16;
}
.shift3 {
  color: #ff914d;
}
.datum {
  color: #c20606;
  font-family: "Gagalin", Helvetica, sans-serif;
  font-size: 31px;
  font-weight: 400;
  padding-left: 33vw;
  text-align: center;
}
img.datumico {
  width: 30px;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 15px;
}

button.trash-icon {
  margin-left: 50%;
  transform: translateX(-50%);
}


.datum-value {
  color: #c20606;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
}
.meal-wrapper {
  display: flex;
  /*background: #efefef;*/
  /*padding: 10px;*/
  border-radius: 10px;
  justify-content: space-between;
  /*gap: 10px;*/
}
.meal-wrapper h3 {
  color: #5d3f27;
  font-size: 18px;
  width: 70%;
  text-align: left;
  cursor: pointer;
}
.meal-wrapper div {
  /*width: 100%;*/
  color: white;
  /*padding: 10px;*/
  border-radius: 10px;
  height: 35px;
  line-height: 1;
  font-size: 17px;
}
.meal-wrapper .naruci {
  background: #99ad16;
  margin-left: auto;
}
.meal-wrapper .otkazi {
  background: #c20606;
  margin-left: auto;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: #5d3f27;
  font-size: 18px;
  width: 70%;
  text-align: left;
  cursor: pointer;
}
.v-application--is-ltr .v-input--selection-controls__input {
  display: none;
}
.v-input--selection-controls.v-input {
  margin-top: 10px;
  margin-left: 5px;
}
.disabled-meal {
  opacity: 0.5;
  pointer-events: none;
}
.transfer-lista {
  width: 30%;
  margin: 0 auto;
}
@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .dan-u-nedelji {
    font-size: 21px;
  }
  .mobile-image {
    width: 30px !important;
  }
  .hero {
    height: unset;
    width: unset;
  }
  .row {
    width: 100%;
    margin: 0;
  }
  .hero-logo {
    width: 80%;
  }
  .col, .v-application {
    padding: 0 !important;;
  }
  .home-page {
    padding-top: 0 !important;;
  }
  .transfer-lista {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .datum-value {
    font-size: 16px;
    margin-top: 20px;
  }

  img.datumico {
    display: none !important;
  }

  .meal-wrapper .otkazi {
    margin-left: auto;
  }

  .rezervisati {
    font-size: 16px !important;
    text-align: left;
    line-height: 1.4;
  }
  .dan-u-nedelji {
    font-size: 21px;
  }
  .mobile-image {
    width: 30px !important;
  }
  .hero {
    height: unset;
    width: unset;
  }
  .row {
    width: 100%;
    margin: 0;
  }
  .hero-logo {
    width: 80%;
  }
  .col, .v-application {
    padding: 0 !important;;
  }
  .home-page {
    padding-top: 0 !important;;
  }
  .transfer-lista {
    width: 80%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 760px) {
  .dan-u-nedelji {
    font-size: 21px;
  }
  .mobile-image {
    width: 30px !important;
  }
  .hero {
    height: unset;
    width: unset;
  }
  .row {
    width: 100%;
    margin: 0;
  }
  .hero-logo {
    width: 80%;
  }
  .col, .v-application {
    padding: 0 !important;;
  }
  .home-page {
    padding-top: 0 !important;
  }
  .transfer-lista {
    width: 80%;
  }
}

</style>