import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      user: null,
      restaurant: null,
      company: null,
      companies: null,
      isLatin : 0,
      drawer: null,
      notification: [],
      snackbar: false
  },
  mutations: {
      SAVE_USER(state, items) {
          state.user = items;
      },
      SAVE_RESTAURANT(state, items) {
          state.restaurant = items;
      },
      SAVE_COMPANY(state, items) {
          state.company = items;
      },
      SAVE_COMPANIES(state, items) {
        state.companies = items;
      },
      SAVE_LANGUAGE(state,item){
          state.isLatin=item;
      },
      SET_DRAWER(state,item){
          state.drawer=item;
      },
      INCREASE_NOTIFICATION(state, item){
          state.notification.push(item);
      },
      SET_NOTIFICATION(state, item){
          state.notification = item;
      },
      SET_SNACKBAR(state, item){
          state.snackbar = item;
      }
  },
  actions: {
  },
  modules: {
  }
})
