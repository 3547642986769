<template>
  <v-row class="mx-10 my-1">
    <v-col cols="12" sm="3">
      <v-row v-if="selectedMenu">
        <v-col cols="12">
          <div style="border: 1px solid #0000001A; background-color: #fff8f5;border-radius: 8px 8px 0px 0px;" class="py-4 text-center">
            {{$t('pages.category_menu.sentence_3')}}
          </div>
          <shift-box v-model="shift" :selectedMenu="selectedMenu" :shiftEnable="shiftEnable" class="py-3"></shift-box>
        </v-col>
        <v-col cols="12" v-if="shiftAsText">
          <v-row>
            <template v-for="(item, key) in Object.keys(selectedMenu[shiftAsText])">
              <v-col cols="12" class="py-1">
                <v-sheet
                  :color="category != item ? '#fafafa' : '#fb6d3b'"
                  :class="category != item ? 'black--text' : 'white--text'"
                  class="px-2 py-4" @click="category = item"
                  style="border-radius: 8px"
                >
                  {{ $store.state.isLatin ? convert(item) : item }}
                </v-sheet>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" align="center">
      <v-row>
        <v-col cols="12" align="center">
          <div style="border: 1px solid #0000001A; border-radius: 10px">
            <div style="background-color: #fff8f5;border-radius: 8px 8px 0px 0px;" class="py-4">
              {{$t('pages.category_menu.sentence_1')}}
            </div>
            <v-row
              no-gutters
              justify="center"
              align="center"
              style="background-color: white; border-radius: 4px; border-radius: 10px"
              class="py-4"
            >
              <v-btn
                icon
                @click="subOneDay"
                style="background-color: white; border-radius: 4px; border: 1px solid #0000001a"
              >
                <v-icon class="px-4" style="color: #fb6d3b">mdi-arrow-left</v-icon>
              </v-btn>
              <div
                class="font-weight-bold px-4">
                {{ dayName(moment(selectedDate).format('dd')) }}
                {{ moment(selectedDate).format('DD.MM.YYYY.') }}
              </div>
              <v-btn
                icon
                @click="addOneDay"
                style="background-color: white; border-radius: 4px; border: 1px solid #0000001a"
              >
                <v-icon class="px-4" style="color: #fb6d3b">mdi-arrow-right</v-icon>
              </v-btn>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" v-if="category && shiftAsText">
          <template v-for="(item, index) in selectedMenu[shiftAsText][category]">
            <v-col
              cols="12"
              style="cursor: pointer"
              @click="!checkDisabled && !rolaCompany && addFood(item)"
              class="pt-0"
            >
              <v-hover v-slot="{ hover }">
                <v-card elevation="0" :class="{ 'on-hover': hover }" :disabled="checkDisabled">
                  <v-row :style="choseItems.find(i => i.foods.find(ii => ii.id == item.id)) && 'background: #ffe0d1; border: 1px solid #fb6d3b1a'">
                    <v-col cols="auto">
                      <v-img
                        style="border-radius: 12px" width="80" height="80"
                        :src="
                        item.image
                          ? conf.apiUrlBase +
                            '/gallery/' +
                            item.image.restaurant_id +
                            '/medium/' +
                            item.image.name
                          : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'"
                        @error="imageNewUrl(index)"
                      />
                    </v-col>
                    <v-col align="start" class="font-weight-bold">
                      <v-row no-gutters align="center" class="fill-height">
                        <v-col>
                          {{ $store.state.isLatin ? convert(item.name) : item.name }}
                        </v-col>
                        <v-col v-if="rolaCompany" cols="auto">
                          <v-icon color="#fb784a" @click.stop.prevent="subFood(item)">mdi-minus-box-outline</v-icon>
                          <span class="px-1">{{ item.quantity }}</span>
                          <v-icon color="#fb784a" @click.stop.prevent="addFood(item)">mdi-plus-box-outline</v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-hover>
              <v-divider class="mt-4"></v-divider>
            </v-col>
          </template>
        </v-col>
        <v-col cols="12" v-else>
          <v-alert
            dense
            text
            type="warning"
            color="#fb784a"
          >
            {{$t('pages.category_menu.sentence_2')}}
          </v-alert>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="3">
      <v-row v-if="selectedMenu">
        <v-col cols="12">
          <div style="border: 1px solid #0000001A;background-color: #fff8f5; border-radius: 8px 8px 0px 0px;" class="py-4 text-center">
            {{$t('pages.category_menu.sentence_4')}}
          </div>
          <shift-box v-model="shift" :selectedMenu="selectedMenu" :shiftEnable="shiftEnable" class="py-3"></shift-box>
        </v-col>
        <template v-for="(item, i) in choseItems.filter(i => i.shift === shiftAsText)">
          <v-col cols="12" class="py-1" :align="!item.foods.length ? 'center' : 'start'">
            <v-sheet color="#fafafa" class="px-2 py-4" style="border-radius: 8px">
              <div v-if="item.foods.length">
                <template v-for="(food, ii) in item.foods">
                  <v-row no-gutters justify="space-between">
                    <v-col>
                        <span class="food-box-name">
                          {{ food.quantity && (food.quantity + 'x') }} {{ food.name }}
                        </span>
                    </v-col>
                    <v-col cols="auto" v-if="!checkDisabled">
                      <v-icon @click="removeFood(food, item, ii)">mdi-close</v-icon>
                    </v-col>
                  </v-row>
                </template>
              </div>
              <v-row v-else no-gutters justify="center">
                <v-col>
                    <span style="color: #d7d7d7" class="food-box-name">
                      {{ 'Izaberite ' + item.category }}
                    </span>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </template>
        <v-col cols="12">
          <v-btn
            block color="#FB6D3B"
            class="white--text"
            @click="!checkDisabled ? disable ? update() : saveOrder() : null"
            :disabled="disable && checkDisabled"
            style="position:sticky; text-transform: none"
            height="46"
          >
            <v-icon>mdi-cart-outline</v-icon> {{disable ? $t('pages.category_menu.sentence_8') : $t('pages.category_menu.sentence_7')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>


    <v-overlay :value="dialog">
      <v-sheet
        elevation="12"
        max-width="600"
        rounded="lg"
        width="100%"
        class="pa-4 text-center mx-auto"
        light
      >
        <v-icon
          class="mb-5"
          color="success"
          size="112"
        >mdi-check-circle</v-icon>

        <h2 class="text-h5 mb-6">{{$t('pages.category_menu.sentence_5')}}</h2>

        <p class="mb-4 text-medium-emphasis text-body-2">
          {{$t('pages.category_menu.sentence_9')}}
        </p>

        <v-divider class="mb-4"></v-divider>

        <div class="text-end">
          <v-btn
            @click="dialog = false"
            class="white--text"
            color="#fb6d3b"
            variant="flat"
            width="90"
            block
          >
            {{$t('pages.category_menu.sentence_6')}}({{timeleft}})
          </v-btn>
        </div>
      </v-sheet>
    </v-overlay>
  </v-row>
</template>

<script>

import axios from "../plugins/axios";
import moment from "moment";
import ShiftBox from "../components/categoryMenu/shiftBox";
import conf from "../config.json";
import convert from 'cyrillic-to-latin'

export default {
  components: {ShiftBox},
  data() {
    return {
      convert: convert,
      conf: conf,
      moment: moment,
      selectedDate: new Date().toISOString().substr(0, 10),
      //selectedDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
      items: [
        { text: 'Supe', category: 0 },
        { text: 'Glavna Jela', category: 1 },
        { text: 'Pice', category: 2 },
        { text: 'Dezert', category: 3 }
      ],
      menus: [],
      category: null,
      choseItems: [],
      shift: null,
      dialog: false,
      shiftEnable: 'all',
      disable: false,
      restaurantOrderTime: null,
      order: null,
      timeleft: 2
    }
  },
  created() {
    this.getMenues(true);

    axios.get('/restaurants/' + this.company.restaurant_id + '/get-new-times')
      .then(res => {
        this.restaurantOrderTime = res.data.data
      })
  },
  methods: {
    imageNewUrl(index) {
      this.selectedMenu[this.shiftAsText][this.category][index].image = null
    },
    update() {
      let foods = this.choseItems.filter(i => i.foods.length)

      let data = {
        categories: foods,
        shift: this.shift + 1,
        restaurant_id: this.company.restaurant_id,
        company_id: this.company.pivot ? this.company.pivot.company_id : 0,
        date: this.selectedDate,
        app_type: 1
      }

      axios.put('/orders/food/store/' + this.order.id + '/group', data).then(res => {
        this.dialog = true
        if(!data.foods.length) this.disable = false
        this.timeleft = 2;
        let downloadTimer = setInterval(() => {
          if(this.timeleft <= 0){
            clearInterval(downloadTimer);
            this.dialog = false
          }
          this.timeleft -= 1;
        }, 1000);

      })
    },
    saveOrder() {
      let foods = this.choseItems.filter(i => i.foods.length)

      let data = {
        categories: foods,
        shift: this.shift + 1,
        restaurant_id: this.company.restaurant_id,
        company_id: this.company.pivot ? this.company.pivot.company_id : 0,
        date: this.selectedDate,
        app_type: 1
      }

      axios.post('/orders/food/store/group', data).then(res => {
        this.order = res.data
        this.disable = true
        this.dialog = true

        this.timeleft = 2;
        let downloadTimer = setInterval(() => {
          if(this.timeleft <= 0){
            clearInterval(downloadTimer);
            this.dialog = false
          }
          this.timeleft -= 1;
        }, 1000);

      })

    },
    subOneDay() {
      this.disable = false

      this.selectedDate = this.moment(this.selectedDate)
        .subtract(1, 'days').format('YYYY-MM-DD')

      this.getMenues()
    },
    addOneDay() {
      this.disable = false

      this.selectedDate = this.moment(this.selectedDate)
        .add(1, 'days').format('YYYY-MM-DD')

      this.getMenues()
    },
    addFood(food) {
      let item = this.choseItems.find(i => i.category === this.category && i.shift === this.shiftAsText)
      this.shiftEnable = this.shiftAsText

      let foodIndex = item.foods.findIndex(f => f.id === food.id)
      if(foodIndex > -1) {
        food.quantity++
        this.$set(item.foods, foodIndex, food)
      }
      else {
        food.quantity = 1
        if(this.rolaCompany)
          item.foods.push(food)
        else
          item.foods = [food]
      }
    },
    subFood(food) {
      if(food.quantity === 0) return
      food.quantity--
      if(food.quantity === 0) {
        let category = this.choseItems.find(i => i.category === food.category.name)
        let index = category.foods.findIndex(f => f.id === food.id)
        if(index > -1) category.foods.splice(index, 1)
      }
    },
    removeFood(food, shift, i) {
      shift.foods.splice(i, 1)

      this.shiftEnable = this.choseItems.find(i => i.shift === this.shiftAsText && i.foods.length)
        ? this.shiftAsText : 'all'

      let menu = this.menus[0][shift.shift][shift.category]
      let foodFromMenu = menu.find(f => f.id === food.id)
      foodFromMenu.quantity = 0
    },
    async getMenues(firstLoad = false) {

      if(firstLoad) {
        let {data} = await axios.get('/restaurants/' + this.company.restaurant_id + '/check-disabled')
        this.selectedDate = moment(data).format('YYYY-MM-DD')
      }

      let fromDate = this.selectedDate;

      this.menus = []
      axios.get(`/menus/web-app?from=${fromDate}&to=${fromDate}&type=rucak`)
        .then((res) => {
          this.menus = res.data

          axios.get('/orders/food/store?date=' + this.selectedDate)
            .then(res => {
              this.order = null
              if(res.data) {
                this.order = res.data[0]
                this.fillOrder(this.order)
              }
            })
        });
    },
    calc(data) {
      this.choseItems = []
      if(!data) return

      if(this.selectedMenu.shiftOne) {
        for(let i in this.selectedMenu.shiftOne) {
          this.choseItems.push({food: {name: 'empty'}, foods: [], category: i, shift: 'shiftOne'})
        }
      }
      if(this.selectedMenu.shiftTwo) {
        for(let i in this.selectedMenu.shiftTwo) {
          this.choseItems.push({food: {name: 'empty'}, foods: [], category: i, shift: 'shiftTwo'})
        }
      }
      if(this.selectedMenu.shiftThree) {
        for(let i in this.selectedMenu.shiftThree) {
          this.choseItems.push({food: {name: 'empty'}, foods: [], category: i, shift: 'shiftThree'})
        }
      }
    },
    fillOrder(order) {
      if(!order) return
      let shift = null
      if(order.shift === 1) {
        shift = 'shiftOne'
        this.shiftEnable  = 'shiftOne'
        this.shift = 0
      }
      if(order.shift === 2) {
        shift = 'shiftTwo'
        this.shiftEnable  = 'shiftTwo'
        this.shift = 1
      }
      if(order.shift === 3) {
        shift = 'shiftThree'
        this.shiftEnable  = 'shiftThree'
        this.shift = 2
      }

      order.transaction.forEach(product => {
        let item = this.choseItems.find(i => {
          return i.shift === shift && i.category === product.food.category.name
        })
        if(item) {
          // Fill center column (menu) with quantity
          let menu = this.menus[0][item.shift][item.category]
          let food = menu.find(food => food.id === product.food_id)
          food.quantity = product.quantity
          // Fill right column (order) with quantity
          product.food.quantity = product.quantity

          if(item.foods) item.foods.push(product.food)
          else item.foods = [product.food]

          this.disable = true
        }
      })
    },
    dayName(date) {
      switch (date) {
        case 'We': return this.$t('calendar.wednesday')
          break;
        case 'Th': return this.$t('calendar.thursday')
          break;
        case 'Fr': return this.$t('calendar.friday')
          break;
        case 'Sa': return this.$t('calendar.saturday')
          break;
        case 'Su': return this.$t('calendar.sunday')
          break;
        case 'Mo': return this.$t('calendar.monday')
          break;
        case 'Tu': return this.$t('calendar.tuesday')
          break;
      }
    }
  },
  computed: {
    rolaCompany() {
      return this.$store.state.user && this.$store.state.user.type === 'company'
    },
    company() {
      return this.$store.state.company;
    },
    selectedMenu() {
      return this.menus.find(i => i.date === this.selectedDate)
    },
    shiftAsText() {
      if(!this.selectedMenu) return

      let shifts = []
      if(this.selectedMenu.shiftOne) {
        shifts.push('shiftOne')
      }
      if(this.selectedMenu.shiftTwo) {
        shifts.push('shiftTwo')
      }
      if(this.selectedMenu.shiftThree) {
        shifts.push('shiftThree')
      }

      return shifts[this.shift]

    },
    checkDisabled() {
      if(!this.restaurantOrderTime) return false

      let day = this.moment().format('dddd').toLowerCase()
      let newDay = this.restaurantOrderTime[day]

      if(this.moment().isBefore(this.moment(this.selectedDate).set("hour", newDay.time.time_hour).set("minutes", newDay.time.time_minut))) {
        let addDay = newDay.day_before_limit
        if(this.moment().add('days', addDay).diff(this.moment(this.selectedDate).add('days', 1)) >= 0) {
          return true
        }
        else {
          return false
        }
      }
      else {
        let addDay = newDay.day_after_limit
        if(this.moment().add('days', addDay).diff(this.moment(this.selectedDate).add('days', 1)) >= 0) {
          return true
        }
        else {
          return false
        }
      }
    }
  },
  watch: {
    shiftAsText() {
      if(!this.selectedMenu) return
      this.category = Object.keys(this.selectedMenu[this.shiftAsText])[0]
    },
    selectedMenu(menu) {
      if(menu) {
        if(menu.shiftOne) {
          if(this.shift === null) this.shift = 0
        }
        if(menu.shiftTwo) {
          if(this.shift === null) this.shift = 1
        }
        if(menu.shiftThree) {
          if(this.shift === null) this.shift = 2
        }

        this.calc(menu)
      }
    }
  }
}

</script>

<style scoped>
.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.9;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

.food-box-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
