export default {
  methods: {
    getLang() {
      if(localStorage.getItem('restorani-lang')) {
        setTimeout(() => {
          this.$i18n.locale = localStorage.getItem('restorani-lang')
        }, 1000)
      }
    },
    setLang(){
      localStorage.setItem('restorani-lang', this.$i18n.locale)
    }
  }
}
