var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_vm._l((_vm.meals),function(meal,index){return _c('v-card',{key:index,staticClass:"mx-auto my-12",attrs:{"max-width":"374"}},[_c('v-img',{attrs:{"height":"250","src":meal.image
          ? _vm.conf.apiUrlBase +
            '/gallery/' +
            _vm.$route.params.id +
            '/medium/' +
            meal.image.name
          : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'}}),_c('v-card-title',[_vm._v(_vm._s(_vm.translate(meal.name)))]),_c('v-card-subtitle'),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.translate(meal.description))+" ")]),_c('v-btn',{staticClass:"primary mt-5 mr-1",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.toggle(meal);}}},[(meal.is_checked)?_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-checkbox-marked-circle")]):_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-circle")])],1)],1)}),(_vm.checked.length)?_c('v-btn',{staticClass:"mx-auto",attrs:{"fixed":"","bottom":"","width":"400","color":"primary"},on:{"click":function($event){_vm.dialog=true}}},[_vm._v(" "+_vm._s(_vm.$t("confirm"))+" "+_vm._s(_vm.checked.length)+" ")]):_vm._e(),_c('v-dialog',{attrs:{"scrollable":"","width":"400","max-heigth":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_vm._l((_vm.checked),function(meal,inx){return _c('v-card',{key:inx},[_c('v-img',{attrs:{"height":"250","src":meal.image
              ? _vm.conf.apiUrlBase +
                '/gallery/' +
                _vm.$route.params.id +
                '/medium/' +
                meal.image.name
              : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'}}),_c('v-card-title',[_vm._v(_vm._s(_vm.translate(meal.name)))])],1)}),_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.buy()}}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle")])],1)],2)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }