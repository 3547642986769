<template>
    <v-container
            class="fill-height login"
            fluid
    >
        <v-row justify="center">
            <v-col cols="12" lg="4">
                <v-card class="elevation-12">
                    <v-toolbar
                            color="primary"
                            dark
                            flat
                    >
                        <v-toolbar-title>{{$t('password_reset')}}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                    <v-form v-model="valid">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="password"
                                        :rules="[rules.required, rules.password]"
                                        outlined
                                        dense
                                        type="password"
                                        :placeholder="$t('password')">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="confirmPassword"
                                        :rules="[rules.required, rules.repeatPassword]"
                                        outlined
                                        dense
                                        type="password"
                                        :placeholder="$t('repeat_password')">
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                    depressed
                                    large
                                    :loading="loading"
                                    :disabled="!valid"
                                    color="primary"
                                    @click="reset">
                                {{$t('save')}}
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


        <v-snackbar v-model="snackbar.status" top>
            {{snackbar.text}}
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="pink"
                        text
                        v-bind="attrs"
                        @click="snackbar.status = false"
                >
                    {{$t('close')}}
                </v-btn>
            </template>
        </v-snackbar>

    </v-container>
</template>

<script>
    import hasValidation from '../mixins/hasValidation'
    import axios from '../plugins/axios'

    export default {
        mixins: [hasValidation],
        data: () => ({
            valid: false,
            password: '',
            confirmPassword: '',
            loading: false,
            snackbar: {
                status: false,
                text: ''
            }
        }),
        methods: {
            reset() {
                this.loading = true;
                axios.post('/user/reset-password', {token: this.$route.query.token, password: this.password})
                    .then(res => {
                        this.snackbar.text = 'Успјешно сте ресетовали лозинку'
                        this.snackbar.status = true
                        this.loading = false;
                    }).catch(() => {
                        this.snackbar.text = 'Сесија за ресетовање лозинке је истекла'
                        this.snackbar.status = true
                        this.loading = false;
                    })
            }
        }
    }
</script>
